import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable} from '../DataTable';
import { useNavigate } from "react-router-dom";
import { capitalizeEachWord } from '../../helper/lib';

const PromotionsTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit,  }) => {
    let navigate = useNavigate();
    const columns = [
        {
            name: "Promo Name",
            selector: "promo_name",
            width: "30.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Product Name",
            selector: "product_name",
            width: "23.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Product Image",
            selector: "product_image",
            width: "28%",
            class: [],
            childClass:[]
        },
        {
            name: "Status",
            selector: "status",
            width: "15%",
            class: [],
            childClass:[]
        }
    ]

    return (
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
                >
                     { isLoadingData ? (<tr><td style={{background:"white", height:"200px"}} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                                            let status = '';
                                            switch(item.status?.toLowerCase()) {

                                                case 'on_review':
                                                    status = <div className="badge-yellow">{capitalizeEachWord(item.status)}</div>;
                                                    break;
                                                case 'approved':
                                                    status = <div className="badge-green">{capitalizeEachWord(item.status)}</div>;
                                                    break;
                                                case 'expired':
                                                    status = <div className="badge-gray">{capitalizeEachWord(item.status)}</div>;
                                                    break;
                                                case 'canceled':
                                                    status = <div className="badge-red">{capitalizeEachWord(item.status)}</div>;
                                                    break;
                                                default:
                                                    status = <div className="badge-gray">{capitalizeEachWord(item.status)}</div>;
                                                    break;
    
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>{item.promo_name}</td>
                                                    <td>{item.product_name}</td>
                                                    <td>
                                                            <div className="img-wrap">
                                                                <img src={item.product_image} alt="banner" className="object-fit-cover"/>
                                                            </div>
                                                    </td>
                                                    <td>{status}</td>
                                               </tr>
                                           )
                                        }) }

                </CustomDataTable>
    )
}

export default PromotionsTable;