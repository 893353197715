import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Register = (props) => {
  return (
    <div className="wrap-content signup">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <form>
              <div className="d-flex align-items-center justify-content-center mb-3 head">
                <div className="img-wrap" style={{ "height": "60px" }}>
                  <img src="./dist/img/logo.svg" alt="img-logo" />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  Join Us
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray text-center fz-14">
                  Welcome to mtp! We're excited to have you on board. 
                  <br/>
                  To get started, please click on the link below to access our sign-up form:
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <a href="#" className="btn btn-fill">Sign Up Form</a>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray text-center fz-14">
                  Fill out the form with the required information, and you'll be part of mtp in no time. 
                  <br/>
                  If you have any questions or need assistance, feel free to reach out to our support team.
                  <br/>
                  Thank you for choosing to be a part of mtp!
                </span>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center mb-5">
                <div className>
                  <img
                    className="icon-3 me-1"
                    src="./dist/img/custom/mail.svg"
                    alt="img-mail"
                  />
                  <span className="pe-1 text-secondary text-center fz-14">
                    mtp@mail.com
                  </span>
                </div>
                <div className="ps-sm-3 pt-1 pt-sm-0">
                  <img
                    className="icon-3 me-1"
                    src="./dist/img/custom/phone.svg"
                    alt="img-phone"
                  />
                  <span className="pe-1 text-secondary text-center fz-14">
                    081234567809
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 fz-14 ask">
                <span className>
                  <Link
                    to="/login"
                    className="link-dark d-flex align-items-center justify-content-center fw-semibold"
                  >
                    <div className="img-wrap me-1">
                      <img src="./dist/img/custom/arrow-left.svg" alt="img-arrow-left" />
                    </div>
                    Back to log in
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => {
  return {};
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
  };
};
export default connect(mapState, actionCreators)(Register);
