import React, { useState, useEffect, useRef } from 'react';
import { replyProjecteview } from "../../../helper/function";
import { addDefaultSrc } from "../../../helper/lib";
import StarRatingComponent from 'react-star-rating-component';

const ModalReplyProjectReview = ({ itemReview, onReply, isShowModal, handleClose, closeOnClickBackdrop = false, data }) => {
    const [reply, setReply] = useState('');
    const modalBackdrop = useRef(null);
    const [options, setOptions] = useState({});
    const [onSubmit, setOnSubmit] = useState(false);

    const replyItemReviewHandle = async (id) => {
        setOnSubmit(true)
        const response = await replyProjecteview(data?.id, data?.product_id, reply)
        if (response.message === "Success") {
            window.location.reload();
        } else {
            setOnSubmit(false)
        }
    }

    useEffect(() => {
        if (closeOnClickBackdrop) {
            const handleClickOutside = (event) => {
                if (modalBackdrop.current && !modalBackdrop.current.contains(event.target)) {
                    handleClose();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            setOptions({
                "data-bs-backdrop": "static",
                "data-bs-keyboard": "false",
            });

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }

    }, [closeOnClickBackdrop, handleClose]);


    return (
        <>
            <div className={`modal fade`} id="ModalReply"
                {...options}
                tabIndex="-1" aria-hidden={!isShowModal} >
                <div className="modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0 d-flex align-items-center">
                            <h5 className="modal-title fw-bold" id="ModalReplyLabel">Reply</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                <label className="form-label fw-bolder">Message</label>
                                <p>
                                    {data?.comment}
                                </p>
                            </div>
                            <div className="">
                                <label className="form-label fw-bolder">Rating</label>
                                <br />
                                <StarRatingComponent
                                    name={"start1"} /* name of the radio input, it is required */
                                    value={data?.rating} /* number of selected icon (`0` - none, `1` - first) */
                                    starCount={5} /* number of icons in rating, default `5` */
                                    renderStarIcon={() => <span style={{ marginRight: "8px" }}>★</span>} /* it should return string or react component */
                                    starColor={"#FCC310"} /* color of selected icons, default `#ffb400` */
                                    emptyStarColor={"lightgray"} /* color of non-selected icons, default `#333` */
                                    editing={false} /* is component available for editing, default `true` */
                                />
                            </div>

                            {(data?.productImage) && (
                                <div className="mb-3">
                                    <label className="form-label fw-bolder">Image</label>
                                    <img src={data?.productImage} onError={addDefaultSrc} alt="gambar-preview" style={{
                                        maxHeight: '400px',
                                        width: '100%',
                                        objectFit: 'contain',
                                        border: '1px solid #c7c7c7'
                                    }} />
                                </div>
                            )}
                            <div className="">
                                <label className="form-label fw-bolder">Reply Message</label>
                                <textarea className="form-control" rows="3" value={reply} onChange={e => setReply(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="modal-footer border-0 position-relative">
                            <div className="w-sm-100">
                                <button type="button" onClick={replyItemReviewHandle} className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-bold" disabled={onSubmit}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ModalReplyProjectReview;




