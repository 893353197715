import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
const LoadingComponent = ({classList}) => {
    return (
        <div className={`d-flex align-items-center justify-content-center ${classList?.join(" ")} `}>
            <Stack sx={{ color: "brown100" }}>
                <CircularProgress color="inherit" />
            </Stack>
        </div>
    )
}
export default LoadingComponent