import React, { useState, useEffect, useRef } from 'react';
import { formatMoney } from "../../../helper/lib";
import { requestDisbursementVendor, getDetailTransactionVendor } from "../../../helper/function";
import $ from "jquery";
import { IsAlert } from "../../../atoms/IsAlert";

const ModalRequestDisbursement = ({ itemReview, onReply, isShowModal, handleClose, closeOnClickBackdrop = false, data, disbursementSucceed }) => {
    const MIN_DISBURSED_AMOUNT = 50000;
    const MAX_DISBURSED_AMOUNT = 999999999999999;
    const [disbursedAmount, setDisbursedAmount] = useState('0');
    const [isProcessing, setIsProcessing] = useState(false);
    const [detailData, setDetailData] = useState(null);
    const modalBackdrop = useRef(null);
    const [options, setOptions] = useState({});

    const handleRequestDisbursement = async () => {
        try {
            setIsProcessing(true);
            let payload = {
                request: true,
                orderType: 3,
                amount_transfer: parseInt(disbursedAmount.replaceAll('.', ''))
            }

            const response = await requestDisbursementVendor(payload)
            if (response?.message === 'Success') {
                setDisbursedAmount('0')
                $('#btn-dismiss-modal').trigger('click');
                IsAlert.fire({
                    title: "Success",
                    text: "Request disbursement success.",
                    icon: "success",
                })
                setIsProcessing(false);
                disbursementSucceed()
            } else {
                IsAlert.fire({
                    title: "Failed",
                    text: response?.message ?? 'Something went wrong.',
                    icon: "error",
                })
                setIsProcessing(false);
            }
        } catch (error) {
            setIsProcessing(false);
        }
    }

    const showAlertConfirmation = async () => {
        IsAlert.fire({
            title: "Warning",
            text: "Are you sure you want to request the amount?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            reverseButtons: true
        }).then((isConfirm) => {
            if (isConfirm.isConfirmed) {
                handleRequestDisbursement()
            }
        })
    }

    const getDetailDataVendor = async () => {
        const response = await getDetailTransactionVendor()
        if (response.message === "Success" && response.meta.total > 0) {
            setDetailData(response.data)
        }
        else {
            setDetailData(null)
        }
    }

    useEffect(() => {
        if (closeOnClickBackdrop) {
            const handleClickOutside = (event) => {
                if (modalBackdrop.current && !modalBackdrop.current.contains(event.target)) {
                    handleClose();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            setOptions({
                "data-bs-backdrop": "static",
                "data-bs-keyboard": "false",
            });

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }

    }, [closeOnClickBackdrop, handleClose]);

    useEffect(() => {
        getDetailDataVendor()
    }, []);


    return (
        <>
            <div className={`modal fade`} id="ModalRequest"
                {...options}
                tabIndex="-1"
                aria-labelledby="ModalRequestLabel"
                aria-hidden={!isShowModal}
            >
                <div className="modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div className="modal-content p-3">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btn-dismiss-modal'></button>
                        </div>
                        <div className="modal-body">
                            <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                <div className="d-grid px-3 px-sm-0">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="img-wrap img-wrap-empty">
                                            <img src={"/dist/img/custom/empty.svg"} alt='img-empty' />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <div className="fs-4 fw-bold text-center">
                                            Request Disbursement
                                        </div>
                                    </div>

                                    <table className='mt-3'>
                                        <tr>
                                            <td style={{ width: '35%' }}>Bank Name</td>
                                            <td style={{ width: '5%' }}>:</td>
                                            <td style={{ width: '45%' }}>{detailData?.Bank}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '35%' }}>Account No</td>
                                            <td style={{ width: '5%' }}>:</td>
                                            <td style={{ width: '45%' }}>{detailData ? detailData['Account Number'] : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '35%' }}>Account Name</td>
                                            <td style={{ width: '5%' }}>:</td>
                                            <td style={{ width: '45%' }}>{detailData ? detailData['Account Name'] : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '35%' }}>Total Balance</td>
                                            <td style={{ width: '5%' }}>:</td>
                                            <td style={{ width: '45%' }}>Rp {formatMoney(data?.current_balance.replaceAll(',', ''), '')}</td>
                                        </tr>
                                    </table>

                                    <div className="d-flex justify-content-center mt-3 flex-column">
                                        <strong>Disbursement Value</strong>
                                        <div className='input-group'>
                                            <div className='input-group-text'>
                                                Rp
                                            </div>
                                            <input type="text" className="form-control" value={disbursedAmount} onChange={(event) => { setDisbursedAmount(event.target.value) }} onKeyUp={(event) => setDisbursedAmount(formatMoney(event.target.value.replaceAll('.', ''), ''))} />
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <strong>Info</strong>
                                        <ol>
                                            <li>Input the amount you want to disburse at a minimum of 50.000 & maximum of the balance.</li>
                                            <li>Your disbursement request will be reduced by the disbursement fee.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 position-relative">
                            <div className="w-50 m-0 p-1">
                                <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal" disabled={isProcessing}>Cancel</button>
                            </div>
                            <div className="w-50 m-0 p-1">
                                <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" onClick={showAlertConfirmation} disabled={isProcessing || parseInt(disbursedAmount?.replaceAll('.', '')) < MIN_DISBURSED_AMOUNT || parseInt(disbursedAmount?.replaceAll('.', '')) > MAX_DISBURSED_AMOUNT || parseInt(disbursedAmount?.replaceAll('.', '')) > parseInt(data?.current_balance.replaceAll(',', '')) || parseInt(disbursedAmount?.replaceAll('.', '')) === 0}>Confirmation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ModalRequestDisbursement;




