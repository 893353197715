import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './ProjectDetailCss.css'
import $ from "jquery";
import { getDesignPlanList, submitDesignConcept, getFinalDesignList, getFinalDesignMaterial, generateUrl, getDetailProjectByOrderNo, getDesignPlanListCustom } from "../../helper/function"
import { IsAlert } from "../../atoms/IsAlert";
import { addDefaultSrc, formatMoney, isJsonString, validateString } from "../../helper/lib";
import moment from 'moment';


const ProjectDetailComponent = ({ project, setIsLoadingData, tsx }) => {
    // Generals
    moment.locale('en');
    const { orderNo, id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [finalDesigns, setFinalDesigns] = useState(null);

    // ================ Express Package Scope ==================
    const [designPlanCheckboxes, setDesignPlanCheckboxes] = useState([]);
    const [designPlans, setDesignPlans] = useState([]);
    const [roomTypeExpress, setRoomTypeExpress] = useState([]);

    const getDesignPlans = async () => {
        const payload = {
            orderNo: orderNo,
            id: id,
        }
        const response = await getDesignPlanList(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            setDesignPlans(response.data.designConcept)
        } else {
            setDesignPlans(null)
        }

        // Final Design
        const responseFinal = await getFinalDesignList(payload)
        if (responseFinal.message === "Success" && responseFinal.meta.total > 0) {
            setFinalDesigns(responseFinal.data.designConcept)
        } else {
            setFinalDesigns(null)
        }
    }

    const handleAddNewDesign = async () => {
        if (designPlans === null || designPlans.length < 100) {
            navigate(`/designer/project/add-design/${project?.projectInformatin?.orderNo}/${id}`)
        } else {
            IsAlert.fire({
                title: "Warning",
                text: "You already have 100 design concept.",
                icon: "warning",
            })
        }
    }

    const handleSubmitDesignConcept = async () => {
        setIsLoadingData(true)
        const payload = {
            orderNo: orderNo,
            onSubmit: true,
            id: designPlanCheckboxes.map(Number),
            designType: 1
        }
        const response = await submitDesignConcept(payload)
        if (response.message === "Success") {
            let additionalTextResponse = '';
            const responseProjectDetail = await getDetailProjectByOrderNo({ orderNo: orderNo })
            if (responseProjectDetail.message === "Success") {
                await responseProjectDetail?.data?.map(async (item, index) => {
                    if (parseInt(item?.id) !== parseInt(id)) {
                        const payloadDesignCheck = {
                            orderNo: orderNo,
                            id: parseInt(item?.id),
                        }
                        const responseDesignCheck = await getDesignPlanList(payloadDesignCheck)
                        if (responseDesignCheck.message === "Success") {
                            let findSubmittedDesign = response?.data?.designConcept?.find((dPlan) => dPlan?.onSubmit === true)
                            if (findSubmittedDesign === undefined) {
                                additionalTextResponse = ' You have 1 other room that is mandatory to submit along with this one.';
                            }
                        }
                    }

                    return;
                });
            }

            getDesignPlans()
            setDesignPlanCheckboxes([])

            IsAlert.fire({
                title: "Success",
                text: `Design concept submitted. ${additionalTextResponse}`,
                icon: "success",
            })
        }

        if (response.message !== "Success") {
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
        setIsLoadingData(false)
    }

    const getRoomTypeExpress = async () => {
        const payload = {
            orderNo: orderNo
        }
        const response = await getDetailProjectByOrderNo(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            let projectResp = response.data
            setRoomTypeExpress(projectResp)
        }
    }


    // ================ Custom Project Scope ==================
    const [moodboardCheckboxes, setMoodboardCheckboxes] = useState([]);
    const [designThreeCheckboxes, setDesignThreeCheckboxes] = useState([]);
    const [workingDrawingCheckboxes, setWorkingDrawingCheckboxes] = useState([]);
    const [designPlansMoodboard, setDesignPlansMoodboard] = useState(null);
    const [designPlansThree, setDesignPlansThree] = useState(null);
    const [designPlansWorkingDrawing, setDesignPlansWorkingDrawing] = useState(null);
    const [greetingMoodboard, setGreetingMoodboard] = useState('');
    const [greetingThree, setGreetingThree] = useState('');
    const [greetingWorkingDrawing, setGreetingWorkingDrawing] = useState('');
    const [materialList, setMaterialList] = useState(null)

    const getDesignPlansCustomProject = async () => {
        const payloadMoodboard = {
            id: id,
            orderNo: orderNo,
            designType: 1,
        }
        const payloadThree = {
            id: id,
            orderNo: orderNo,
            designType: 2,
        }
        const payloadWorkingDrawing = {
            id: id,
            orderNo: orderNo,
            designType: 3,
        }

        const responseMoodboard = await getDesignPlanListCustom(payloadMoodboard)
        if (responseMoodboard.message === "Success" && responseMoodboard.meta.total > 0) {
            setDesignPlansMoodboard(responseMoodboard.data.designConcept)
            setGreetingMoodboard(responseMoodboard.data.greeting)
            //
            const responseThree = await getDesignPlanListCustom(payloadThree)
            if (responseThree.message === "Success" && responseThree.meta.total > 0) {
                setDesignPlansThree(responseThree.data.designConcept)
                setGreetingThree(responseThree.data.greeting)
                //
                const responseWorkingDrawing = await getDesignPlanListCustom(payloadWorkingDrawing)
                if (responseWorkingDrawing.message === "Success" && responseWorkingDrawing.meta.total > 0) {
                    setDesignPlansWorkingDrawing(responseWorkingDrawing.data.designConcept)
                    setGreetingWorkingDrawing(responseWorkingDrawing.data.greeting)
                } else {
                    setDesignPlansWorkingDrawing(null)
                }
            } else {
                setDesignPlansThree(null)
            }
        } else {
            setDesignPlansMoodboard(null)
        }

        // Final Design
        const payload = {
            orderNo: orderNo,
            id: id,
        }
        // const responseFinal = await getFinalDesignList(payload)
        // if (responseFinal.message === "Success" && responseFinal.meta.total > 0) {
        //     setFinalDesigns(responseFinal.data.designConcept)
        // } else {
        //     setFinalDesigns(null)
        // }

        // Material List
        const responseMaterial = await getFinalDesignMaterial(payload)
        if (responseMaterial.message === "Success" && responseMaterial.meta.total > 0) {
            setMaterialList(responseMaterial.data?.designConcept[0])
            setFinalDesigns(responseMaterial.data?.designConcept)
        } else {
            setMaterialList(null)
            setFinalDesigns(null)
        }

    }

    const handleSubmitMoodboard = async () => {
        setIsLoadingData(true)
        const payload = {
            orderNo: orderNo,
            onSubmit: true,
            designType: 1,
            id: moodboardCheckboxes.map(Number)
        }
        const response = await submitDesignConcept(payload)
        if (response.message === "Success") {
            let additionalTextResponse = '';
            const responseProjectDetail = await getDetailProjectByOrderNo({ orderNo: orderNo })
            if (responseProjectDetail.message === "Success") {
                await responseProjectDetail?.data?.map(async (item, index) => {
                    if (parseInt(item?.id) !== parseInt(id)) {
                        const payloadDesignCheck = {
                            orderNo: orderNo,
                            id: parseInt(item?.id),
                            designType: 1,
                        }
                        const responseDesignCheck = await getDesignPlanListCustom(payloadDesignCheck)
                        if (responseDesignCheck.message === "Success") {
                            let findSubmittedDesign = response?.data?.designConcept?.find((dPlan) => dPlan?.onSubmit === true)
                            if (findSubmittedDesign === undefined) {
                                additionalTextResponse = ' You have 1 other room that is mandatory to submit along with this one.';
                            }
                        }
                    }

                    return;
                });
            }

            getDesignPlansCustomProject()
            setMoodboardCheckboxes([])

            IsAlert.fire({
                title: "Success",
                text: `Moodboard submitted. ${additionalTextResponse}`,
                icon: "success",
            })
        }

        if (response.message !== "Success") {
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
        setIsLoadingData(false)
    }

    const handleSubmitThreeDesign = async () => {
        setIsLoadingData(true)
        const payload = {
            orderNo: orderNo,
            onSubmit: true,
            designType: 2,
            id: designThreeCheckboxes.map(Number)
        }
        const response = await submitDesignConcept(payload)
        if (response.message === "Success") {
            getDesignPlansCustomProject()
            setDesignThreeCheckboxes([])

            IsAlert.fire({
                title: "Success",
                text: '3D Design submitted.',
                icon: "success",
            })
        }

        if (response.message !== "Success") {
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
        setIsLoadingData(false)
    }

    const handleSubmitWorkingDrawing = async () => {
        setIsLoadingData(true)
        const payload = {
            orderNo: orderNo,
            onSubmit: true,
            designType: 3,
            id: workingDrawingCheckboxes.map(Number)
        }
        const response = await submitDesignConcept(payload)
        if (response.message === "Success") {
            getDesignPlansCustomProject()
            setWorkingDrawingCheckboxes([])

            IsAlert.fire({
                title: "Success",
                text: 'Working Drawings submitted.',
                icon: "success",
            })
        }

        if (response.message !== "Success") {
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
        setIsLoadingData(false)
    }

    const handleSubmitMaterialList = async () => {
        setIsLoadingData(true)
        const payload = {
            orderNo: orderNo,
            onSubmit: true,
            designType: 4,
            id: [parseInt(materialList?.id)]
        }
        const response = await submitDesignConcept(payload)
        if (response.message === "Success") {
            getDesignPlansCustomProject()

            IsAlert.fire({
                title: "Success",
                text: 'Material list submitted.',
                icon: "success",
            })
        }

        if (response.message !== "Success") {
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
        setIsLoadingData(false)
    }


    // ================= General =======================
    useEffect(() => {
        // For Express Package
        if (project?.projectInformatin?.service === "Express Package" || project?.service === "Express Package") {
            getDesignPlans()
            getRoomTypeExpress()
        }

        // For Custom Project
        if (project?.projectInformatin?.service === "Custom Project") {
            getDesignPlansCustomProject()
        }

        // Event click class 'image-component'
        // $('.image-component').on('click', function () {
        //     let imgSrc = $(this).find('img').attr('src')
        // });

        // click tab if state exists
        if (location.state && location.state.tab !== undefined) {
            $(location.state.tab).trigger("click")
        }
    }, []);


    if (project.status !== "New Project" && project?.service !== "Express Package") {
        return (
            project?.projectInformatin?.service === "Express Package" ?
                // Express Package
                <div className="mt-5">
                    {/* Panel tab if status !== New Project or !== Cancelled */}
                    {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                        <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                            <div className="w-100 swiper-nav swiper">
                                <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="pills-tab" role="tablist">
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'designPlan' || location?.state?.tab === 'finalDesign' ? '' : 'active'}`} id="information-tab" data-bs-toggle="pill" data-bs-target="#information-temp" type="button" role="tab" aria-controls="information" aria-selected="true">
                                            Information
                                        </button>
                                    </li>
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'designPlan' ? 'active' : ''}`} id="dplans-tab" data-bs-toggle="pill" data-bs-target="#dplans-temp" type="button" role="tab" aria-controls="dplans" aria-selected="true" disabled={(finalDesigns !== null && finalDesigns?.length > 0)}>
                                            Design Plans
                                        </button>
                                    </li>
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'finalDesign' ? 'active' : ''}`} id="fdesign-tab" data-bs-toggle="pill" data-bs-target="#fdesign-temp" type="button" role="tab" aria-controls="fdesign" aria-selected="true" disabled={(finalDesigns === null || finalDesigns?.length === 0)}>
                                            Final Design
                                        </button>
                                    </li>
                                </ul>
                                <div className="swiper-button-next"></div>
                                <div className="swiper-button-prev"></div>
                            </div>
                        </div>
                    )}

                    <div className="tab-content" id="pills-tabContent">
                        {/* Information Tab */}
                        <div className={`tab-pane fade ${location?.state?.tab === 'designPlan' || location?.state?.tab === 'finalDesign' ? '' : 'show active'}`} id="information-temp" role="tabpanel" aria-labelledby="information-tab">
                            {/* Design Information */}
                            <div className="fs-5 fw-bold mt-2 mb-4">
                                Design Information
                            </div>
                            <table>
                                <tbody>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Project No</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.orderNo ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Service</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.service ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Status</p></td>
                                        <td>
                                            <div className="badge-new mb-3 badge-gray">{project?.projectInformatin?.status ?? '-'}</div>
                                            {/* {(project?.projectInformatin?.status == 'New Project') && (
                                                <div className="badge-new mb-3 badge-green">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status == 'On Setup') && (
                                                <div className="badge-new mb-3 badge-gray">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status == 'Create Concept') && (
                                                <div className="badge-new mb-3 badge-yellow">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status == 'Final Design') && (
                                                <div className="badge-new mb-3 badge-yellow">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status == 'Cancelled') && (
                                                <div className="badge-new mb-3 badge-red">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status == 'Complete') && (
                                                <div className="badge-new mb-3 badge-blue">{project?.projectInformatin?.status ?? '-'}</div>
                                            )} */}
                                        </td>
                                    </tr>
                                    {(project?.projectInformatin?.status === 'Cancelled') && (
                                        <>
                                            {roomTypeExpress?.map((room, index) => {
                                                return (
                                                    <tr key={index} className="align-top">
                                                        <td width="150"><p className="fz-14 ff2">Room Type {index + 1}</p></td>
                                                        <td><p className="fz-14 fw-bold">{room?.roomTypes ?? '-'}</p></td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    )}
                                    {(project?.projectInformatin?.status !== 'Cancelled') && (
                                        <>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Room Type</p></td>
                                                <td><p className="fz-14 fw-bold">{project?.projectInformatin?.roomTypes ?? '-'}</p></td>
                                            </tr>
                                        </>
                                    )}
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Design Package</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.designerPackage ?? '-'}</p></td>
                                    </tr>
                                    {/* <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Designer</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.designers ?? '-'}</p></td>
                                    </tr> */}
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Name</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.name ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Phone Number</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.phoneNumber ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Email</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.email ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Last Update</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.updatedAt ? moment(project?.projectInformatin?.updatedAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB' : '-'}</p></td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* Room Information, Wishlist, Inspiration, Budget */}
                            {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled') && (
                                <div>
                                    <hr className="mt-5 mb-5" />
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Room Information
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Residence Type</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project?.roomInformation?.residenceType !== "" && project?.roomInformation?.residenceType ? project?.roomInformation?.residenceType : '-'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150" className="pe-2"><p className="fz-14 ff2">Why do Costumer want to decorate the room?</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project?.roomInformation?.aboutProject ?? '-'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Current Room Photos</p></td>
                                                <td>
                                                    {(project?.roomInformation?.currentRoomPhotos) && (
                                                        <>
                                                            {(JSON.parse(project?.roomInformation?.currentRoomPhotos).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <div className="row mb-3" style={{ gap: "15px" }}>
                                                                {/* Image Element */}
                                                                {JSON.parse(project?.roomInformation?.currentRoomPhotos).map((photo, index) => {
                                                                    if (photo !== null) {
                                                                        return (
                                                                            <div key={index} className="col-3 rounded border p-3">
                                                                                <a href="" className="text-center image-component">
                                                                                    <img src={`${photo}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-room" onError={addDefaultSrc} />
                                                                                </a>

                                                                                <div className="d-flex mt-4">
                                                                                    {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                    <div className="d-flex flex-column">
                                                                                        <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.roomInformation?.crpNote)[index]}</p>
                                                                                    </div>
                                                                                    <a href="" className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-room" /></a>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(!project?.roomInformation?.currentRoomPhotos) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Floor Plans</p></td>
                                                <td>
                                                    {(project?.roomInformation?.floorPlanPhotos) && (
                                                        <>
                                                            {(JSON.parse(project?.roomInformation?.floorPlanPhotos).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <div className="row mb-3" style={{ gap: "15px" }}>
                                                                {/* Image Element */}
                                                                {JSON.parse(project?.roomInformation?.floorPlanPhotos).map((photo, index) => {
                                                                    if (photo !== null) {
                                                                        return (
                                                                            <div key={index} className="col-3 rounded border p-3">
                                                                                <a href="" className="text-center image-component">
                                                                                    <img src={photo} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-room" onError={addDefaultSrc} />
                                                                                </a>

                                                                                <div className="d-flex mt-4">
                                                                                    {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                    <div className="d-flex flex-column">
                                                                                        <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.roomInformation?.fpiNote)[index]}</p>
                                                                                    </div>
                                                                                    <a href="" className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-room" /></a>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(!project?.roomInformation?.currentRoomPhotos) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Dimension</p></td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td width="100"><p className="fz-14 fw-bold ml-2">Width</p></td>
                                                                <td>
                                                                    {(project?.roomInformation?.dimensions) && (
                                                                        <p className="fz-14 fw-bold ml-2">{JSON.parse(project?.roomInformation?.dimensions)[0]} cm</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="100"><p className="fz-14 fw-bold ml-2">Length</p></td>
                                                                <td>
                                                                    {(project?.roomInformation?.dimensions) && (
                                                                        <p className="fz-14 fw-bold ml-2">{JSON.parse(project?.roomInformation?.dimensions)[2]} cm</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="100"><p className="fz-14 fw-bold ml-2">Height</p></td>
                                                                <td>
                                                                    {(project?.roomInformation?.dimensions) && (
                                                                        <p className="fz-14 fw-bold ml-2">{JSON.parse(project?.roomInformation?.dimensions)[1]} cm</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Items To Keep</p></td>
                                                <td>
                                                    {(project?.roomInformation?.itemToKeep) && (
                                                        <>
                                                            {(JSON.parse(project?.roomInformation?.itemToKeep).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <div className="row mb-3" style={{ gap: "15px" }}>
                                                                {/* Image Element */}
                                                                {JSON.parse(project?.roomInformation?.itemToKeep).map((photo, index) => {
                                                                    if (photo !== null) {
                                                                        return (
                                                                            <div key={index} className="col-3 rounded border p-3">
                                                                                <a className="text-center image-component">
                                                                                    <img src={`${photo}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-item-keep" onError={addDefaultSrc} />
                                                                                </a>

                                                                                <div className="d-flex mt-4">
                                                                                    {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                    <div className="d-flex flex-column">
                                                                                        <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.roomInformation?.itkNote)[index]}</p>
                                                                                    </div>
                                                                                    <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-item-keep" /></a>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(!project?.roomInformation?.itemToKeep) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Items To Remove</p></td>
                                                <td>
                                                    {(project?.roomInformation?.itemToRemove) && (
                                                        <>
                                                            {(JSON.parse(project?.roomInformation?.itemToRemove).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <div className="row mb-3" style={{ gap: "15px" }}>
                                                                {/* Image Element */}
                                                                {JSON.parse(project?.roomInformation?.itemToRemove).map((photo, index) => {
                                                                    if (photo !== null) {
                                                                        return (
                                                                            <div key={index} className="col-3 rounded border p-3">
                                                                                <a className="text-center image-component">
                                                                                    <img src={`${photo}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-item-remove" onError={addDefaultSrc} />
                                                                                </a>

                                                                                <div className="d-flex mt-4">
                                                                                    {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                    <div className="d-flex flex-column">
                                                                                        <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.roomInformation?.itrNote)[index]}</p>
                                                                                    </div>
                                                                                    <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-item-remove" /></a>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(!project?.roomInformation?.itemToRemove) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>

                                            {(project?.projectInformatin?.windowJs !== null && project?.projectInformatin?.windowJs !== "" && project?.projectInformatin?.windowJs !== undefined && project?.projectInformatin?.windowJs !== '[]') && (
                                                <>
                                                    {JSON.parse(project?.projectInformatin?.windowJs)?.map((window, index) => {
                                                        return (
                                                            <tr key={index} className="align-top">
                                                                <td width="150"><p className="fz-14 ff2">Window {index + 1}</p></td>
                                                                <td>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width="100"><p className="fz-14 fw-bold ml-2">Width</p></td>
                                                                                <td>
                                                                                    <p className="fz-14 fw-bold ml-2">{window?.width} cm</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="100"><p className="fz-14 fw-bold ml-2">Height</p></td>
                                                                                <td>
                                                                                    <p className="fz-14 fw-bold ml-2">{window?.height} cm</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {(project?.projectInformatin?.windowJs === null || project?.projectInformatin?.windowJs === "" || project?.projectInformatin?.windowJs === undefined || project?.projectInformatin?.windowJs === '[]') && (
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Window</p></td>
                                                    <td>
                                                        <p className="fz-14 fw-bold ml-2">-</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <hr className="mt-5 mb-5" />
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Wishlist
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Items To Add</p></td>
                                                <td>
                                                    {(project?.wishList?.itemToAdd) && (
                                                        <>
                                                            {(JSON.parse(project.wishList.itemToAdd).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <p className="fz-14 fw-bold ml-2">
                                                                {/* Image Element */}
                                                                {JSON.parse(project.wishList.itemToAdd).map((text, index, row) => {
                                                                    if (text !== null) {
                                                                        return (
                                                                            <span key={index}>
                                                                                {`${text}${index + 1 === row.length ? '.' : ', '}`}
                                                                            </span>
                                                                        )
                                                                    }
                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </p>
                                                        </>
                                                    )}
                                                    {(!project?.wishList?.itemToAdd) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Open To Use New Wall Color / Wallpaper</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project.wishList.openToUseNew ?? '-'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Color Tone</p></td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-3 rounded border p-3">
                                                            <a className="text-center image-component">
                                                                <img src={project.wishList.colorTone ? `${project.wishList.colorTone}?tsx=${tsx}` : ''} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-inspiration" onError={addDefaultSrc} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Color Preference</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project.wishList.colorPreference ?? '-'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Specific Audience</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project.wishList.specificAudience ?? '-'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Anything Else Costumer Like To See In Your Design?</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project.wishList.wishlistQuetion !== "" && project.wishList.wishlistQuetion ? project.wishList.wishlistQuetion : '-'}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <hr className="mt-5 mb-5" />
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Inspiration
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Inspiration Link</p></td>
                                                <td>
                                                    {(project?.insporation?.inspirationLink) && (
                                                        <>
                                                            {(JSON.parse(project.insporation.inspirationLink).length === 0) && (
                                                                <p className="fz-14 fw-bold ml-2">-</p>
                                                            )}
                                                            {JSON.parse(project.insporation.inspirationLink).map((inspirationLink, index) => {
                                                                if (inspirationLink !== null && inspirationLink?.url) {
                                                                    return (
                                                                        <>
                                                                            <a key={index} href={inspirationLink.url} target="_blank" className="fz-14 fw-bold ml-2"><u>{inspirationLink.url}</u></a><br />
                                                                        </>
                                                                    )
                                                                }
                                                                return (<div key={index}></div>)
                                                            })}
                                                        </>
                                                    )}
                                                    {(!project?.insporation?.inspirationLink) && (
                                                        <p className="fz-14 fw-bold ml-2">-</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2 mt-2">Inspiration</p></td>
                                                <td>
                                                    {(project?.insporation?.inspiration) && (
                                                        <>
                                                            {(JSON.parse(project.insporation.inspiration).length === null) && (
                                                                <p className="fz-14 fw-bold ml-2 mt-2">
                                                                    No photo upload</p>
                                                            )}
                                                            <div className="row mb-3" style={{ gap: "15px" }}>
                                                                {/* Image Element */}
                                                                {JSON.parse(project.insporation.inspiration).map((photo, index) => {
                                                                    if (photo !== null) {
                                                                        return (
                                                                            <div key={index} className="col-3 rounded border p-3 mt-2">
                                                                                <a className="text-center image-component">
                                                                                    <img src={`${photo}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-inspiration" onError={addDefaultSrc} />
                                                                                </a>

                                                                                <div className="d-flex mt-4">
                                                                                    {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                    <div className="d-flex flex-column">
                                                                                        <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.insporation?.inspirationNote)[index]}</p>
                                                                                    </div>
                                                                                    <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-inspiration" /></a>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (<div key={index}></div>)
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(!project?.insporation?.inspiration) && (
                                                        <p className="fz-14 fw-bold ml-2">
                                                            No photo upload</p>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <hr className="mt-5 mb-5" />
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Budget
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Budget Range</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">Rp {project.budget.budgetRange ?? '0'}</p></td>
                                            </tr>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Budget Description</p></td>
                                                <td><p className="fz-14 fw-bold ml-2">{project.budget.budgetDesc ?? '-'}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>

                        {/* Design Plans Tab */}
                        {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                            <div className={`tab-pane fade ${location?.state?.tab === 'designPlan' ? 'show active' : ''}`} id="dplans-temp" role="tabpanel" aria-labelledby="dplans-tab">
                                {/* Greetings */}
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Greetings
                                    </div>
                                    <div>
                                        <NavLink to={{ pathname: `/designer/project/update-note/${project?.projectInformatin?.orderNo}/${id}` }} className="btn btn-fill fw-bold" state={{ noted: project?.roomInformation?.noted }} exact="true">
                                            Update Greetings
                                        </NavLink>
                                    </div>
                                </div>
                                <table>
                                    <tbody>
                                        <tr className="align-top">
                                            <td width="150"><p className="fz-14 ff2">Greetings</p></td>
                                            <td>
                                                <p className="fz-14 fw-bold">
                                                    {project?.roomInformation?.noted}
                                                    {(project?.roomInformation?.noted === '' || project?.roomInformation?.noted === null) && ("You haven’t update your greeting note.")}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Design Concept */}
                                <hr className="mt-5 mb-5" />
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Design Concept
                                    </div>
                                    {(designPlans?.find((dPlan) => dPlan?.status === 'Final Design') === undefined && ((designPlans?.filter((dPlan) => dPlan?.OnSubmit === false).length !== 0 && designPlans?.length !== 0) || designPlans?.length === 0)) && (
                                        <div>
                                            <button onClick={handleAddNewDesign} className="btn btn-fill fw-bold">
                                                Add New Design
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="w-100 mb-4 alert alert-basic" role="alert">
                                    <p className="fz-14 fw-bolder">Info:</p>
                                    <p className="mb-2 fz-14">1. Add new design and pick the design concept you want to submit.</p>
                                    <p className="mb-2 fz-14">2. The file that you send in this submission will be received by the customer after all design concepts in each room are submitted.</p>
                                </div>

                                {/* List Design Concept */}
                                {(designPlans !== null && designPlans.length > 0) && (
                                    <>
                                        <div className="design-plan-list">
                                            {/* List Item */}
                                            {designPlans.map((designPlan, index) => {
                                                return (
                                                    <div key={index} className="card card-project">
                                                        <div className="card-body p-5">
                                                            <div className="row item">
                                                                <div className="col-12 col-md-1 d-flex align-items-center">
                                                                    {((designPlan?.onSubmit === false && designPlans?.find((dPlan) => dPlan?.designChoose === true) === undefined)) && (
                                                                        <>
                                                                            <input className="form-check-input me-3" type="checkbox" value={designPlan.id} style={{ width: "30px", height: "30px", border: "3px solid black" }} checked={designPlanCheckboxes.includes(`${designPlan.id}`)} onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setDesignPlanCheckboxes([...designPlanCheckboxes, e.target.value]);
                                                                                } else {
                                                                                    setDesignPlanCheckboxes(designPlanCheckboxes.filter((item) => item !== e.target.value));
                                                                                }
                                                                            }} />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="col-12 col-md-5 content-image">
                                                                    <div className="d-flex justify-content-around align-items-start">
                                                                        {(designPlan.photos[0]?.full_image_url === "") && (
                                                                            <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="item" />
                                                                        )}
                                                                        {(designPlan.photos[0]?.full_image_url !== "") && (
                                                                            <img src={`${designPlan.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="item" />
                                                                        )}
                                                                        <div className="d-flex flex-column ms-4">
                                                                            <dl>
                                                                                <dt>TITLE</dt>
                                                                                <dd><span className="fs-5 fw-bold">{designPlan.title}</span></dd>
                                                                            </dl>
                                                                            {/* <a href="#">View</a> */}
                                                                            <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${designPlan.id}/${id}`} className="badge-yellow">
                                                                                View
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <div className="row row-cols-2 px-5">
                                                                        <dl className="col mb-0">
                                                                            <dt>LAST UPDATE</dt>
                                                                            <dd>{designPlan.lastUpdate}</dd>
                                                                            <dt>STATUS</dt>
                                                                            <dd>
                                                                                <div className="badge-new badge-gray">{designPlan.status}</div>
                                                                            </dd>
                                                                        </dl>
                                                                        <dl className="col mb-0">
                                                                            <dt>CLIENT BUDGET</dt>
                                                                            <dd>Rp {designPlan.clientBudget}</dd>
                                                                            {(designPlan?.designChoose === true) && (
                                                                                <>
                                                                                    <dt>
                                                                                    </dt>
                                                                                    <dd>
                                                                                        <div className="badge-new badge-gray" style={{ marginTop: "73px" }}><strong>Selected Design</strong></div>
                                                                                    </dd>
                                                                                </>
                                                                            )}
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {(designPlans?.find((dPlan) => dPlan?.designChoose === true) === undefined) && (
                                            <div className="text-right">
                                                <button className="btn btn-fill fw-bold" disabled={designPlanCheckboxes.length === 0} data-bs-toggle="modal" data-bs-target="#modalSubmitDesign">
                                                    Submit Design Concept
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* List Null */}
                                {(designPlans === null) && (
                                    <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                        <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                            <img src="/dist/img/empty/empty-product.png" alt="item-empty" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
                                            You haven't create your design concept yet.
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Final Design Tab */}
                        {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                            <div className={`tab-pane fade ${location?.state?.tab === 'finalDesign' ? 'show active' : ''}`} id="fdesign-temp" role="tabpanel" aria-labelledby="fdesign-tab">
                                <div className="fs-5 fw-bold mt-2 mb-4">
                                    Final Design
                                </div>

                                {/* List Final Design */}
                                {(finalDesigns !== null && finalDesigns.length > 0) && (
                                    <>
                                        <div className="final-design-list">
                                            {/* List Item */}
                                            {finalDesigns.map((finalDesign, index) => {
                                                return (
                                                    <div key={index} className="card card-project">
                                                        <div className="card-body p-5">
                                                            <div className="row item">
                                                                <div className="col-12 col-md-5">
                                                                    <div className="d-flex justify-content-around align-items-start">
                                                                        {(finalDesign.photos[0]?.full_image_url === "") && (
                                                                            <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="item" />
                                                                        )}
                                                                        {(finalDesign.photos[0]?.full_image_url !== "") && (
                                                                            <img src={`${finalDesign.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="item" />
                                                                        )}
                                                                        <div className="d-flex flex-column ms-4">
                                                                            <dl>
                                                                                <dt>TITLE</dt>
                                                                                <dd><span className="fs-5 fw-bold">{finalDesign.title}</span></dd>
                                                                            </dl>
                                                                            {/* <a href="#">View</a> */}
                                                                            <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${finalDesign.id}/${id}`} state={{ isFinal: true }} className="badge-yellow">
                                                                                View
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <div className="row row-cols-2 px-5">
                                                                        <dl className="col mb-0">
                                                                            <dt>TOTAL ITEM</dt>
                                                                            <dd>1</dd>
                                                                            <dt>LAST UPDATE</dt>
                                                                            <dd>{moment(finalDesign?.lastUpdate.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'}</dd>
                                                                        </dl>
                                                                        <dl className="col mb-0">
                                                                            <dt>CLIENT BUDGET</dt>
                                                                            <dd>Rp {finalDesign.clientBudget}</dd>
                                                                            <dt>STATUS</dt>
                                                                            <dd>
                                                                                <div className="badge-new badge-gray">{finalDesign.status}</div>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </>
                                )}

                                {/* List Null */}
                                {(finalDesigns === null || finalDesigns?.length === 0) && (
                                    <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                        <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                            <img src="/dist/img/empty/empty-product.png" alt="item-empty" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-3 fw-bold text-center" style={{ maxWidth: "519px" }}>
                                            To submit a final design, you must select from
                                            approved design concept and click make as final design.
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="modal fade" id="modalSubmitDesign" tabIndex="-1" aria-labelledby="modalSubmitDesignLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header border-0">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                        <div className="d-grid px-3 px-sm-0">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="img-wrap img-wrap-empty">
                                                    <img src="/dist/img/custom/submit-design.svg" alt="item-submit-design" />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <div className="fs-4 fw-bold text-center">
                                                    Submit Design Concept
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                                The file that you send in this submission will be received by the customer after all design concepts in each room are submitted
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 position-relative">
                                    <div className="w-50 m-0 p-1">
                                        <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                    </div>
                                    <div className="w-50 m-0 p-1">
                                        <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={handleSubmitDesignConcept}>Send Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                :
                // Custom Project
                <div className="mt-5">
                    {/* Panel tab if status !== New Project or !== Cancelled */}
                    {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                        <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                            <div className="w-100 swiper-nav swiper">
                                <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="pills-tab" role="tablist">
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'designPlan' || location?.state?.tab === 'finalDesign' ? '' : 'active'}`} id="information-tab" data-bs-toggle="pill" data-bs-target="#information-temp" type="button" role="tab" aria-controls="information" aria-selected="true">
                                            Information
                                        </button>
                                    </li>
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'designPlan' ? 'active' : ''}`} id="dplans-tab" data-bs-toggle="pill" data-bs-target="#dplans-temp" type="button" role="tab" aria-controls="dplans" aria-selected="true" disabled={!(designPlansMoodboard?.find(design => { return design?.isFinaliz === true }) === undefined || designPlansThree?.find(design => { return design?.isFinaliz === true }) === undefined || designPlansWorkingDrawing?.find(design => { return design?.isFinaliz === true }) === undefined)}>
                                            Design Plans
                                        </button>
                                    </li>
                                    <li className="nav-item swiper-slide" role="presentation">
                                        <button className={`nav-link ${location?.state?.tab === 'finalDesign' ? 'active' : ''}`} id="fdesign-tab" data-bs-toggle="pill" data-bs-target="#fdesign-temp" type="button" role="tab" aria-controls="fdesign" aria-selected="true" disabled={(designPlansMoodboard?.find(design => { return design?.isFinaliz === true }) === undefined || designPlansThree?.find(design => { return design?.isFinaliz === true }) === undefined || designPlansWorkingDrawing?.find(design => { return design?.isFinaliz === true }) === undefined)}>
                                            Final Design
                                        </button>
                                    </li>
                                </ul>
                                <div className="swiper-button-next"></div>
                                <div className="swiper-button-prev"></div>
                            </div>
                        </div>
                    )}

                    <div className="tab-content" id="pills-tabContent">
                        {/* Information Tab */}
                        <div className={`tab-pane fade ${location?.state?.tab === 'designPlan' || location?.state?.tab === 'finalDesign' ? '' : 'show active'}`} id="information-temp" role="tabpanel" aria-labelledby="information-tab">
                            {/* Design Information */}
                            <div className="fs-5 fw-bold mt-2 mb-4">
                                Design Information
                            </div>
                            <table>
                                <tbody>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Project No</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.orderNo ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Service</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.service ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Status</p></td>
                                        <td>
                                            <div className="badge-new mb-3 badge-gray">{project?.projectInformatin?.status ?? '-'}</div>
                                            {/* {(project?.projectInformatin?.status === 'New Project') && (
                                                <div className="badge-new mb-3 badge-green">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status === 'On Setup') && (
                                                <div className="badge-new mb-3 badge-gray">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status === 'Create Concept') && (
                                                <div className="badge-new mb-3 badge-yellow">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status === 'Final Design') && (
                                                <div className="badge-new mb-3 badge-yellow">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status === 'Cancelled') && (
                                                <div className="badge-new mb-3 badge-red">{project?.projectInformatin?.status ?? '-'}</div>
                                            )}
                                            {(project?.projectInformatin?.status === 'Complete') && (
                                                <div className="badge-new mb-3 badge-blue">{project?.projectInformatin?.status ?? '-'}</div>
                                            )} */}
                                        </td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Property Type</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.propertyType ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Budget</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.budget ? formatMoney(project?.projectInformatin?.budget) : '-'}</p></td>
                                    </tr>
                                    {/* <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Company Name</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.designers ?? '-'}</p></td>
                                    </tr> */}
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Name</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.name ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Phone Number</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.phoneNumber ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Email</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.email ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Project Address</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.address ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">About Project</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.projectInformatin?.aboutProject ?? '-'}</p></td>
                                    </tr>
                                    <tr className="align-top">
                                        <td width="150"><p className="fz-14 ff2">Total Room</p></td>
                                        <td><p className="fz-14 fw-bold">{project?.roomInformation?.totalRoom ?? '-'}</p></td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* Room Information */}
                            <hr className="mt-5 mb-5" />
                            <div className="fs-5 fw-bold mt-2 mb-4">
                                Room Information
                            </div>

                            {(project?.roomInformation?.listRoomInfo) && project?.roomInformation?.listRoomInfo.map((room, index) => {
                                return (
                                    <div key={index}>
                                        <table>
                                            <tbody>
                                                <tr className="align-top">
                                                    {(project?.roomInformation?.listRoomInfo?.length > 1) && (
                                                        <td width="150"><p className="fz-14 ff2">Room Name {index + 1}</p></td>
                                                    )}
                                                    {(project?.roomInformation?.listRoomInfo?.length <= 1) && (
                                                        <td width="150"><p className="fz-14 ff2">Room Name</p></td>
                                                    )}
                                                    <td><p className="fz-14 fw-bold">{room.roomName ?? '-'}</p></td>
                                                </tr>
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Short Description</p></td>
                                                    <td><p className="fz-14 fw-bold">{room.shortDesc ?? '-'}</p></td>
                                                </tr>
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Link Inspiration</p></td>
                                                    <td>
                                                        <p className="fz-14 fw-bold">
                                                            {(room.linkInspiration) && (
                                                                <a href={room.linkInspiration} target="_blank" rel="noreferrer" className="fz-14 fw-bold ml-2"><u>{room.linkInspiration}</u></a>
                                                            )}
                                                            {(!room.linkInspiration) && '-'}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Inspiration Photo</p></td>
                                                    <td>
                                                        {(isJsonString(room?.inspiration)) && (
                                                            <>
                                                                {(room?.inspiration) && (
                                                                    <>
                                                                        {(JSON.parse(room?.inspiration).length === null) && (
                                                                            <p className="fz-14 fw-bold ml-2">
                                                                                No photo upload</p>
                                                                        )}
                                                                        <div className="row mb-3" style={{ gap: "15px" }}>
                                                                            {/* Image Element */}
                                                                            {JSON.parse(room?.inspiration).map((photo, index) => {
                                                                                if (photo !== null) {
                                                                                    return (
                                                                                        <div key={index} className="col-3 rounded border p-3">
                                                                                            <a href="" className="text-center image-component">
                                                                                                <img src={`${photo}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-room" onError={addDefaultSrc} />
                                                                                            </a>

                                                                                            <div className="d-flex mt-4">
                                                                                                {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                                <div className="d-flex flex-column">
                                                                                                    <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> {JSON.parse(project?.insporation?.inspirationNote)[index]}</p>
                                                                                                </div>
                                                                                                <a href="" className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-room" /></a>
                                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={photo} download={true}>Download</a></li></ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                return (<div key={index}></div>)
                                                                            })}
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {(!room?.inspiration) && (
                                                                    <p className="fz-14 fw-bold ml-2">
                                                                        No photo upload</p>
                                                                )}
                                                            </>
                                                        )}

                                                        {(!isJsonString(room?.inspiration)) && (
                                                            <>
                                                                {(validateString(room?.inspiration) === '-') && (
                                                                    <p className="fz-14 fw-bold ml-2">
                                                                        No photo upload</p>
                                                                )}

                                                                {(validateString(room?.inspiration) !== '-') && (
                                                                    <div className="col-3 rounded border p-3">
                                                                        <a href="" className="text-center image-component">
                                                                            <img src={`${room?.inspiration}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-room" onError={addDefaultSrc} />
                                                                        </a>

                                                                        <div className="row mb-3" style={{ gap: "15px" }}>
                                                                            <div className="d-flex mt-4">
                                                                                {/* <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-room" /> */}
                                                                                <div className="d-flex flex-column">
                                                                                    <p className="fz-14 fw-bold mb-0"><span className="text-secondary">Note:</span> -</p>
                                                                                </div>
                                                                                <a href="" className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-room" /></a>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" href={room?.inspiration} download={true}>Download</a></li></ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Dimension</p></td>
                                                    <td>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td width="100"><p className="fz-14 fw-bold ml-2">Width</p></td>
                                                                    <td>
                                                                        {(room?.dimention) && (
                                                                            <p className="fz-14 fw-bold ml-2">{JSON.parse(room?.dimention)[0]} cm</p>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="100"><p className="fz-14 fw-bold ml-2">Length</p></td>
                                                                    <td>
                                                                        {(room?.dimention) && (
                                                                            <p className="fz-14 fw-bold ml-2">{JSON.parse(room?.dimention)[2]} cm</p>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="100"><p className="fz-14 fw-bold ml-2">Height</p></td>
                                                                    <td>
                                                                        {(room?.dimention) && (
                                                                            <p className="fz-14 fw-bold ml-2">{JSON.parse(room?.dimention)[1]} cm</p>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Photo Room</p></td>
                                                    <td>
                                                        {(!room?.photoRoom) && (
                                                            <p className="fz-14 fw-bold">No photo upload</p>
                                                        )}

                                                        {(room?.photoRoom) && (
                                                            <div className="row" style={{ marginBottom: "5px" }}>
                                                                <div className="col-3 rounded border p-3">
                                                                    <a className="text-center image-component">
                                                                        <img src={`${room?.photoRoom}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-inspiration" onError={addDefaultSrc} />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                                {/* <tr className="align-top">
                                                    <td width="150"><p className="fz-14 ff2">Floor Plan</p></td>
                                                    <td><p className="fz-14 fw-bold">{room.floorPlan ?? '-'}</p></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        <hr className="mt-3 mb-3" style={{ width: '50%' }} />
                                    </div>
                                )
                            })}
                        </div>

                        {/* Design Plans Tab */}
                        {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                            <div className={`tab-pane fade ${location?.state?.tab === 'designPlan' ? 'show active' : ''}`} id="dplans-temp" role="tabpanel" aria-labelledby="dplans-tab">
                                <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                                    <div className="w-100 swiper-nav swiper">
                                        <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="pills-tab-2" role="tablist">
                                            <li className="nav-item swiper-slide" role="presentation">
                                                <button className={`nav-link ${location?.state?.designType === 1 || location?.state?.designType === undefined ? 'active' : ''}`} id="moodboard-tab" data-bs-toggle="pill" data-bs-target="#moodboard-temp" type="button" role="tab" aria-controls="moodboard" aria-selected="true">
                                                    Moodboard
                                                </button>
                                            </li>
                                            <li className="nav-item swiper-slide" role="presentation">
                                                <button className={`nav-link ${location?.state?.designType === 2 ? 'active' : ''}`} id="design3d-tab" data-bs-toggle="pill" data-bs-target="#design3d-temp" type="button" role="tab" aria-controls="design3d" aria-selected="true" disabled={!(designPlansMoodboard?.find(design => design?.status === 'Final Design') !== undefined || (designPlansThree !== null && designPlansThree.length > 0) || designPlansMoodboard?.find(design => design?.isFinaliz === true) !== undefined)}>
                                                    3D Design
                                                </button>
                                            </li>
                                            <li className="nav-item swiper-slide" role="presentation">
                                                <button className={`nav-link ${location?.state?.designType === 3 ? 'active' : ''}`} id="wdrawings-tab" data-bs-toggle="pill" data-bs-target="#wdrawings-temp" type="button" role="tab" aria-controls="wdrawings" aria-selected="true" disabled={!(designPlansThree?.find(design => design?.status === 'Final Design') !== undefined || (designPlansWorkingDrawing !== null && designPlansWorkingDrawing.length > 0) || designPlansThree?.find(design => design?.isFinaliz === true) !== undefined)}>
                                                    Working Drawings
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                    </div>
                                </div>
                                <div className="tab-content" id="pills-tabContent-2">
                                    {/* Greetings */}
                                    <div className="d-flex justify-content-between">
                                        <div className="fs-5 fw-bold mt-2 mb-4">
                                            Greetings
                                        </div>
                                        <div>
                                            <NavLink to={{ pathname: `/designer/project/update-note/${project?.projectInformatin?.orderNo}/${id}` }} className="btn btn-fill fw-bold" state={{ noted: project?.projectNote }} exact="true">
                                                Update Greetings
                                            </NavLink>
                                        </div>
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr className="align-top">
                                                <td width="150"><p className="fz-14 ff2">Greetings</p></td>
                                                <td>
                                                    <p className="fz-14 fw-bold">
                                                        {project?.projectNote}
                                                        {(project?.projectNote === '' || project?.projectNote === null) && ("You haven’t update your greeting note.")}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* Moodboard Tab */}
                                    <div className={`tab-pane fade ${location?.state?.designType === 1 || location?.state?.designType === undefined ? 'show active' : ''}`} id="moodboard-temp" role="tabpanel" aria-labelledby="moodboard-tab">
                                        {/* Design Concept */}
                                        <hr className="mt-5 mb-5" />
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-5 fw-bold mt-2 mb-4">
                                                Design Concept
                                            </div>

                                            {(designPlansMoodboard?.find(design => { return design?.isFinaliz === true }) === undefined) && (
                                                <div>
                                                    <NavLink to={`/designer/project/add-design/${project?.projectInformatin?.orderNo}/${id}`} className="btn btn-fill fw-bold" state={{ designType: 1 }}>
                                                        Add New Design
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-100 mb-4 alert alert-basic" role="alert">
                                            <p className="fz-14 fw-bolder">Info:</p>
                                            <p className="mb-2 fz-14">Add new design and pick the design concept you want to submit.</p>
                                        </div>

                                        {/* List Design Concept */}
                                        {(designPlansMoodboard !== null && designPlansMoodboard.length > 0) && (
                                            <>
                                                <div className="design-plan-list">
                                                    {/* List Item */}
                                                    {designPlansMoodboard.map((designPlan, index) => {
                                                        return (
                                                            <div key={index} className="card card-project">
                                                                <div className="card-body p-5">
                                                                    <div className="row item">
                                                                        <div className="col-12 col-md-1 d-flex align-items-center">
                                                                            {((designPlan?.onSubmit === false && designPlansMoodboard?.find((dPlan) => dPlan?.designChoose === true) === undefined)) && (
                                                                                <>
                                                                                    <input className="form-check-input me-3" type="checkbox" value={designPlan.id} style={{ width: "30px", height: "30px", border: "3px solid black" }} checked={moodboardCheckboxes.includes(`${designPlan.id}`)} onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setMoodboardCheckboxes([...moodboardCheckboxes, e.target.value]);
                                                                                        } else {
                                                                                            setMoodboardCheckboxes(moodboardCheckboxes.filter((item) => item !== e.target.value));
                                                                                        }
                                                                                    }} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-12 col-md-5 content-image">
                                                                            <div className="d-flex justify-content-around align-items-start">
                                                                                {(designPlan.photos[0]?.full_image_url === "") && (
                                                                                    <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="item" />
                                                                                )}
                                                                                {(designPlan.photos[0]?.full_image_url !== "") && (
                                                                                    <img src={`${designPlan.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="item" />
                                                                                )}
                                                                                <div className="d-flex flex-column ms-4">
                                                                                    <dl>
                                                                                        <dt>TITLE</dt>
                                                                                        <dd><span className="fs-5 fw-bold">{designPlan.title}</span></dd>
                                                                                    </dl>
                                                                                    {/* <a href="#">View</a> */}
                                                                                    <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${designPlan.id}/${id}`} className="badge-yellow" state={{ designType: 1 }}>
                                                                                        View
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            <div className="row row-cols-2 px-5">
                                                                                <dl className="col mb-0">
                                                                                    <dt>LAST UPDATE</dt>
                                                                                    <dd>{designPlan.lastUpdate}</dd>
                                                                                    <dt>STATUS</dt>
                                                                                    <dd>
                                                                                        <div className="badge-new badge-gray">{designPlan.status}</div>
                                                                                    </dd>
                                                                                </dl>
                                                                                <dl className="col mb-0">
                                                                                    <dt>CLIENT BUDGET</dt>
                                                                                    <dd>{formatMoney(designPlan.clientBudget)}</dd>
                                                                                    {(designPlan?.designChoose === true) && (
                                                                                        <>
                                                                                            <dt>
                                                                                            </dt>
                                                                                            <dd>
                                                                                                <div className="badge-new badge-gray" style={{ marginTop: "73px" }}><strong>Selected Moodboard</strong></div>
                                                                                            </dd>
                                                                                        </>
                                                                                    )}
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="text-right">
                                                    <button className="btn btn-fill fw-bold" disabled={moodboardCheckboxes.length === 0} data-bs-toggle="modal" data-bs-target="#modalSubmitMoodboard">
                                                        Submit Moodboard
                                                    </button>
                                                </div>
                                                <div className="modal fade" id="modalSubmitMoodboard" tabIndex="-1" aria-labelledby="modalSubmitMoodboardLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content p-3">
                                                            <div className="modal-header border-0">
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                                                    <div className="d-grid px-3 px-sm-0">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="img-wrap img-wrap-empty">
                                                                                <img src="/dist/img/custom/submit-design.svg" alt="img-submit-design" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                                                            <div className="fs-4 fw-bold text-center">
                                                                                Submit Moodboard
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                                                            The file that you send in this submission will be received by the customer.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer border-0 position-relative">
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                                                </div>
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={handleSubmitMoodboard}>Send Now</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* List Null */}
                                        {(designPlansMoodboard === null) && (
                                            <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                                <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                                    <img src="/dist/img/empty/empty-product.png" alt="img-empty-product" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
                                                    You haven't create your design concept yet.
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* 3D Design Tab */}
                                    <div className={`tab-pane fade ${location?.state?.designType === 2 ? 'show active' : ''}`} id="design3d-temp" role="tabpanel" aria-labelledby="design3d-tab">
                                        {/* Design Concept */}
                                        <hr className="mt-5 mb-5" />
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-5 fw-bold mt-2 mb-4">
                                                Design Concept
                                            </div>

                                            {(designPlansThree?.find(design => { return design?.isFinaliz === true }) === undefined) && (
                                                <div>
                                                    <NavLink to={`/designer/project/add-design/${project?.projectInformatin?.orderNo}/${id}`} className="btn btn-fill fw-bold" state={{ designType: 2 }}>
                                                        Add New Design
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-100 mb-4 alert alert-basic" role="alert">
                                            <p className="fz-14 fw-bolder">Info:</p>
                                            <p className="mb-2 fz-14">Add new design and pick the design concept you want to submit.</p>
                                        </div>

                                        {/* List Design Concept */}
                                        {(designPlansThree !== null && designPlansThree.length > 0) && (
                                            <>
                                                <div className="design-plan-list">
                                                    {/* List Item */}
                                                    {designPlansThree.map((designPlan, index) => {
                                                        return (
                                                            <div key={index} className="card card-project">
                                                                <div className="card-body p-5">
                                                                    <div className="row item">
                                                                        <div className="col-12 col-md-1 d-flex align-items-center">
                                                                            {((designPlan?.onSubmit === false && designPlansThree?.find((dPlan) => dPlan?.designChoose === true) === undefined)) && (
                                                                                <>
                                                                                    <input className="form-check-input me-3" type="checkbox" value={designPlan.id} style={{ width: "30px", height: "30px", border: "3px solid black" }} checked={designThreeCheckboxes.includes(`${designPlan.id}`)} onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setDesignThreeCheckboxes([...designThreeCheckboxes, e.target.value]);
                                                                                        } else {
                                                                                            setDesignThreeCheckboxes(designThreeCheckboxes.filter((item) => item !== e.target.value));
                                                                                        }
                                                                                    }} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-12 col-md-5 content-image">
                                                                            <div className="d-flex justify-content-around align-items-start">
                                                                                {(designPlan.photos[0]?.full_image_url === "") && (
                                                                                    <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="img-item" />
                                                                                )}
                                                                                {(designPlan.photos[0]?.full_image_url !== "") && (
                                                                                    <img src={`${designPlan.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="img-item" />
                                                                                )}
                                                                                <div className="d-flex flex-column ms-4">
                                                                                    <dl>
                                                                                        <dt>TITLE</dt>
                                                                                        <dd><span className="fs-5 fw-bold">{designPlan.title}</span></dd>
                                                                                    </dl>
                                                                                    {/* <a href="#">View</a> */}
                                                                                    <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${designPlan.id}/${id}`} className="badge-yellow" state={{ designType: 2 }}>
                                                                                        View
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            <div className="row row-cols-2 px-5">
                                                                                <dl className="col mb-0">
                                                                                    <dt>LAST UPDATE</dt>
                                                                                    <dd>{designPlan.lastUpdate}</dd>
                                                                                    <dt>STATUS</dt>
                                                                                    <dd>
                                                                                        <div className="badge-new badge-gray">{designPlan.status}</div>
                                                                                    </dd>
                                                                                </dl>
                                                                                <dl className="col mb-0">
                                                                                    <dt>CLIENT BUDGET</dt>
                                                                                    <dd>{formatMoney(designPlan.clientBudget)}</dd>
                                                                                    {(designPlan?.designChoose === true) && (
                                                                                        <>
                                                                                            <dt>
                                                                                            </dt>
                                                                                            <dd>
                                                                                                <div className="badge-new badge-gray" style={{ marginTop: "73px" }}><strong>Selected 3D Design</strong></div>
                                                                                            </dd>
                                                                                        </>
                                                                                    )}
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="text-right">
                                                    <button className="btn btn-fill fw-bold" disabled={designThreeCheckboxes.length === 0} data-bs-toggle="modal" data-bs-target="#modalSubmitThree">
                                                        Submit 3D Design
                                                    </button>
                                                </div>
                                                <div className="modal fade" id="modalSubmitThree" tabIndex="-1" aria-labelledby="modalSubmitThreeLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content p-3">
                                                            <div className="modal-header border-0">
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                                                    <div className="d-grid px-3 px-sm-0">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="img-wrap img-wrap-empty">
                                                                                <img src="/dist/img/custom/submit-design.svg" alt="img-submit-design" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                                                            <div className="fs-4 fw-bold text-center">
                                                                                Submit 3D Design
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                                                            The file that you send in this submission will be received by the customer after the payment is complete
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer border-0 position-relative">
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                                                </div>
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={handleSubmitThreeDesign}>Send Now</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* List Null */}
                                        {(designPlansThree === null) && (
                                            <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                                <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                                    <img src="/dist/img/empty/empty-product.png" alt="img-empty-product" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
                                                    You haven't create your design concept yet.
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Working Drawings Tab */}
                                    <div className={`tab-pane fade ${location?.state?.designType === 3 ? 'show active' : ''}`} id="wdrawings-temp" role="tabpanel" aria-labelledby="wdrawings-tab">
                                        {/* Design Concept */}
                                        <hr className="mt-5 mb-5" />
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-5 fw-bold mt-2 mb-4">
                                                Design Concept
                                            </div>

                                            {(designPlansWorkingDrawing?.find(design => { return design?.isFinaliz === true }) === undefined) && (
                                                <div>
                                                    <NavLink to={`/designer/project/add-design/${project?.projectInformatin?.orderNo}/${id}`} className="btn btn-fill fw-bold" state={{ designType: 3 }}>
                                                        Add New Design
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-100 mb-4 alert alert-basic" role="alert">
                                            <p className="fz-14 fw-bolder">Info:</p>
                                            <p className="mb-2 fz-14">Add new design and pick the design concept you want to submit.</p>
                                        </div>

                                        {/* List Design Concept */}
                                        {(designPlansWorkingDrawing !== null && designPlansWorkingDrawing.length > 0) && (
                                            <>
                                                <div className="design-plan-list">
                                                    {/* List Item */}
                                                    {designPlansWorkingDrawing.map((designPlan, index) => {
                                                        return (
                                                            <div key={index} className="card card-project">
                                                                <div className="card-body p-5">
                                                                    <div className="row item">
                                                                        <div className="col-12 col-md-1 d-flex align-items-center">
                                                                            {((designPlan?.onSubmit === false && designPlansWorkingDrawing?.find((dPlan) => dPlan?.designChoose === true) === undefined)) && (
                                                                                <>
                                                                                    <input className="form-check-input me-3" type="checkbox" value={designPlan.id} style={{ width: "30px", height: "30px", border: "3px solid black" }} checked={workingDrawingCheckboxes.includes(`${designPlan.id}`)} onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setWorkingDrawingCheckboxes([...workingDrawingCheckboxes, e.target.value]);
                                                                                        } else {
                                                                                            setWorkingDrawingCheckboxes(workingDrawingCheckboxes.filter((item) => item !== e.target.value));
                                                                                        }
                                                                                    }} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-12 col-md-5 content-image">
                                                                            <div className="d-flex justify-content-around align-items-start">
                                                                                {(designPlan.photos[0]?.full_image_url === "") && (
                                                                                    <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="img-item" />
                                                                                )}
                                                                                {(designPlan.photos[0]?.full_image_url !== "") && (
                                                                                    <img src={`${designPlan.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="img-item" />
                                                                                )}
                                                                                <div className="d-flex flex-column ms-4">
                                                                                    <dl>
                                                                                        <dt>TITLE</dt>
                                                                                        <dd><span className="fs-5 fw-bold">{designPlan.title}</span></dd>
                                                                                    </dl>
                                                                                    {/* <a href="#">View</a> */}
                                                                                    <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${designPlan.id}/${id}`} className="badge-yellow" state={{ designType: 3 }}>
                                                                                        View
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            <div className="row row-cols-2 px-5">
                                                                                <dl className="col mb-0">
                                                                                    <dt>LAST UPDATE</dt>
                                                                                    <dd>{designPlan.lastUpdate}</dd>
                                                                                    <dt>STATUS</dt>
                                                                                    <dd>
                                                                                        <div className="badge-new badge-gray">{designPlan.status}</div>
                                                                                    </dd>
                                                                                </dl>
                                                                                <dl className="col mb-0">
                                                                                    <dt>CLIENT BUDGET</dt>
                                                                                    <dd>{formatMoney(designPlan.clientBudget)}</dd>
                                                                                    {(designPlan?.designChoose === true) && (
                                                                                        <>
                                                                                            <dt>
                                                                                            </dt>
                                                                                            <dd>
                                                                                                <div className="badge-new badge-gray" style={{ marginTop: "73px" }}><strong>Selected Working Drawings</strong></div>
                                                                                            </dd>
                                                                                        </>
                                                                                    )}
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="text-right">
                                                    <button className="btn btn-fill fw-bold" disabled={workingDrawingCheckboxes.length === 0} data-bs-toggle="modal" data-bs-target="#modalSubmitWorkingDrawings">
                                                        Submit Working Drawings
                                                    </button>
                                                </div>
                                                <div className="modal fade" id="modalSubmitWorkingDrawings" tabIndex="-1" aria-labelledby="modalSubmitWorkingDrawingsLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content p-3">
                                                            <div className="modal-header border-0">
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                                                    <div className="d-grid px-3 px-sm-0">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="img-wrap img-wrap-empty">
                                                                                <img src="/dist/img/custom/submit-design.svg" alt="img-submit-design" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                                                            <div className="fs-4 fw-bold text-center">
                                                                                Submit Working Drawings
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                                                            The file that you send in this submission will be received by the customer after the payment is complete
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer border-0 position-relative">
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                                                </div>
                                                                <div className="w-50 m-0 p-1">
                                                                    <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={handleSubmitWorkingDrawing}>Send Now</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* List Null */}
                                        {(designPlansWorkingDrawing === null) && (
                                            <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                                <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                                    <img src="/dist/img/empty/empty-product.png" alt="img-empty-product" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
                                                    You haven't create your design concept yet.
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Final Design Tab */}
                        {(project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== 'On Setup') && (
                            <div className={`tab-pane fade ${location?.state?.tab === 'finalDesign' ? 'show active' : ''}`} id="fdesign-temp" role="tabpanel" aria-labelledby="fdesign-tab">
                                {/* Material List */}
                                <div className="d-flex justify-content-between">
                                    <div className="fs-5 fw-bold mt-2 mb-4">
                                        Material List
                                    </div>
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        {(project?.projectInformatin?.status !== 'Complete') && (
                                            <div>
                                                <NavLink to={`/designer/project/upload-material-list/${project?.projectInformatin?.orderNo}/${id}`} className="btn btn-fill fw-bold">
                                                    {materialList === null || materialList === [] || materialList === undefined ? 'Upload' : 'Update'} Material List
                                                </NavLink>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="modal fade" id="modalSubmitMaterialList" tabIndex="-1" aria-labelledby="modalSubmitMaterialListLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content p-3">
                                            <div className="modal-header border-0">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                                    <div className="d-grid px-3 px-sm-0">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="img-wrap img-wrap-empty">
                                                                <img src="/dist/img/custom/submit-design.svg" alt="item-submit-design" />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                                            <div className="fs-4 fw-bold text-center">
                                                                Submit Material List
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                                            The file that you send in this submission will be received by the customer.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer border-0 position-relative">
                                                <div className="w-50 m-0 p-1">
                                                    <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                                <div className="w-50 m-0 p-1">
                                                    <button type="button" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={handleSubmitMaterialList}>Send Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="fz-14" dangerouslySetInnerHTML={{ __html: materialList?.materials ?? "You haven't added the materials list yet." }} />

                                {/* List Final Design */}
                                {(finalDesigns !== null && finalDesigns.length > 0) && (
                                    <>
                                        <div className="final-design-list">
                                            {/* List Item */}
                                            {finalDesigns.map((finalDesign, index) => {
                                                return (
                                                    <div key={index} className="card card-project">
                                                        <div className="card-body p-5">
                                                            <div className="row item">
                                                                <div className="col-12 col-md-5">
                                                                    <div className="d-flex justify-content-around align-items-start">
                                                                        {(finalDesign.photos[0]?.full_image_url === "") && (
                                                                            <img src="/dist/img/empty/empty-product.png" className="item-picture" alt="img-item" />
                                                                        )}
                                                                        {(finalDesign.photos[0]?.full_image_url !== "") && (
                                                                            <img src={`${finalDesign.photos[0]?.full_image_url}?tsx=${tsx}`} className="item-picture" alt="img-item" />
                                                                        )}
                                                                        <div className="d-flex flex-column ms-4">
                                                                            <dl>
                                                                                <dt>TITLE</dt>
                                                                                <dd><span className="fs-5 fw-bold">Material List</span></dd>
                                                                            </dl>
                                                                            {/* <a href="#">View</a> */}
                                                                            <NavLink to={`/designer/project/detail-design/${project?.projectInformatin?.orderNo}/${finalDesign.id}/${id}`} state={{ isFinal: true }} className="badge-yellow">
                                                                                View
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <div className="row row-cols-2 px-5">
                                                                        <dl className="col mb-0">
                                                                            <dt>LAST UPDATE</dt>
                                                                            <dd>{finalDesign.lastUpdate}</dd>
                                                                            <dt>STATUS</dt>
                                                                            <dd>
                                                                                <div className="badge-new badge-gray">{finalDesign.status}</div>
                                                                            </dd>
                                                                        </dl>
                                                                        <dl className="col mb-0">
                                                                            <dt>CLIENT BUDGET</dt>
                                                                            <dd>{formatMoney(finalDesign.clientBudget)}</dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </>
                                )}

                                {/* List Null */}
                                {(finalDesigns === null || finalDesigns?.length === 0) && (
                                    <div className="d-flex flex-column align-items-center h-100" style={{ marginTop: "75px" }}>
                                        <div className="img-wrap" style={{ "width": "300px", "height": "300px" }}>
                                            <img src="/dist/img/empty/empty-product.png" alt="img-empty-product" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-3 fw-bold text-center" style={{ maxWidth: "519px" }}>
                                            To submit a final design, you must select from
                                            approved design concept and click make as final design.
                                        </div>
                                    </div>
                                )}

                                {(materialList) && (
                                    <div className="text-right">
                                        <button className="btn btn-fill fw-bold mr-2" disabled={(materialList !== null && materialList?.onSubmit === true) || (materialList === null) || (materialList === []) || (materialList === undefined)} data-bs-toggle="modal" data-bs-target="#modalSubmitMaterialList">
                                            Submit Material List
                                        </button>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                </div >
        )
    }

    if (project.status === "New Project") {
        return (
            <div className="mt-5">
                {/* Design Information */}
                <div className="fs-5 fw-bold mt-2 mb-4">
                    Design Information
                </div>
                <table>
                    <tbody>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Project No</p></td>
                            <td><p className="fz-14 fw-bold">{project.orderNo ?? '-'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Service</p></td>
                            <td><p className="fz-14 fw-bold">{project.service ?? '-'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Status</p></td>
                            <td>
                                <div className="badge-new mb-3 badge-gray">{project?.status ?? '-'}</div>
                                {/* {(project.status === 'New Project') && (
                                    <div className="badge-new mb-3 badge-green">{project.status ?? '-'}</div>
                                )}
                                {(project.status === 'On Setup') && (
                                    <div className="badge-new mb-3 badge-gray">{project.status ?? '-'}</div>
                                )}
                                {(project.status === 'Create Concept') && (
                                    <div className="badge-new mb-3 badge-yellow">{project.status ?? '-'}</div>
                                )}
                                {(project.status === 'Final Design') && (
                                    <div className="badge-new mb-3 badge-yellow">{project.status ?? '-'}</div>
                                )}
                                {(project.status === 'Cancelled') && (
                                    <div className="badge-new mb-3 badge-red">{project.status ?? '-'}</div>
                                )}
                                {(project.status === 'Complete') && (
                                    <div className="badge-new mb-3 badge-blue">{project.status ?? '-'}</div>
                                )} */}
                            </td>
                        </tr>
                        {roomTypeExpress?.map((room, index) => {
                            return (
                                <tr key={index} className="align-top">
                                    <td width="150"><p className="fz-14 ff2">Room Type {index + 1}</p></td>
                                    <td><p className="fz-14 fw-bold">{room?.roomTypes ?? '-'}</p></td>
                                </tr>
                            )
                        })}
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Design Package</p></td>
                            <td><p className="fz-14 fw-bold">{project?.designerPackage ?? '-'}</p></td>
                        </tr>
                        {/* <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Designer</p></td>
                            <td><p className="fz-14 fw-bold">{project.designers ?? '-'}</p></td>
                        </tr> */}
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Name</p></td>
                            <td><p className="fz-14 fw-bold">{project?.name ?? '-'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Phone Number</p></td>
                            <td><p className="fz-14 fw-bold">{project?.phoneNumber ?? '-'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Email</p></td>
                            <td><p className="fz-14 fw-bold">{project?.email ?? '-'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Last Update</p></td>
                            <td><p className="fz-14 fw-bold">{project.updateAt ? moment(project.updateAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB' : '-'}</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export default ProjectDetailComponent;