import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const ResetPasswordEmail = (props) => {
  return (
    <div className="wrap-content signin">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <form>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  Reset Your Password
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <span className="pe-1 text-gray text-center fz-14">
                  Reset password email has been sent! Please check your email
                  for further instructions.
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-5 fz-14 ask">
                <span className>
                  <Link
                    to="/login"
                    className="link-dark d-flex align-items-center justify-content-center fw-semibold"
                  >
                    <div className="img-wrap me-1">
                      <img src="./dist/img/custom/arrow-left.svg" alt="img-arrow-left" />
                    </div>
                    Back to log in
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => {
  return {};
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
  };
};
export default connect(mapState, actionCreators)(ResetPasswordEmail);
