import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable } from '../DataTable';
import { formatDate } from "../../helper/lib";
import { useNavigate } from "react-router-dom";
import { capitalizeEachWord, addDefaultSrc } from '../../helper/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const MyPromoTable = ({ items, page, limit, total, isLoadingData, onChangePage, onChangeLimit, }) => {
    let navigate = useNavigate();
    const columns = [
        {
            name: "Banner",
            selector: "promo_name",
            width: "0px",
            class: []
        },
        {
            name: "Promo Name",
            selector: "start",
            width: "30.5%",
            class: [],
            childClass: []
        },
        {
            name: "Start",
            selector: "start",
            width: "13.5%",
            class: [],
            childClass: []
        },
        {
            name: "End",
            selector: "end",
            width: "14%",
            class: [],
            childClass: []
        },
        // {
        //     name: "Pending",
        //     selector: "product",
        //     width: "9%",
        //     class: [],
        //     childClass: []
        // },
        {
            name: "Approved Product",
            selector: "product",
            width: "12%",
            class: [],
            childClass: []
        },
        // {
        //     name: "Rejected",
        //     selector: "product",
        //     width: "9%",
        //     class: [],
        //     childClass: []
        // },
        {
            name: "Status",
            selector: "status",
            width: "15%",
            class: [],
            childClass: []
        },
        {
            name: "Options",
            selector: "options",
            width: "0px",
            class: [],
            childClass: ["visually-hidden"]
        }
    ]

    return (
        <CustomDataTable
            columns={columns}
            items={items}
            page={page}
            limit={limit}
            total={total}
            isLoadingData={isLoadingData}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
        >
            {isLoadingData ? (<tr><td style={{ background: "white", height: "200px" }} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                let status = '';
                switch (item.request_status?.toLowerCase()) {

                    case 'open':
                        status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                        break;
                    case 'expired':
                        status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                        break;
                    default:
                        status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                        break;

                }
                return (
                    <tr key={index}>
                        <td>
                            <div className="img-wrap">
                                <img src={item?.banner_image} alt="banner" className="object-fit-cover" onError={addDefaultSrc} />
                            </div>
                        </td>
                        <td>{item.name}</td>
                        <td>{formatDate(new Date(item.start_date))}</td>
                        <td>{formatDate(new Date(item.end_date))}</td>
                        {/* <td>{item.number_of_pending}</td> */}
                        <td>{item.number_of_approved}</td>
                        {/* <td>{item.number_of_rejected}</td> */}
                        <td>{status}</td>
                        <td>
                            <div className='d-flex' style={{ gap: '8px' }}>
                                <NavLink to={`view/${item?.id}`} className='btn btn-sm btn-warning' data-bs-toggle="tooltip" title='My Promotion Detail'>
                                    <FontAwesomeIcon icon={faEye} color="white" />
                                </NavLink>
                            </div>
                        </td>
                    </tr>
                )
            })}

        </CustomDataTable>
    )
}

export default MyPromoTable;