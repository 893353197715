import React from "react";
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import $ from "jquery";
import SwiperInstance from "swiper";


const ProfileLayout = (props) => {
    const location = useLocation();
    const [navSwiper, setNewsSwiper] = useState();
    const locationPathOne = location.pathname.split('/')[1];
    const uriPath = locationPathOne === 'designer' ? `/${locationPathOne}/profile/` : ""
    const uriPathTwo = locationPathOne === 'designer' ? `/${locationPathOne}` : ""

    useEffect(() => {

        if (!navSwiper) {
            let swiperInstance = new SwiperInstance(".swiper-nav", {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            setNewsSwiper(swiperInstance)
        }

        $(document).find(`input[type='search'][aria-controls="table_id2"]`).hide()
        $(document).find(`input[type='search'][aria-controls="table_id2"]`).parent().hide()

    }, [])
    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">

            <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                <div className="w-100 swiper-nav swiper">
                    <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="pills-tab" role="tablist">
                        <li className="nav-item swiper-slide" role="presentation">
                            {/* <button className="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">
                                Profile
                            </button> */}
                            <NavLink to={`${uriPathTwo}/profile`} end className="nav-link" >
                                Profile
                            </NavLink>
                        </li>
                        <li className="nav-item swiper-slide d-none" role="presentation">
                            {/* <button className="nav-link" id="pills-bank-account-tab" data-bs-toggle="pill" data-bs-target="#pills-bank-account" type="button" role="tab" aria-controls="pills-bank-account" aria-selected="false">
                                Bank Account
                            </button> */}
                            <NavLink to={`${uriPath}bank-account`} className="nav-link" >
                                Bank Account
                            </NavLink>
                        </li>
                        <li className="nav-item swiper-slide" role="presentation">
                            {/* <button className="nav-link" id="pills-password-tab" data-bs-toggle="pill" data-bs-target="#pills-password" type="button" role="tab" aria-controls="pills-password" aria-selected="false">
                                Password
                            </button> */}
                            <NavLink to={`${uriPath}change-password`} className="nav-link" >
                                Password
                            </NavLink>
                        </li>
                        {(locationPathOne !== 'designer') && (
                            <>
                                <li className="nav-item swiper-slide" role="presentation">
                                    {/* <button className="nav-link" id="pills-courier-tab" data-bs-toggle="pill" data-bs-target="#pills-courier" type="button" role="tab" aria-controls="pills-courier" aria-selected="false">
                                        Courier
                                    </button> */}
                                    <NavLink to={`${uriPath}courier`} className="nav-link" >
                                        Courier
                                    </NavLink>
                                </li>
                                <li className="nav-item swiper-slide" role="presentation">
                                    {/* <button className="nav-link" id="pills-setting-tab" data-bs-toggle="pill" data-bs-target="#pills-setting" type="button" role="tab" aria-controls="pills-setting" aria-selected="false">
                                        Setting
                                    </button> */}
                                    <NavLink to={`${uriPath}setting`} className="nav-link" >
                                        Setting
                                    </NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>

            <Outlet />

        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(ProfileLayout);
