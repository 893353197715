import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getItemReview } from "../../helper/function";
import StarRatingComponent from 'react-star-rating-component';
import Select from 'react-select';
import ItemReviewTable from "../../organism/ItemReview/ItemReviewTable";
import ModalReplyItemReview from "../../organism/ItemReview/modals/ModalReplyItemReview";
import $ from "jquery";
import moment from 'moment';
import 'moment/locale/id';

$.datepicker = require("bootstrap-datepicker")
moment.locale('id');

const ItemReview = (props) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalCurrent, setTotalCurrent] = useState(0);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [reviewIdWillBeReplied, setReviewIdWillBeReplied] = useState(null);
    const [starFilter, setStarFilter] = useState(0);
    const [selectedStarFilter, setSelectedStarFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState("");
    const [dateFilterEnd, setDateFilterEnd] = useState("");

    const starOptions = [
        { value: 5, label: "" },
        { value: 4, label: "" },
        { value: 3, label: "" },
        { value: 2, label: "" },
        { value: 1, label: "" },
    ];
    const customStyles = {
        star: {
            control: (provided, state) => ({
                ...provided,
                width: 150,
            }),
        }
    }

    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex", fontSize: 11 }}>
            <StarRatingComponent
                name={`rate-${value}`}
                value={value}
                starCount={5}
                editing={false}
                renderStarIcon={() => <span style={{ marginRight: "5px" }}>★</span>} /* it should return string or react component */
                starColor={"#333333"}
                emptyStarColor={"lightgray"}
            />
        </div>
    );


    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleChangeLimit = (limit) => {

    }

    const handleChangeRatingFilter = (e) => {
        setSelectedStarFilter(e)
        setStarFilter(e?.value)
    }

    const getItemReviewData = async (page = 0, limit = 10) => {
        setIsLoadingData(true)

        let payload = {
            query: "",
            rating: starFilter,
            startDate: dateFilter,
            endDate: dateFilterEnd,
            offset: page,
            limit: limit
        }
        const response = await getItemReview(payload);

        // const response = {
        //     data: [
        //         {
        //             id: "DW22REZ12",
        //             customer:{
        //                 id: 1,
        //                 name: "John Doe",
        //                 image: "/dist/img/custom/png/user-image.png",
        //             },
        //             product:{
        //                 id: 1,
        //                 name: "Toraja Chair",
        //                 image: "/dist/img/custom/png/product-image.png",
        //             },
        //             rating: 5,
        //             message: "lorem ipsum dolor sit amet.",
        //             reply_message: "lorem ipsum dolor sit amet.",
        //             update_at: "2021-01-01 01:00:00",
        //         },
        //         {
        //             id: "DW22REZ13",
        //             customer:{
        //                 id: 1,
        //                 name: "John Doe",
        //                 image: "/dist/img/custom/png/user-image.png",
        //             },
        //             product:{
        //                 id: 1,
        //                 name: "Toraja Chair",
        //                 image: "/dist/img/custom/png/product-image.png",
        //             },
        //             rating: 4,
        //             message: "lorem ipsum dolor sit amet.",
        //             reply_message: "lorem ipsum dolor sit amet.",
        //             update_at: "2021-01-01 02:00:00",
        //         },

        //     ],
        //     meta: {
        //         total: 2,
        //         total_current: 2
        //     },
        //     message: "Success"
        // }

        if (response.message === "Success" && response.meta.total > 0) {
            setData(response.data)
            setTotal(response.meta.total)
            setTotalCurrent(response.data.length)
            setIsLoadingData(false)
        }
        else {
            setData([])
            setTotal(0)
            setTotalCurrent(0)
            setIsLoadingData(false)
        }
    }

    const openModalReplyItemReview = (id) => {
        setReviewIdWillBeReplied(id)
    }

    const closeModalReplyItemReview = () => {
    }

    const resetFilter = () => {
        setPage(0)
        setLimit(10)
        setSelectedStarFilter(null)
        setStarFilter(0)
        setDateFilter("")
        setDateFilterEnd("")
        $('input[name="filterDate"]').val("");
    }

    const actionFunction = [openModalReplyItemReview]

    useEffect(() => {
        getItemReviewData(page, limit);
    }, [page, limit, starFilter, dateFilter, dateFilterEnd]);

    useEffect(() => {
        $('#datepicker').datepicker({
            templates: {
                leftArrow: '<img src="../../dist/img/custom/chevron-left.svg"></img>',
                rightArrow: '<img src="../../dist/img/custom/chevron-right.svg"></img>'
            }
        });

        $('#datepicker').on('changeDate', function (e) {
            let date = $('input[name="filterDate"]').val();
            setDateFilter(moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').format('YYYY-MM-DD'))
            setDateFilterEnd(moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').add(1, 'days').format('YYYY-MM-DD'))
        })
    }, []);

    return (
        <>
            <div className="container-fluid mb-4 px-0 tab-navigasi">

                <div className="fs-5 fw-bold mt-2 mb-4">
                    Product Reviews List
                </div>

                <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-2 mb-lg-0">
                        <Select
                            className="basic-single w-100"
                            classNamePrefix="select"
                            placeholder="Rating"
                            name="form-category"
                            options={starOptions}
                            isSearchable={false}
                            onChange={handleChangeRatingFilter}
                            formatOptionLabel={formatOptionLabel}
                            styles={customStyles.star}
                            value={selectedStarFilter}
                        />
                    </div>
                    <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
                        <div className="input-group bg-white border rounded password date w-lg-100" id="datepicker">
                            <input type="text" className="form-control border-0 box-shadow-focus-0" placeholder="Date" aria-describedby="basic-addon1" name="filterDate" />
                            <span className="input-group-append">
                                <span className="input-group-text bg-white border-0" id="basic-addon1">
                                    <img src={"/dist/img/custom/calendar.svg"} className="icon-3 pt-1" alt="img-calendar"></img>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="ps-0 ps-lg-4 w-lg-100 mt-2 mt-lg-0 border-start border-lg-0">
                        <button className="btn btn-line btn-lg-down-fill fw-bold w-lg-100" onClick={resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>
                {(data.length > 0 || isLoadingData) &&
                    (
                        <div className="w-100 mt-3">
                            <ItemReviewTable
                                items={data}
                                total={total}
                                page={page}
                                limit={limit}
                                isLoadingData={isLoadingData}
                                onChangePage={handleChangePage}
                                onChangeLimit={handleChangeLimit}
                                actionFunction={actionFunction}
                            />
                        </div>
                    )}
                {data.length === 0 && !isLoadingData &&
                    (
                        <>
                            {/* <!-- Empty --> */}
                            <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                                <div className="d-grid px-3 px-sm-0">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="img-wrap img-wrap-empty">
                                            <img alt="" src={"/dist/img/empty/empty-product.png"} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <div className="fs-6 fw-bold text-center">
                                            No Product Review Found!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>

            <ModalReplyItemReview
                handleClose={closeModalReplyItemReview}
                closeOnClickBackdrop={false}
                data={data.find((item) => item.id === reviewIdWillBeReplied)}
            />
        </>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(ItemReview);
