import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { IsAlert } from "../../../atoms/IsAlert";
import LoadingComponent from '../../../atoms/LoadingComponent';
import { getDetailProjectByOrderNo } from "../../../helper/function";
import moment from 'moment';


const ProjectDetailRoom = (props) => {
    moment.locale('en');
    const navigate = useNavigate();
    const { orderNo } = useParams();
    const [isLoadingData, setIsLoadingData] = useState(false);

    //
    const [project, setProject] = useState(null);

    //
    const getProjectData = async () => {
        // Set loading
        setIsLoadingData(true)

        const payload = {
            orderNo: orderNo
        }
        const response = await getDetailProjectByOrderNo(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            let projectResp = response.data
            // projectResp.projectInformatin.service = 'Custom Project' // For testing
            setProject(projectResp)
            // Set loading
            setIsLoadingData(false)
        } else {
            setProject(null)
            // Set loading
            setIsLoadingData(false)

            // Alert
            IsAlert.fire({
                title: "Error",
                text: response?.message ?? 'Something when wrong.',
                icon: "error",
            })
        }
    }

    //
    useEffect(() => {
        getProjectData();
    }, [])

    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            <div className="mb-4">
                <a onClick={() => navigate(`/designer/project`)} className="fs-6 fw-bold">
                    <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                    Project Detail
                </a>
            </div>

            {/* Loading */}
            {(isLoadingData === true) && (<LoadingComponent />)}

            {/* Project Detail Component */}
            {(project !== null && isLoadingData === false) && (
                <>
                    <h4 className="mb-4 text-center border-bottom border-lg-0 pb-2">
                        {(project[0]?.service === 'Custom Project') && 'Pilih Room Name'}
                        {(project[0]?.service !== 'Custom Project') && 'Pilih Room Type'}
                    </h4>

                    {/* <div className="w-100 row justify-content-center">
                        {project.map((item, index) => {
                            return (
                                <div key={index} className="col-3">
                                    <div className="card card-project">
                                        <div className="card-body p-4">
                                            <div className="item">
                                                <div className="row row-cols-2">
                                                    {(item?.service === 'Custom Project') && (
                                                        <dl className="col mb-0">
                                                            <dt>ROOM NAME</dt>
                                                            <dd>{item?.roomName}</dd>
                                                        </dl>
                                                    )}
                                                    {(item?.service !== 'Custom Project') && (
                                                        <dl className="col mb-0">
                                                            <dt>ROOM TYPE</dt>
                                                            <dd>{item?.roomTypes}</dd>
                                                        </dl>
                                                    )}
                                                    <dl className="col mb-0">
                                                        <dt>LAST UPDATE</dt>
                                                        <dd>{moment(item?.updateAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'}</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-footer row justify-content-center">
                                            <NavLink to={`/designer/project/view/${orderNo}/${item?.Id}`} className="badge-new badge-yellow">
                                                View
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}

                    <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                        <div className="w-100 px-4 mt-4 mt-md-3 px-md-0 table-responsive">
                            <table id="table_id2" className="table table-striped" style={{ "width": "100%" }}>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: '0.9em' }} width={"1%"}>No</th>
                                        <th style={{ fontSize: '0.9em' }} width={"30%"}>Room Type</th>
                                        <th style={{ fontSize: '0.9em' }} width={"20%"}>Last Update</th>
                                        <th style={{ fontSize: '0.9em' }} width={"45%"}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {project?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: '0.9em' }}>{index + 1}</td>
                                                {(item?.service === 'Custom Project') && (
                                                    <td style={{ fontSize: '0.9em' }}>{item?.roomName}</td>
                                                )}
                                                {(item?.service !== 'Custom Project') && (
                                                    <td style={{ fontSize: '0.9em' }}>{item?.roomTypes}</td>
                                                )}
                                                <td style={{ fontSize: '0.9em' }}>{moment(item?.updateAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'}</td>
                                                <td style={{ fontSize: '0.9em' }}>
                                                    <NavLink to={`/designer/project/view/${orderNo}/${item?.Id}`} className="badge-new badge-yellow">
                                                        View
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(ProjectDetailRoom);