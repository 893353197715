import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable} from '../DataTable';
import { validateString } from "../../helper/lib";
import StarRatingComponent from 'react-star-rating-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const ProjectReviewTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit, actionFunction  }) => {
    moment.locale('en');
    
    const columns = [
        {
            name: "Order Number",
            selector: "order_number",
            width: "10.5%",
            class: []
        },
        {
            name: "Customer",
            selector: "customer",
            width: "10.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Service",
            selector: "service",
            width: "13.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Message",
            selector: "message",
            width: "34%",
            class: [],
            childClass:[]
        },
        {
            name: "Reply Message",
            selector: "reply_message",
            width: "9%",
            class: [],
            childClass:[]
        },
        {
            name: "Rating",
            selector: "rating",
            width: "25%",
            class: [],
            childClass:[]
        },
        {
            name: "Updated At",
            selector: "updated_at",
            width: "10%",
            class: [],
            childClass:[]
        },
        {
            name: "Options",
            selector: "options",
            width: "0px",
            class: [],
            childClass: ["visually-hidden"]
        }
    ]




    return (
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
                >
                     { isLoadingData ? (<tr><td style={{background:"white", height:"200px"}} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span>{validateString(item.orderNumber)}</span>
                                                    </td>
                                                    <td>
                                                        <span data-toggle="tooltip" data-placement="top" title={validateString(item.reviewer_name)}>{validateString(item?.reviewer_name.length > 50 ? item?.reviewer_name?.substring(0,50) + "..." : item?.reviewer_name)}</span> 
                                                    </td>
                                                    <td>
                                                        <span data-toggle="tooltip" data-placement="top" title={validateString(item.service)}>{validateString(item.service)}</span> 
                                                    </td>
                                                    <td>
                                                        <span data-toggle="tooltip" data-placement="top" title={validateString(item.comment)}>{validateString(item?.comment?.length > 50 ? item?.comment?.substring(0,50) + "..." : item?.comment)}</span> 
                                                    </td>
                                                    <td>
                                                        <span data-toggle="tooltip" data-placement="top" title={validateString(item.comment_reply)}>{validateString(item?.comment_reply?.length > 50 ? item?.comment_reply?.substring(0,50) + "..." : item.comment_reply)}</span> 
                                                    </td>
                                                    <td>
                                                    <StarRatingComponent
                                                        name={"start1"} /* name of the radio input, it is required */
                                                        value={item.rating} /* number of selected icon (`0` - none, `1` - first) */
                                                        starCount={5} /* number of icons in rating, default `5` */
                                                        renderStarIcon={() => <span style={{marginRight:"8px"}}>★</span>} /* it should return string or react component */
                                                        starColor={"#FCC310"} /* color of selected icons, default `#ffb400` */
                                                        emptyStarColor={"lightgray"} /* color of non-selected icons, default `#333` */
                                                        editing={false} /* is component available for editing, default `true` */
                                                    />
                                                    </td>
                                                    <td>
                                                        <span>{moment(item.updateAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'}</span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex' style={{ gap:'8px' }}>
                                                            {(item?.comment_reply === undefined || item?.comment_reply === null || item?.comment_reply === "") && (
                                                                <button type='button' onClick={() => {actionFunction[0](item.id)}} className='btn btn-sm btn-warning' data-bs-toggle="modal" data-bs-target="#ModalReply" title='Reply'>
                                                                    <FontAwesomeIcon icon={faPencil} color="white" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                               </tr>
                                           )
                                        }) }

                </CustomDataTable>
    )
}

export default ProjectReviewTable;