import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import ProjectDetailComponent from '../../../organism/Project/ProjectDetailComponent';
import { IsAlert } from "../../../atoms/IsAlert";
import LoadingComponent from '../../../atoms/LoadingComponent';
import { getDetailProject, updateProjectStatus, updateProjectQuotation, approveProjectBudgetCustom } from "../../../helper/function";
import { formatMoney } from "../../../helper/lib";


const ProjectDetail = (props) => {
    const navigate = useNavigate();
    const { orderNo, id } = useParams();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [price, setPrice] = useState(0);
    const [tsx, setTsx] = useState(Date.now());

    //
    const [project, setProject] = useState(null);

    //
    const getProjectData = async () => {
        // Set loading
        setIsLoadingData(true)

        const payload = {
            orderNo: orderNo,
            id: id
        }
        const response = await getDetailProject(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            let projectResp = response.data
            // projectResp.projectInformatin.service = 'Custom Project' // For testing
            setProject(projectResp)
            // Set loading
            setIsLoadingData(false)
        } else {
            setProject(null)
            // Set loading
            setIsLoadingData(false)

            // Alert
            IsAlert.fire({
                title: "Error",
                text: response?.message ?? 'Something when wrong.',
                icon: "error",
            })
        }
    }

    //
    useEffect(() => {
        getProjectData();
    }, [])

    // Accept Project
    const handleAcceptOrder = async () => {
        IsAlert.fire({
            title: "Accept",
            text: "Are you sure want to accept this project?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Accept",
        }).then(async function (isConfirm) {
            if (isConfirm.isConfirmed) {
                // Set loading
                setIsLoadingData(true)

                // Accept order
                const payload = {
                    orderNo: orderNo,
                    status: 1
                }
                const response = await updateProjectStatus(payload)
                if (response?.message === "Success") {
                    if (project?.service === 'Custom Project' || project?.projectInformatin?.service === 'Custom Project') {
                        getProjectData()
                    } else {
                        navigate(`/designer/project/view/${orderNo}`)
                    }
                }

                // Error
                if (response?.message !== "Success") {
                    // Set loading
                    setIsLoadingData(false)
                    IsAlert.fire({
                        title: "Fail",
                        text: response.message,
                        icon: "error",
                    })
                }
            }
        });
    }

    // Reject Project
    const handleRejectOrder = async () => {
        IsAlert.fire({
            title: "Reject",
            text: "Are you sure want to reject this project?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Reject",
        }).then(async function (isConfirm) {
            if (isConfirm.isConfirmed) {
                // Set loading
                setIsLoadingData(true)

                // Reject order
                const payload = {
                    orderNo: orderNo,
                    status: 5
                }
                const response = await updateProjectStatus(payload)
                if (response?.message === "Success") {
                    getProjectData()
                }

                // Error
                if (response?.message !== "Success") {
                    // Set loading
                    setIsLoadingData(false)
                    IsAlert.fire({
                        title: "Fail",
                        text: response.message,
                        icon: "error",
                    })
                }
            }
        });
    }

    // Approve Budget Project (Custom Project)
    const handleApprovePrice = async () => {
        IsAlert.fire({
            title: "Approve",
            text: "Are you sure want to approve this project budget?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Approve",
        }).then(async function (isConfirm) {
            if (isConfirm.isConfirmed) {
                // Set loading
                setIsLoadingData(true)

                // Approve Budget API
                const payload = {
                    order_number: orderNo
                }
                const response = await approveProjectBudgetCustom(payload)
                if (response?.message === "Success") {
                    setIsLoadingData(false)
                    getProjectData()
                }

                // Error
                if (response?.message !== "Success") {
                    // Set loading
                    getProjectData()
                    setIsLoadingData(false)
                    IsAlert.fire({
                        title: "Success",
                        text: response.message,
                        icon: "success",
                    })
                }
            }
        });
    }

    // create quotation
    const createQuotation = async () => {
        // Set loading
        setIsLoadingData(true)

        // create quotation
        const payload = {
            orderNo: orderNo,
            price: price
        }
        const response = await updateProjectQuotation(payload)
        if (response?.message === "Success") {
            setPrice(0)
            getProjectData()
            IsAlert.fire({
                title: "Success",
                text: "Create quotation success.",
                icon: "success",
            })
        }

        // Error
        if (response?.message !== "Success") {
            // Set loading
            setIsLoadingData(false)
            IsAlert.fire({
                title: "Fail",
                text: response.message,
                icon: "error",
            })
        }
    }

    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            <div className="mb-4">
                {((project?.projectInformatin?.status === 'New Project' && project?.projectInformatin?.status !== undefined) || (project?.projectInformatin?.status === 'Cancelled' && project?.projectInformatin?.status !== undefined) || ((project?.projectInformatin?.service === 'Custom Project' && project?.projectInformatin?.status === 'On Setup') && (project?.projectInformatin?.service !== undefined && project?.projectInformatin?.status !== undefined))) && (
                    <a onClick={() => navigate(`/designer/project`)} className="fs-6 fw-bold">
                        <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                        Project Detail{project?.projectInformatin?.roomTypes ? ' - ' + project?.projectInformatin?.roomTypes : '-'}
                    </a>
                )}
                {((project?.status === 'New Project' && project?.status !== undefined) || (project?.status === 'Cancelled' && project?.status !== undefined) || ((project?.service === 'Custom Project' && project?.status === 'On Setup') && (project?.service !== undefined && project?.status !== undefined))) && (
                    <a onClick={() => navigate(`/designer/project`)} className="fs-6 fw-bold">
                        <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                        Project Detail{project?.projectInformatin?.roomTypes ? ' - ' + project?.projectInformatin?.roomTypes : '-'}
                    </a>
                )}



                {((project?.projectInformatin?.status !== 'New Project' && project?.projectInformatin?.status !== undefined) && (project?.projectInformatin?.status !== 'Cancelled' && project?.projectInformatin?.status !== undefined) && (!(project?.projectInformatin?.service === 'Custom Project' && project?.projectInformatin?.status === 'On Setup') && (project?.projectInformatin?.service !== undefined && project?.projectInformatin?.status !== undefined))) && (
                    <a onClick={() => navigate(`/designer/project/view/${orderNo}`)} className="fs-6 fw-bold">
                        <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                        Project Detail{project?.projectInformatin?.roomTypes ? ' - ' + project?.projectInformatin?.roomTypes : '-'}
                    </a>
                )}
                {((project?.status !== 'New Project' && project?.status !== undefined) && (project?.status !== 'Cancelled' && project?.status !== undefined) && (!(project?.service === 'Custom Project' && project?.status === 'On Setup') && (project?.service !== undefined && project?.status !== undefined))) && (
                    <a onClick={() => navigate(`/designer/project/view/${orderNo}`)} className="fs-6 fw-bold">
                        <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                        Project Detail{project?.projectInformatin?.roomTypes ? ' - ' + project?.projectInformatin?.roomTypes : '-'}
                    </a>
                )}
            </div>


            {/* Loading */}
            {(isLoadingData === true) && (<LoadingComponent />)}

            {/* Project Detail Component */}
            {(project !== null && isLoadingData === false) && (
                <ProjectDetailComponent project={project} setIsLoadingData={props.setLoading} tsx={tsx} />
            )}

            {/* Button Accept / reject, if status new project */}
            {((project?.projectInformatin?.status === 'New Project' || project?.status === 'New Project') && isLoadingData === false) && (
                <div className="text-right">
                    <button type="button" className="btn btn-danger fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3" onClick={handleRejectOrder}>Reject</button>
                    <button type="button" className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3" onClick={handleAcceptOrder}>Accept</button>
                </div>
            )}

            {/* Button Create Quotation */}
            {(project?.projectInformatin?.status === 'On Setup' && project?.projectInformatin?.service === 'Custom Project' && project?.projectInformatin?.onapprove === false && (
                <div className="text-right">
                    {(isLoadingData === false) && (
                        <>
                            <button type="button" className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3" data-bs-toggle="modal" data-bs-target="#modalQuotation">
                                {(project?.projectInformatin?.countQutation <= 0 || project?.projectInformatin?.countQutation === null) && 'Create Quotation'}
                                {(project?.projectInformatin?.countQutation > 0) && 'Re-Negotiate'}
                            </button>
        
                            <button type="button" className="btn btn-outline-dark fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3" onClick={handleApprovePrice}>
                                Approve
                            </button>
                        </>
                    )}

                    <div className="modal fade" id="modalQuotation" tabIndex="-1" aria-labelledby="modalQuotationLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header border-0">
                                    <h5>Offered Price</h5>

                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <label className="form-label d-flex fw-semibold">Price</label>
                                    <input type="text" className="form-control" value={price} onChange={(event) => { setPrice(event.target.value) }} onKeyUp={(event) => setPrice(formatMoney(event.target.value.replaceAll('.', ''), ''))} />
                                </div>
                                <div className="modal-footer border-0">
                                    <div className="w-100">
                                        <button type="button" className="btn w-100 m-0 btn-fill fw-semibold" data-bs-dismiss="modal" onClick={createQuotation}>Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(ProjectDetail);