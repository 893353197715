import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getDataDashboardDesigner, getListProjectLatest, getListDesignConceptLatest } from "../../helper/function";
import { connect } from "react-redux";
import SwiperInstance from "swiper"
import DashboardWdLoading from "../../organism/Dashboard/DashboardWdLoading";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardWd = (props) => {
    const [projects, setProjects] = useState([]);
    const [designConcepts, setDesignConcepts] = useState([]);
    const [dataChart, setDataChart] = useState(null);
    const [textCenter, setTextCenter] = useState({
        id: 'textCenter',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx } = chart;

            ctx.save();
            ctx.font = 'bolder 30px sans-serif';
            ctx.fillStyle = '#333333';
            ctx.textAlign = 'center';
            ctx.fillText('0', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);

            // ctx.font = 'bolder 16px sans-serif';
            // ctx.fillText('Profile Viewed', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
        }
    });
    const [dataDashboard, setDataDashboard] = useState({
        projectActive: 0,
        projectComplete: 0,
        projectInComplete: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const newsEl = React.useRef(null);
    const newsSwiper = React.useRef(null);

    const getDashboardData = async () => {
        setIsLoading(true);
        let res = await getDataDashboardDesigner();

        setDataDashboard(res.data);
        setTextCenter({
            id: 'textCenter',
            beforeDatasetsDraw(chart, args, pluginOptions) {
                const { ctx } = chart;

                ctx.save();
                ctx.font = 'bolder 30px sans-serif';
                ctx.fillStyle = '#333333';
                ctx.textAlign = 'center';
                ctx.fillText(res?.data?.projectActive ?? 0, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);

                // ctx.font = 'bolder 16px sans-serif';
                // ctx.fillText('Profile Viewed', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
            }
        });
        setDataChart({
            labels: ["Complete", "Incomplete"],
            datasets: [
                {
                    data: [res?.data?.projectComplete ?? 0, res?.data?.projectInComplete ?? 0],
                    backgroundColor: ["#333333", "#EFEFEF" ],
                    hoverBackgroundColor: ["#333333", "#EFEFEF" ],
                    cutout: 100,
                    rotation: 90
                },
            ],
        })

        // Get list
        const payloadProject = {
            search: "",
            status: 0,
            date: "",
            offset: 0,
            limit: 2
        }
        const responseProject = await getListProjectLatest(payloadProject)
        if (responseProject.message === "Success" && responseProject.meta.total > 0) {
            setProjects(responseProject.data)
        }
        const responseDesignConcept = await getListDesignConceptLatest(payloadProject)
        if (responseDesignConcept.message === "Success" && responseDesignConcept.meta.total > 0) {
            setDesignConcepts(responseDesignConcept.data)
        }

        setIsLoading(false);
        props.setLoading(false);
    }

    const optionsChart = {
        plugins: {
            legend: {
                display: false,
            },
            doughnutlabel: {
                labels: [
                    {
                        text: "My Label",
                        font: {
                            size: "40"
                        },
                        textAlign: 'center'
                    }
                ]
            }
        },
    };


    useEffect(() => {

        let swiperInstance = new SwiperInstance(newsEl.current, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            slideSize: 360,
            navigation: {
                nextEl: "#swiper-button-next",
                prevEl: "#swiper-button-prev",
            },
        });

        newsSwiper.current = swiperInstance;

    }, [newsEl.current])

    useEffect(() => {
        getDashboardData();
    }, [])


    return (
        isLoading ? <DashboardWdLoading /> :
            <div className="container-fluid mb-4 px-0">
                <div className="d-lg-flex">
                    <div className="col-12 col-lg-4 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex p-3 h-100 flex-column h-100">
                            <div className="d-flex justify-content-between">
                                <h6>Your Stats</h6>
                                <img src={"/dist/img/custom/briefcase.svg"} className="icon-1" alt="img-briefcase" />
                            </div>

                            <div className="d-flex flex-column mt-3">
                                <div className="col-12 col-md-12 d-flex align-items-center justify-content-center mb-4">
                                    <div className="w-100 d-flex justify-content-center">
                                        <Doughnut data={dataChart} options={optionsChart} plugins={[textCenter]} height={150} />
                                    </div>
                                </div>

                                <h6 className="border-bottom mb-4 pb-1 w-100 text-center">Active Projects</h6>
                                <div className="col-12 col-md-5 pe-2 d-flex align-items-center w-100 justify-content-center">
                                    <div className="border-right pe-2 text-center">
                                        <p className="fw-bold mb-1">
                                            {dataDashboard?.projectComplete}
                                        </p>
                                        <p className="">
                                            Complete
                                        </p>
                                    </div>
                                    <div className="ps-2 text-center">
                                        <p className="fw-bold mb-1">
                                            {dataDashboard?.projectInComplete}
                                        </p>
                                        <p className="">
                                            Incomplete
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex flex-column p-3 h-100">
                            <div className="d-flex justify-content-between">
                                <h6>Update Your Profile</h6>
                                <img src={"/dist/img/custom/user-circle.svg"} className="icon-1" alt="img-user-circle" />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="col-7 col-md-5 d-flex justify-content-center w-100">
                                    <div className="img-wrap d-flex-justify-content-center">
                                        <img alt="img-process-pana-1" src="../dist/img/custom/process-pana-1.svg" style={{ "width": "160px", "height": "160px" }} />
                                    </div>
                                </div>

                                <p className="mt-4">Showing your complete bio will attract more costumer to hire your service.</p>
                                <NavLink to="/designer/profile" className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3">
                                    Update Profile
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 pe-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 d-flex flex-column p-3 h-100">
                            <div className="d-flex justify-content-between mb-3">
                                <h6>Upload Your Portfolio</h6>
                                <img src={"/dist/img/custom/user-circle.svg"} className="icon-1" alt="img-user-circle" />
                            </div>
                            <div className="img-wrap d-flex justify-content-center">
                                <img alt="process-cuate" src="../dist/img/custom/process-cuate-1.svg" style={{ "width": "160px", "height": "160px" }} />
                            </div>
                            <p className="mt-2">Costumer will be more attracted when you
                                upload your post portfolios. Upload your best projects!</p>
                            <NavLink to="/designer/portfolio" className="fs-6 fw-bold mt-2">
                                Upload Portfolio
                                <img src={"/dist/img/custom/caret-right-light.svg"} className="icon-3 ms-1" alt="img-caret-right-light" />
                            </NavLink>
                        </div>
                    </div>
                </div>

                {/* Latest Project */}
                <div className="col-12 mt-3 mt-lg-4">
                    <h6>Latest Project</h6>
                    {/* <!-- Project List --> */}
                    {(projects.length > 0) && (
                        <div className="w-100 d-flex flex-column">
                            {projects.map((item, index) => {
                                return (
                                    <div key={index} className="card card-project">
                                        <div className="card-body p-5">
                                            <div className="row item">
                                                <div className="col-12 col-md-6">
                                                    <div className="d-flex justify-content-around align-items-start">
                                                        <img src="../../dist/img/empty/empty-product.png" className="item-picture" alt="img-empty-product" />
                                                        <div className="d-flex flex-column ms-4">
                                                            <dl>
                                                                <dt>Order No.</dt>
                                                                <dd><span className="fs-5 fw-bold">{item.orderNo}</span></dd>
                                                            </dl>

                                                            {(item.status === 'New Project' || item.status === 'Cancelled' || (item.service === 'Custom Project' && item.status === 'On Setup')) && (
                                                                <NavLink to={`project/view/${item.orderNo}/${item.id}`} className="badge-new badge-yellow">
                                                                    View
                                                                </NavLink>
                                                            )}

                                                            {(item.status !== 'New Project' && item.status !== 'Cancelled' && !(item.service === 'Custom Project' && item.status === 'On Setup')) && (
                                                                <NavLink to={`project/view/${item.orderNo}`} className="badge-new badge-yellow">
                                                                    View
                                                                </NavLink>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="row row-cols-2 px-5">
                                                        <dl className="col mb-0">
                                                            <dt>CLIENT</dt>
                                                            <dd>{item.client}</dd>
                                                            <dt>SERVICE</dt>
                                                            <dd>{item.service}</dd>
                                                        </dl>
                                                        <dl className="col mb-0">
                                                            <dt>LAST UPDATE</dt>
                                                            <dd>-</dd>
                                                            <dt>STATUS</dt>
                                                            <dd>
                                                                <div className="badge-new badge-gray">{item.status}</div>
                                                                {/* {(item.status === 'New Project') && (
                                                                    <div className="badge-new badge-green">{item.status}</div>
                                                                )}
                                                                {(item.status === 'On Setup') && (
                                                                    <div className="badge-new badge-gray">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Create Concept') && (
                                                                    <div className="badge-new badge-yellow">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Final Design') && (
                                                                    <div className="badge-new badge-yellow">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Cancelled') && (
                                                                    <div className="badge-new badge-red">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Complete') && (
                                                                    <div className="badge-new badge-blue">{item.status}</div>
                                                                )} */}
                                                            </dd>
                                                            {/* <dd><div className="badge-new badge-yellow">Create Concept</div></dd>
                                                  <dd><div className="badge-new badge-gray">On Setup</div></dd>
                                                  <dd><div className="badge-new badge-green">Complete</div></dd>
                                                  <dd><div className="badge-new badge-red">Cancelled</div></dd> */}
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>

                {/* Latest Design Concepts */}
                <div className="col-12 mt-3 mt-lg-4">
                    <h6>Latest Design Concepts</h6>
                    {/* List Design Concept */}
                    {(designConcepts !== null && designConcepts.length > 0) && (
                        <>
                            <div className="design-plan-list">
                                {designConcepts.map((designPlan, index) => {
                                    {/* List Item */ }
                                    return (
                                        <div key={index} className="card card-project">
                                            <div className="card-body p-5">
                                                <div className="row item">
                                                    <div className="col-12 col-md-5 content-image">
                                                        <div className="d-flex justify-content-around align-items-start">
                                                            {(designPlan.photos[0]?.full_image_url === "") && (
                                                                <img src="../../../dist/img/empty/empty-product.png" className="item-picture" alt="img-item" />
                                                            )}
                                                            {(designPlan.photos[0]?.full_image_url !== "") && (
                                                                <img src={designPlan.photos[0]?.full_image_url} className="item-picture" alt="img-item" />
                                                            )}
                                                            <div className="d-flex flex-column ms-4">
                                                                <dl>
                                                                    <dt>TITLE</dt>
                                                                    <dd><span className="fs-5 fw-bold">{designPlan.title}</span></dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="row row-cols-2 px-5">
                                                            <dl className="col mb-0">
                                                                <dt>LAST UPDATE</dt>
                                                                <dd>{designPlan.lastUpdate}</dd>
                                                                <dt>STATUS</dt>
                                                                <dd>
                                                                    <div className="badge-new badge-yellow">{designPlan.status}</div>
                                                                </dd>
                                                            </dl>
                                                            <dl className="col mb-0">
                                                                <dt>CLIENT BUDGET</dt>
                                                                <dd>{designPlan.clientBudget}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>

    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(DashboardWd);
