import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ModalAddBank from "../../organism/Profile/BankAccount/modals/ModalAddBank";
import { getVendorBank, deleteVendorBank, activateVendorBank } from '../../helper/function';
import { IsAlert } from "../../atoms/IsAlert";
import LoadingComponent from "../../atoms/LoadingComponent";

const Bank = (props) => {

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const getVendorBankData = async () => {
        setIsLoadingData(true);
        const response = await getVendorBank();
        if(response.message === "Success" && response.data.length > 0){
            setIsLoadingData(false);
            // sort by bank name
            let data = response.data.sort((a, b) => {
                if(a.bank_name < b.bank_name) { return -1; }
                if(a.bank_name > b.bank_name) { return 1; }
                return 0;
            })

            setData(data);
        }else{
            setIsLoadingData(false);
        }
    }

    const handleEdit = (e) => {
        let dataToBeEdited = data.filter((item) => item.id === e.id )[0];
        setDataEdit(dataToBeEdited);
    }

    const handleAddNewBank = () => {
        setDataEdit(null);
    }

    const handleBankActivation = (id, e, codeBank) => {
        // Validasi untuk hanya boleh 1 yg di activate
        if(e.target.checked) {
            let findActivatedBank = data.find(bank => bank.status === 'Active');

            if (findActivatedBank !== undefined) {
                // set to unchecked again
                e.target.checked = false;

                IsAlert.fire({
                    title: "Warning",
                    text: "You can only activate 1 bank account!",
                    icon: "warning",
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText: "Confirm",
                  })
                  return;
            }
        }

        IsAlert.fire({
            title: "Warning",
            text: `Are you sure want to ${e.target.checked ? "activate" : "deactivate"} this bank ?`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: e.target.checked ? "Activate" : "Deactivate",
          }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                props.setLoading(true)
                let dataSend = {
                    codeBank: codeBank,
                    id: id,
                    activation: e.target.checked
                }

                //TODO: hit api here
                activateVendorBank(dataSend)
                .then((response) => {
                    if (response.message === "Success") {
                        getVendorBankData()
                        IsAlert.fire({
                            title: "Success",
                            text: `${e.target.checked ? "Activate" : "Deactivate"} bank success.`,
                            icon: "success",
                            showCancelButton: false,
                            focusConfirm: true,
                            confirmButtonText: "Confirm",
                          })
                    } else {
                        IsAlert.fire({
                            title: "Failed",
                            text: response?.message ?? 'Something when wrong.',
                            icon: "error",
                            showCancelButton: false,
                            focusConfirm: true,
                            confirmButtonText: "Confirm",
                          })
                    }
                })
                
                props.setLoading(false)
                
            }else{
                e.target.checked = !e.target.checked;
            }

          });
    }

    const handleBankDelete = (item) => {
        IsAlert.fire({
            title: "Warning",
            text: "Are you sure want to delete this bank ?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Delete",
          }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                props.setLoading(true)
                let dataSend = {
                    "bank_id": item.id,
                }
                //TODO: hit api here
                deleteVendorBank(dataSend)
                .then((response) => {
                    if (response.message === "Success") {
                        getVendorBankData()
                        IsAlert.fire({
                            title: "Success",
                            text: "Delete bank success.",
                            icon: "success",
                            showCancelButton: false,
                            focusConfirm: true,
                            confirmButtonText: "Confirm",
                          })
                    } else {
                        IsAlert.fire({
                            title: "Failed",
                            text: response?.message ?? 'Something when wrong.',
                            icon: "error",
                            showCancelButton: false,
                            focusConfirm: true,
                            confirmButtonText: "Confirm",
                          })
                    }
                })
                
                props.setLoading(false)
            }else{
            }

          });
    }
        
    useEffect(() => {
        getVendorBankData();
    }, [])
   
    return (
        <>
        <div className="tab-content" id="pills-tabContent">
            {/* <!-- Bank --> */}
            <div className="tab-pane fade show active" id="pills-bank" role="tabpanel" aria-labelledby="pills-bank-tab">
                <div className="fs-5 fw-bold mt-2 mb-4">
                    Manage Bank Accounts
                </div>
                <div className="d-md-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">

                    </div>
                    <div className="d-flex flex-column-reverse flex-md-row w-md-100 mt-4 mt-md-0">
                        <button className="btn btn-line w-md-100 fw-bold mt-3 mt-md-0" type="button" data-bs-toggle="modal" data-bs-target="#ModalAddBank" onClick={handleAddNewBank}>
                            Add Bank Account
                        </button>
                    </div>
                </div>
                <div className="w-100 mt-3">
                    {(data.length > 0 || isLoadingData) && (
                    <table id="table_id2" className="table table-striped" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th className="">No</th>
                                <th className="min-width-80">Bank Name</th>
                                <th className="min-width-80">Account Name</th>
                                <th className="min-width-80">Account Number</th>
                                <th className="min-width-80 w-30">
                                    <div className="d-flex align-items-center justify-content-center w-100">
                                        Bank Activation
                                    </div>
                                </th>
                                <th className="w-9-persen">
                                    <span className="visually-hidden">
                                        Options
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="">{index+1}</td>
                                        <td className="min-width-80 w-30">{item.bank_name}</td>
                                        <td className="min-width-80 w-30">{item.account_name}</td>
                                        <td className="min-width-80 w-30">{item.account_number}</td>
                                        <td className="min-width-80 w-30">
                                            <div className="d-flex align-items-center justify-content-center w-100">
                                                <input className="form-check-input mt-0 icon-hw-2" type="checkbox" defaultChecked={item.status === "Inactive" ? false : true} onChange={(e) => handleBankActivation(item.id, e, item.bank_code)}/>
                                            </div>
                                        </td>
                                        <td className="w-7-persen">
                                            <div className="w-100 d-flex align-items-center justify-content-end">
                                                <div className="dropdown">
                                                    <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img alt="" src={"/dist/img/custom/dots.svg"} className="icon-2"/>
                                                    </a>
                                                    
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li><button className="dropdown-item fz-14" data-bs-toggle="modal" data-bs-target="#ModalAddBank" onClick={() => handleEdit(item)}>Edit</button></li>
                                                        <li><button className="dropdown-item fz-14"  onClick={() => handleBankDelete(item)}>Delete</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    ) }
                    {data.length === 0 && isLoadingData && (
                        <LoadingComponent  classList={['py-4']}/>
                    )}
                    {data.length === 0 && !isLoadingData && (
                        <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                            <div className="d-grid px-3 px-sm-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="img-wrap img-wrap-empty">
                                        <img alt="" src={"/dist/img/empty/empty-product.png"} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4">   
                                    <div className="fs-6 fw-bold text-center">
                                        No Bank Found!
                                    </div>                                                       
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div> 

        <ModalAddBank
            handleClose={() => {}}
            closeOnClickBackdrop={() => {}}
            onSubmit={() => getVendorBankData()}
            dataEdit={dataEdit}
            />
            </>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(Bank);
