
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCrbF5gzX5aHdmpfDaYIz9-J8KZm7z6Eyw",
  authDomain: "qorser-mtp.firebaseapp.com",
  projectId: "qorser-mtp",
  storageBucket: "qorser-mtp.appspot.com",
  messagingSenderId: "184826656770",
  appId: "1:184826656770:web:6e4e234ca404519be0cf1b",
  measurementId: "G-86Y448XZN6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      getToken(messaging, { vapidKey: "BIkmJNGTeBKlXzCYgPSNp2mE9b9nwSznDFYehxD1qGnRyrVfwIAkOXz0NPehGPfDncsKt2w_-_qVz3Eb0VFlydY" })
        .then((currentToken) => {
          
          console.log('Client Token: ', currentToken);
          if (currentToken !== localStorage.getItem("firebase_token")) {
            localStorage.setItem("firebase_token", currentToken);
            window.location.reload();
          }

        })
        .catch((err) => {
          if (err && err.status) {
            console.log('An error occurred when requesting to receive the token.', err);  
          } else {
            console.log('An error occurred, Service-Worker not found.', err);
            window.location.reload();
          }
          
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
