import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DashboardLoading = () => {

    return (
        <>
             <div className="container-fluid mb-4 px-0">
                <div className="d-lg-flex">
                    <div className="col-12 col-lg-7 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex align-items-center justify-content-start p-3">
                            <div>
                                    <Skeleton width={40} />
                            </div>
                            <div className="fs-6 fw-semibold ms-3"><Skeleton width={100} /></div>
                        </div>
                        <div className="rounded-lg border w-100 d-flex align-items-center p-3 mt-3">
                            <div className="col-7 col-md-5 pe-2 d-flex align-items-center justify-content-center">
                                <div className="img-wrap d-flex align-items-center" style={{"width": "227px", "height": "227px"}}>
                                    {/* <img alt="" src="../dist/img/custom/furniture-store.svg" /> */}
                                    <Skeleton width={227} height={150} />
                                </div>
                            </div>
                            <div className="col-5 col-md-7 pe-md-5 d-flex align-items-center">
                                <div className="w-100">
                                    <div className="w-100">
                                        <div className="mb-2 mb-sm-3 fz-14"><Skeleton width={100} /></div>
                                        <div className="fs-5 fz-sm-16 fw-bold"><Skeleton width={200} /></div>
                                    </div>
                                    <div className="w-100 mt-2">
                                        <div className="mb-2 mb-sm-3 fz-14"><Skeleton width={100} /></div>
                                        <div className="w-100 d-flex justify-content-between align-items-lg-center flex-column flex-md-row">
                                            <div className="fs-5 fz-sm-16 fw-bold"><Skeleton width={200} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 ps-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold"><Skeleton width={200} /></div>
                                <div className="fw-bold"><Skeleton  width={100}/></div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                                <Skeleton width={30} height={30} />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14"><Skeleton width={100} /></div>
                                            <div className="fs-5 fw-bold"><Skeleton width={100} /></div>
                                        </div>
                                    </div>
                                    <a href="#" className="link-dark fz-14 fw-bold"><Skeleton width={100} /></a>
                                </div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                                <Skeleton width={30} height={30} />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14"><Skeleton width={100} /></div>
                                            <div className="fs-5 fw-bold"><Skeleton width={100} /></div>
                                        </div>
                                    </div>
                                    <a href="#" className="link-dark fz-14 fw-bold"><Skeleton width={100} /></a>
                                </div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                                <Skeleton width={30} height={30} />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14"><Skeleton width={100} /></div>
                                            <div className="fs-5 fw-bold"><Skeleton width={100} /></div>
                                        </div>
                                    </div>
                                    <a href="#" className="link-dark fz-14 fw-bold"><Skeleton width={100} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-lg-flex mt-3 mt-lg-4">
                    <div className="col-12 col-lg-7 pe-lg-2">
                        <div className="rounded-lg border w-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold"><Skeleton width={200} /></div>
                            </div>
                            <div className="w-100 mt-3">
                                <table className="table table-ordinary table-striped w-100" >
                                    <thead>
                                        <tr>
                                            <th className="number"><Skeleton width={100} /></th>
                                            <th className="name"><Skeleton width={100} /></th>
                                            <th className="total-items"><Skeleton width={100} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={300} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 ps-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold"><Skeleton width={200} /></div>
                            </div>
                            <div className="w-100 mt-3">
                            <table className="table table-ordinary table-striped w-100" >
                                    <thead>
                                        <tr>
                                            <th className="number"><Skeleton width={100} /></th>
                                            <th className="name"><Skeleton width={100} /></th>
                                            <th className="total-items"><Skeleton width={100} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td className="number"><Skeleton width={100} /></td>
                                            <td className="name"><Skeleton width={200} /></td>
                                            <td className="total-items"><Skeleton width={100} /></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-3 mt-lg-4">
                    <div className="rounded-lg border w-100 p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-semibold"><Skeleton width={100} /></div>
                            <div className="pagination">

                            </div>
                        </div>
                        <div className="w-100 mt-3 overflow-hidden">
                            <div className="swiper ">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                        <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                        <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                        <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DashboardLoading;




