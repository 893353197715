import React, { useEffect, useState } from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable } from '../DataTable';
import { formatDate, formatMoney, formatDateTimeSecond } from "../../helper/lib";
import { useNavigate } from "react-router-dom";
import { capitalizeEachWord, validateString, addDefaultSrc } from '../../helper/lib';
import { joinPromo, productForJoinPromoList } from "../../helper/function";
import PaginationComponent from '../../organism/DataTable/CustomDataTablePagination';
import { IsAlert } from "../../atoms/IsAlert";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const PromotionsTable = ({ items, page, limit, total, isLoadingData, onChangePage, onChangeLimit, setRetrieve, retrieve }) => {
    const [promoID, setPromoID] = useState(0);
    const [productsJoin, setProductsJoin] = useState([]);
    const [isShowJoinPopup, setIsShowJoinPopup] = useState(false);
    const [isRetrievingProduct, setIsRetrievingProduct] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isJoining, setIsJoining] = useState(false);

    const columns = [
        {
            name: "Banner",
            selector: "promo_name",
            width: "0px",
            class: []
        },
        {
            name: "Promo Name",
            selector: "start",
            width: "30.5%",
            class: [],
            childClass: []
        },
        {
            name: "Start",
            selector: "start",
            width: "13.5%",
            class: [],
            childClass: []
        },
        {
            name: "End",
            selector: "end",
            width: "10%",
            class: [],
            childClass: []
        },
        {
            name: "Product Category",
            selector: "product",
            width: "13%",
            class: [],
            childClass: []
        },
        {
            name: "Status",
            selector: "status",
            width: "15%",
            class: [],
            childClass: []
        },
        {
            name: "Options",
            selector: "options",
            width: "0px",
            class: [],
            childClass: ["visually-hidden"]
        }
    ]

    const handleJoinPromo = async (id) => {
        setPromoID(id)

        // retrive product
        setIsRetrievingProduct(true)
        try {
            const payload = {
                promo_id: id,
                offset: pageProductJoin,
                limit: limitProductJoin,
                query: search
            }
            const response = await productForJoinPromoList(payload)
            if (response?.message === "Success" && response?.meta?.total > 0) {
                setProductsJoin(response?.data)
                setTotalProductJoin(response?.meta?.total)
            } else {
                setProductsJoin([])
            }
        } catch (error) {
            setProductsJoin([])
        }
        setIsRetrievingProduct(false)
    }

    const handleJoinPromoApi = async () => {
        setIsJoining(true)
        const payload = {
            promo_id: promoID,
            products: selectedProducts,
            type: "apply"
        }
        const response = await joinPromo(payload)
        if (response?.message === "Success" || response?.message === 'Success create new record') {
            setIsShowJoinPopup(false)
            setPromoID(0)
            setSelectedProducts([])
            setProductsJoin([])
            IsAlert.fire({
                title: "Success",
                text: "Apply promo success.",
                icon: "success",
            })
        } else {
            IsAlert.fire({
                title: "Failed",
                text: response?.message ?? 'Something went wrong.',
                icon: "error",
            })
        }
        setIsJoining(false)
    }

    const handleChangeCheckboxProduct = (e) => {
        if (e.target.checked) {
            setSelectedProducts([...selectedProducts, {
                product_id: parseInt(e.target.value),
                product_variant_id: parseInt(e.target.getAttribute('data-variantid'))
            }])
        } else {
            const filtered = selectedProducts.filter((product, i) => {
                return product?.product_id !== parseInt(e.target.value)
            })
            setSelectedProducts(filtered)
        }
    }

    // Pagination state for product list join
    const [totalProductJoin, setTotalProductJoin] = useState(0);
    const [pageProductJoin, setPageProductJoin] = useState(0);
    const [limitProductJoin, setLimitProductJoin] = useState(10);
    const [search, setSearch] = useState("");
    const onChangePageProductJoin = (page) => {
        setPageProductJoin(page)
    }

    const handleSearchDataJoin = (e) => {
        if ($(e.currentTarget).prop("id") === 'button-search-product-join') {
            setSearch($('input#search-field-product-join').val())
        }

        if (e?.key === 'Enter') {
            setSearch($('input#search-field-product-join').val())
        }
    }
    useEffect(() => {
        if (promoID > 0) {
            handleJoinPromo(promoID)
        }
    }, [pageProductJoin, limitProductJoin, search])

    useEffect(() => {
        if (isShowJoinPopup === false) {
            setSearch("")
            setSelectedProducts([])
            $('input#search-field-product-join').val("")
        }
    }, [isShowJoinPopup]);

    return (
        <>
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
            >
                {isLoadingData ? (<tr><td style={{ background: "white", height: "200px" }} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                    let status = '';
                    if (item?.request_status) {
                        switch (item?.request_status?.toLowerCase()) {

                            case 'open':
                                status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                                break;
                            case 'expired':
                                status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                                break;
                            default:
                                status = <div className="badge-gray">{capitalizeEachWord(item.request_status)}</div>;
                                break;

                        }
                    }
                    return (
                        <tr key={index}>
                            <td>
                                <div className="img-wrap">
                                    <img src={item?.banner_image} alt="banner" className="object-fit-cover" onError={addDefaultSrc} />
                                </div>
                            </td>
                            <td>{item?.name}</td>
                            <td>{formatDateTimeSecond(new Date(item.start_date))}</td>
                            <td>{formatDateTimeSecond(new Date(item.end_date))}</td>
                            <td>{validateString(item?.category_name)}</td>
                            <td>{status}</td>
                            <td>
                                <div className='d-flex' style={{ gap: '8px' }}>
                                    {(item?.request_status === 'open') && (
                                        <button type='button' onClick={() => { handleJoinPromo(item?.id); setIsShowJoinPopup(true) }} className='btn btn-sm btn-warning text-white' title='Join'>
                                            Join
                                        </button>
                                    )}
                                    <NavLink to={`view/${item?.id}`} className='btn btn-sm btn-warning' data-bs-toggle="tooltip" title='Promotion Detail'>
                                        <FontAwesomeIcon icon={faEye} color="white" />
                                    </NavLink>
                                </div>
                            </td>
                        </tr>
                    )
                })}

            </CustomDataTable>

            <div style={{
                display: isShowJoinPopup ? 'block' : 'none',
                position: 'fixed',
                zIndex: 2000,
                paddingTop: '30px',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'rgba(0,0,0,0.4)'
            }}>
                <div style={{
                    backgroundColor: '#fefefe',
                    margin: 'auto',
                    padding: '20px',
                    border: '1px solid #888',
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {/* Header */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgb(204 204 204)',
                        marginBottom: '5%'
                    }}>
                        <h5>Choose product for adding promotion</h5>
                        <span style={{
                            color: '#aaaaaa',
                            float: 'right',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }} onClick={() => { setIsShowJoinPopup(false) }}>&times;</span>
                    </div>


                    {/* Content */}
                    <div className="mb-2">
                        <div className="input-group search-product flex-nowrap rounded-pill overflow-hidden w-lg-100" style={{ width: "235px", border: "1px solid #5b6167" }}>
                            <input type="text" className="form-control border-0 fz-14 box-shadow-focus-0" placeholder="Search by product name" id="search-field-product-join" onKeyUp={handleSearchDataJoin} />
                            <div className="py-1 px-1">
                                <button onClick={handleSearchDataJoin} className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center" type="button" id="button-search-product-join" style={{ width: "1.8rem", height: "1.7rem" }}>
                                    <img alt="" src="/dist/img/custom/search.svg" style={{ width: ".9rem" }} className="filter-white" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        maxHeight: '450px',
                        overflowY: 'auto',
                        padding: '15px'
                    }}>
                        {(!isRetrievingProduct && !isJoining) && (
                            <>
                                {productsJoin.map((item, index) => {
                                    return (
                                        <>
                                            <div key={index} className="card" style={{
                                                boxShadow: '0 0 12px #EBEBEB',
                                                borderRadius: '8px',
                                                marginBottom: '2rem'
                                            }}>
                                                <label for={`inputProductJoin${index}`} className="card-body p-3" style={{
                                                    cursor: item?.discount === 0 ? "pointer" : "auto"
                                                }}>
                                                    <div className="row item">
                                                        <div className='col-1' style={{
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto'
                                                        }}>
                                                            {(item?.discount === 0) && (
                                                                <input id={`inputProductJoin${index}`} className="form-check-input me-3" type="checkbox" style={{ width: "20px", height: "20px", border: "2px solid black" }} value={item?.main_product_id} onChange={handleChangeCheckboxProduct} defaultChecked={selectedProducts?.find((product) => parseInt(product?.product_id) === parseInt(item?.main_product_id)) !== undefined} data-variantid={item?.id} />
                                                            )}
                                                        </div>
                                                        <div className='col-2'>
                                                            <div className="img-wrap img-items overflow-hidden rounded">
                                                                <img src={item.product_images.length > 0 ? item.product_images[0].thumbnail_image_url : "/dist/img/brokenImage.jpg"} alt="banner" onError={addDefaultSrc} className="object-fit-cover" />
                                                            </div>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <h6>{validateString(item?.name?.length > 50 ? item?.name?.substring(0, 25) + "..." : item.name)}</h6>
                                                                <h6>{formatMoney(item.price)}</h6>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <p className='mb-1'>
                                                                    {item?.color}
                                                                    {(item?.size) && (
                                                                        <>
                                                                            {' - ' + item?.size}
                                                                        </>
                                                                    )}
                                                                    {(item?.finishing) && (
                                                                        <>
                                                                            {' - ' + item?.finishing}
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <p>{validateString(item.category_name)}</p>
                                                                {(item?.discount !== 0) && (
                                                                    <small><i style={{ color: "red" }}>Already have discount</i></small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </>
                                    )
                                })}
                            </>
                        )}
                    </div>

                    {/* Loading */}
                    {(isRetrievingProduct || isJoining) && <LoadingComponent />}


                    {/* Footer */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '5%',
                        paddingTop: '10px'
                    }}>
                        {(productsJoin.length > 0) && (
                            <>
                                <PaginationComponent
                                    total={totalProductJoin}
                                    page={pageProductJoin}
                                    limit={limitProductJoin}
                                    startPage={0}
                                    totalCurrent={productsJoin.length}
                                    setPage={onChangePageProductJoin}
                                    isLoadingData={isRetrievingProduct}
                                />
                                <hr />
                            </>
                        )}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <h6>Selected Product : {selectedProducts?.length}</h6>
                            <button type='button' className="btn btn-fill fw-bold" disabled={(isRetrievingProduct || selectedProducts?.length === 0)} onClick={handleJoinPromoApi}>Join Promo</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromotionsTable;