import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable} from '../DataTable';
import { formatMoney, validateString, formatDateTime } from "../../helper/lib";
import { capitalizeEachWord } from '../../helper/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const ItemReviewTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit, actionFunction, detailData  }) => {
    const columns = [
        {
            name: "Request No",
            selector: "order_no",
            width: "10%",
            class: []
        },
        {
            name: "Date",
            selector: "date_start",
            width: "5%",
            class: [],
            childClass:[]
        },
        // {
        //     name: "Date To",
        //     selector: "date_to",
        //     width: "13.5%",
        //     class: [],
        //     childClass:[]
        // },
        // {
        //     name: "Total Order",
        //     selector: "total_order",
        //     width: "13%",
        //     class: [],
        //     childClass:[]
        // },
        // {
        //     name: "Commission",
        //     selector: "commision",
        //     width: "13%",
        //     class: [],
        //     childClass:[]
        // },
        {
            name: "Disbursement Request (Rp)",
            selector: "amount",
            width: "5%",
            class: ["text-center"],
            childClass:[]
        },
        {
            name: "Disbursement Amount (Rp)",
            selector: "amount",
            width: "5%",
            class: ["text-center"],
            childClass:[]
        },
        {
            name: `Completion Date`,
            selector: "amount",
            width: "5%",
            class: [],
            childClass:[]
        },
        {
            name: "Status",
            selector: "status",
            width: "10%",
            class: ["text-center"],
            childClass:[]
        },
        {
            name: "",
            selector: "action",
            width: "5%",
            class: [],
            childClass:[]
        },
    ]

    return (
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
                >
                     { isLoadingData ? (<tr><td style={{background:"white", height:"200px"}} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                                            let status = '';
                                            switch(item.status?.toLowerCase()) {

                                                case 'paid':
                                                    status = <div className="badge-green">{capitalizeEachWord(item.status)}</div>;
                                                    break;
                                                case 'in_wallet':
                                                    status = <div className="badge-yellow">{capitalizeEachWord("In Wallet")}</div>;
                                                    break;
                                                default:
                                                    status = <div className="badge-gray">{capitalizeEachWord(item.status)}</div>;
                                                    break;
    
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span>{validateString(item.disburse_id)}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatDateTime(new Date(item.tanggal)))}</span>
                                                    </td>
                                                    {/* <td>
                                                        <span>{validateString(formatDateTime(new Date(item.date_to)))}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatMoney(item.total_order))}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatMoney(item.commision))}</span>
                                                    </td> */}
                                                    <td className='text-end'>
                                                        <span>{validateString(formatMoney(item?.amount_before_fee?.replaceAll(',', ''), ""))}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <span>{validateString(formatMoney(item.disbursed_amount.replaceAll(',', ''), ""))}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatDateTime(new Date(item.tanggal)))}</span>
                                                    </td>
                                                    <td className='justify-content-center d-flex'>
                                                        {status}
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <NavLink to={`${item?.id}`} className='btn btn-sm btn-warning' data-bs-toggle="tooltip" title='Disbursement Detail'>
                                                                <FontAwesomeIcon icon={faEye} color="white" />
                                                            </NavLink>
                                                        </div>
                                                    </td>
                                               </tr>
                                           )
                                        }) }

                </CustomDataTable>
    )
}

export default ItemReviewTable;