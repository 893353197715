import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchApiPost } from "../helper/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { connect } from "react-redux";
import { IsAlert } from "../atoms/IsAlert";

const ForgotPassword = (props) => {
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email harus diisi")
      .email("Harus format email"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const onSubmit = async (data) => {
    props.setLoading(true);
    var req = await fetchApiPost("merchants/session/forgot_password", data);
    var res = await req.json();
    if (res.meta.total > 0) {
      props.setLoading(false);
      IsAlert.fire({
        title: "Berhasil",
        text: "Email Verifikasi Terkirim",
        icon: "success",
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Selesai",
      }).then(function (isConfirm) {
        if (isConfirm.isConfirmed) {
          navigate("/email-sent");
        }
      });
    } else {
      props.setLoading(false);
      
      IsAlert.fire({
        title: "Gagal",
        text: res?.message ?? 'Something when wrong.',
        icon: "error",
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Konfirmasi",
      })

    }
  };
  return (
    <div className="wrap-content signin">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  Reset Your Password
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <span className="pe-1 text-gray text-center fz-14">
                  Enter your email address and we will sent further instruction.
                </span>
              </div>
              <div className="mb-3">
                <label className="form-labe fz-12 fw-bold">Email</label>
                <input
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  type="email"
                />
                <div className="invalid-feedback d-none">
                  Email / phone number is required
                </div>
              </div>
              <button type="submit" className="btn w-100 fw-semibold btn-fill">
                Reset Password
              </button>
              <div className="d-flex align-items-center justify-content-center mt-5 fz-14 ask">
                <span className>
                  <Link
                    to="/login"
                    className="link-dark d-flex align-items-center justify-content-center fw-semibold"
                  >
                    <div className="img-wrap me-1">
                      <img src="./dist/img/custom/arrow-left.svg" alt="img-arrow-left" />
                    </div>
                    Back to log in
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => {
  return {};
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
  };
};
export default connect(mapState, actionCreators)(ForgotPassword);
