import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import {
  getProvinces,
  getCities,
  getDistrict,
  getSubdistrict,
  getCourier,
  addCourier,
  editCourier,
} from '../../../../helper/function';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const ModalAddCourier = ({
  itemReview,
  onSubmit,
  isShowModal,
  handleClose,
  closeOnClickBackdrop = false,
  dataEdit = null,
  isEdit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalBackdrop = useRef(null);
  const btnClose = useRef(null);
  const [options, setOptions] = useState({});
  const [courierOptions, setCourierOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [subdistrictOptions, setSubdistrictOptions] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const customStyles = {
    city: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    province: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    district: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    subdistrict: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    courier: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
  };

  const validationSchema = Yup.object().shape({
    courier_id: Yup.string().required('Courier is Required'),
    origin_province: Yup.string().required('Province is Required'),
    origin_city: Yup.string().required('City is Required'),
    origin_district: Yup.string().required('Disctrict is Required'),
    origin_subdistrict: Yup.string().required('Sub District is Required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const getProvinceData = async () => {
    const response = await getProvinces();
    if (response.message === 'Success' && response.data.length > 0) {
      let province = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setProvinceOptions(province);
    } else {
      setProvinceOptions([]);
    }
  };

  const getCitiesData = async (id = 1) => {
    const response = await getCities(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let cities = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setCitiesOptions(cities);
      if (selectedProvince && !dataEdit) handleChangeCity(cities[0]);
      if (dataEdit) {
        let findCity = cities.find(
          (item) => item.value === dataEdit.origin_city
        );
        if (findCity) {
          handleChangeCity(findCity);
        } else {
          handleChangeCity(cities[0]);
        }
      }
    } else {
      setCitiesOptions([]);
    }
  };

  const getDistrictData = async (id) => {
    const response = await getDistrict(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let districts = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setDistrictOptions(districts);
      if (selectedCity && !dataEdit) handleChangeDistrict(districts[0]);
      if (dataEdit) {
        let findDistrict = districts.find(
          (item) => item.value === dataEdit.origin_district
        );
        if (findDistrict) {
          handleChangeDistrict(findDistrict);
        } else {
          handleChangeDistrict(districts[0]);
        }
      }
    } else {
      setDistrictOptions([]);
    }
  };
  const getSubdistrictData = async (id) => {
    const response = await getSubdistrict(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let subdistricts = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setSubdistrictOptions(subdistricts);
      if (selectedDistrict && !dataEdit)
        handleChangeSubdistrict(subdistricts[0]);
      if (dataEdit) {
        let findSubdistrict = subdistricts.find(
          (item) => item.value === dataEdit.origin_subdistrict
        );
        if (findSubdistrict) {
          handleChangeSubdistrict(findSubdistrict);
        } else {
          handleChangeSubdistrict(subdistricts[0]);
        }
      }
    } else {
      setDistrictOptions([]);
    }
  };

  const getCourierData = async () => {
    const response = await getCourier();
    if (response.message === 'Success' && response.data.length > 0) {
      let courier = response.data.map((item) => {
        return { value: item.id, label: `${item?.name} - ${item?.code}` };
      });
      setCourierOptions(courier);
    } else {
      setCourierOptions([]);
    }
  };

  const closeModal = () => {
    resetModal();
    btnClose.current.click();
    // btnClose.current.dispatchEvent(new MouseEvent("click", { bubbles: true }));
  };

  const handleChangeCourier = (e) => {
    setSelectedCourier(e);
    setValue('courier_id', e.value);
  };

  const handleChangeProvince = (e) => {
    setSelectedProvince(e);
    setValue('origin_province', e.value);
  };

  const handleChangeCity = (e) => {
    setSelectedCity(e);
    setValue('origin_city', e.value);
  };

  const handleChangeDistrict = (e) => {
    setSelectedDistrict(e);
    setValue('origin_district', e.value);
  };

  const handleChangeSubdistrict = (e) => {
    setSelectedSubdistrict(e);
    setValue('origin_subdistrict', e.value);
  };

  const onSubmitCourier = (data) => {
    setErrorMessage(null);
    setIsSubmitting(true);
    if (dataEdit) {
      const loadingToast = toast.loading('Updating courier...');
      editCourier(data)
        .then((response) => {
          if (response.message === 'Success') {
            onSubmit();
            closeModal();

            toast.update(loadingToast, {
              render: 'Courier updated',
              type: 'success',
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            setErrorMessage(response.message);
            toast.update(loadingToast, {
              render: response.message,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      const loadingToast = toast.loading('Adding new courier...');
      addCourier(data)
        .then((response) => {
          if (response.message === 'Success') {
            onSubmit();
            closeModal();
            toast.update(loadingToast, {
              render: 'New Courier Added',
              type: 'success',
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            setErrorMessage(response.message);
            toast.update(loadingToast, {
              render: response.message,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const resetModal = () => {
    setSelectedProvince(null);
    setSelectedCity(null);
    setSelectedProvince(null);
    setSelectedSubdistrict(null);
    setSelectedCourier(null);
    setValue('courier_id', '');
    setValue('origin_province', '');
    setValue('origin_city', '');
    setValue('origin_district', '');
    setValue('origin_subdistrict', '');
    setErrorMessage(null);
    setIsSubmitting(false);
  };

  useEffect(() => {
    getProvinceData();
    getCourierData();
  }, []);

  useEffect(() => {
    if (dataEdit) {
      let findProvince = provinceOptions?.find(
        (item) => item?.value === dataEdit?.origin_province
      );
      setSelectedProvince(findProvince);
      getCitiesData(dataEdit?.origin_province);
      let findCity = citiesOptions?.find(
        (item) => item?.value === dataEdit?.origin_city
      );
      setSelectedCity(findCity);
      getDistrictData(dataEdit?.origin_city);
      let findDistrict = districtOptions?.find(
        (item) => item?.value === dataEdit?.origin_district
      );
      setSelectedDistrict(findDistrict);
      getSubdistrictData(dataEdit?.origin_subdistrict);
      let findSubdistrict = subdistrictOptions?.find(
        (item) => item?.value === dataEdit?.origin_city
      );
      setSelectedSubdistrict(findSubdistrict);

      let findCourier = courierOptions?.find(
        (item) => item?.value === dataEdit?.courier_id
      );

      setSelectedCourier(findCourier);

      setValue('courier_id', findCourier?.value);
      setValue('origin_province', dataEdit?.origin_province);
      setValue('origin_city', dataEdit?.origin_city);
      setValue('origin_district', dataEdit?.origin_district);
      setValue('origin_subdistrit', dataEdit?.origin_subdistrit);
    }
  }, [dataEdit]);

  useEffect(() => {
    getCitiesData(selectedProvince?.value);
  }, [selectedProvince]);

  useEffect(() => {
    getDistrictData(selectedCity?.value);
  }, [selectedCity]);

  useEffect(() => {
    getSubdistrictData(selectedDistrict?.value);
  }, [selectedDistrict]);

  useEffect(() => {
    if (closeOnClickBackdrop) {
      const handleClickOutside = (event) => {
        if (
          modalBackdrop.current &&
          !modalBackdrop.current.contains(event.target)
        ) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      setOptions({
        'data-bs-backdrop': 'static',
        'data-bs-keyboard': 'false',
      });

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [closeOnClickBackdrop, handleClose]);

  return (
    <>
      <div
        className={`modal fade ${isShowModal ? 'show' : ''}`}
        id="ModalAddCourier"
        {...options}
        tabIndex="-1"
        aria-labelledby="ModalAddCourierLabel"
        aria-hidden={!isShowModal}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title fw-bold" id="ModalAddCityLabel">
                {!dataEdit ? 'Add Courier' : 'Edit Courier'}
              </h5>
              <button
                type="button"
                id="btn-close-modal"
                ref={btnClose}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModal()}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmitCourier)}>
              {/* input id hidden when edit */}
              {dataEdit && (
                <input type="hidden" value={dataEdit.id} {...register('id')} />
              )}
              <div className="modal-body">
                {errorMessage !== null && (
                  <div className="alert alert-danger alert-dismissible fade show">
                    {errorMessage}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrorMessage(null)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label fw-bolder">Courier Name</label>
                  <Controller
                    name="courier_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedCourier}
                        placeHolder="Select Courier"
                        options={courierOptions}
                        isSearchable={true}
                        styles={customStyles.courier}
                        menuPortalTarget={document.body}
                        onChange={handleChangeCourier}
                      />
                    )}
                  />
                  {errors.courier_id ? (
                    <span className="text-danger">
                      {errors.courier_id.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="d-sm-flex mb-3">
                  <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                    <label className="form-label fw-bolder">
                      Origin Province
                    </label>
                    <Controller
                      name="origin_province"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={selectedProvince}
                          placeholder="Select Province"
                          options={provinceOptions}
                          isSearchable={true}
                          styles={customStyles.province}
                          menuPortalTarget={document.body}
                          onChange={handleChangeProvince}
                        />
                      )}
                    />
                    {errors.origin_province ? (
                      <span className="text-danger">
                        {errors.origin_province.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-12 col-sm-6 ps-sm-2">
                    <label className="form-label fw-bolder">Origin City</label>
                    <Controller
                      name="origin_city"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={selectedCity}
                          placeholder="Select City"
                          defaultValue={
                            selectedProvince != null ? citiesOptions[0] : null
                          }
                          options={citiesOptions}
                          isSearchable={true}
                          styles={customStyles.city}
                          menuPortalTarget={document.body}
                          onChange={handleChangeCity}
                        />
                      )}
                    />
                    {errors.origin_city ? (
                      <span className="text-danger">
                        {errors.origin_city.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="d-sm-flex mb-3">
                  <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                    <label className="form-label fw-bolder">
                      Origin District
                    </label>
                    <Controller
                      name="origin_district"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={selectedDistrict}
                          placeholder="Select District"
                          defaultValue={
                            selectedCity != null ? districtOptions[0] : null
                          }
                          options={districtOptions}
                          isSearchable={true}
                          styles={customStyles.district}
                          menuPortalTarget={document.body}
                          onChange={handleChangeDistrict}
                        />
                      )}
                    />
                    {errors.origin_district ? (
                      <span className="text-danger">
                        {errors.origin_district.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-12 col-sm-6 ps-sm-2">
                    <label className="form-label fw-bolder">
                      Origin Subdistrict
                    </label>
                    <Controller
                      name="origin_subdistrict"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={selectedSubdistrict}
                          placeholder="Select Sub District"
                          defaultValue={
                            selectedDistrict != null
                              ? subdistrictOptions[0]
                              : null
                          }
                          options={subdistrictOptions}
                          isSearchable={true}
                          styles={customStyles.subdistrict}
                          menuPortalTarget={document.body}
                          onChange={handleChangeSubdistrict}
                        />
                      )}
                    />
                    {errors.origin_subdistrict ? (
                      <span className="text-danger">
                        {errors.origin_subdistrict.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 position-relative">
                <div className="w-sm-50 m-0 m-sm-1 p-1 p-sm-0">
                  <button
                    type="button"
                    className="btn w-sm-100 btn-light-gray fw-semibold"
                    data-bs-dismiss="modal"
                    onClick={() => resetModal()}
                  >
                    Close
                  </button>
                </div>
                <div className="w-sm-50 m-0 m-sm-1 p-1 p-sm-0">
                  {!isSubmitting && (
                    <button
                      type="submit"
                      className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold"
                    >
                      {!dataEdit ? 'Add Courier' : 'Edit Courier'}
                    </button>
                  )}
                  {isSubmitting && (
                    <button
                      type="submit"
                      disabled
                      className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold"
                    >
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ width: '1.5rem', height: '1.5rem' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddCourier;
