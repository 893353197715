import React from "react";
import { NavLink, Outlet } from 'react-router-dom';
import { connect } from "react-redux";


const PromotionsLayout = (props) => {
    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            
            <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                <div className="w-100 swiper-nav swiper">
                  <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" >
                    <li className="nav-item swiper-slide">
                      <NavLink to="/promotions" end className="nav-link" >
                            Promotions
                      </NavLink>
                    </li>
                    <li className="nav-item swiper-slide" role="presentation">
                        <NavLink to="my-promo" className="nav-link" >
                            My Promo
                        </NavLink>
                    </li>
                    {/* <li className="nav-item swiper-slide" role="presentation">
                        <NavLink to="promotion-product" className="nav-link" >
                            Promotion Product
                        </NavLink>
                    </li>                                    */}
                  </ul>
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
            </div>            

            <Outlet />

        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(PromotionsLayout);
