import React from "react";
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";


const ItemsLayout = (props) => {
    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            
            <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                <div className="w-100 swiper-nav swiper">
                  {/* <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="pills-tab" role="tablist">
                    <li className="nav-item swiper-slide" role="presentation">
                        <NavLink to="/items" end className="nav-link" >
                            Items
                        </NavLink>
                    </li>
                    <li className="nav-item swiper-slide" role="presentation">
                        <NavLink to="pending-review" className="nav-link" >
                            Pending Review
                        </NavLink>
                    </li>
                    <li className="nav-item swiper-slide" role="presentation">
                        <NavLink to="discount-type" className="nav-link" >
                            Discount Type
                        </NavLink>
                    </li>                                
                  </ul> */}
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
            </div>            
            <Outlet />
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(ItemsLayout);
