import React from "react";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const LoadingGeneral = (props) => {
    if (props.loadingGeneral) {
        return (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center bg-loading bg-white" style={{position:"absolute", zIndex:99999}}>
                <Stack sx={{ color: "brown100" }}>
                    <CircularProgress color="inherit" />
                </Stack>
            </div>
        )
    }
    else {
        return ""
    }
}
const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
    };
};

export default connect(mapState, actionCreators)(LoadingGeneral)