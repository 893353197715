import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getListFaq } from "../helper/function";

const SellerHelp = (props) => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const inputRef = React.useRef(null);

    const handleSearch = () => {
        setSearch(inputRef.current.value)
    }

    const handleSearchOnInput = (event) => {
        if (event.key === 'Enter') {
            setSearch(inputRef.current.value)
        }
    }

    const getListFaqApi = async () => {
        props.setLoading(true);

        let payload = {}
        const response = await getListFaq(payload);
        if (response?.message === "Success") {
            setData(response?.data)
        }

        props.setLoading(false);
    }

    useEffect(() => {
        getListFaqApi();
    }, [])

    return (
        <div className="container-fluid mb-4 px-0">

            <div className="fs-2 fz-sm-16 fw-bold mb-3 mb-md-4 w-100 d-flex align-items-center justify-content-sm-center">
                What Can We Help?
            </div>
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="input-group search-product flex-nowrap border rounded-pill overflow-hidden col-12 col-md-6 col-lg-3">
                    <input ref={inputRef} type="text" className="form-control border-0 box-shadow-focus-0" placeholder="Search here..." aria-label="Username" aria-describedby="addon-wrapping" onKeyDown={handleSearchOnInput} />
                    <div className="py-1 px-1">
                        <button onClick={handleSearch} className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center" type="button" id="button-addon2" style={{ width: "1.8rem", height: "1.7rem" }}>
                            <img src={"/dist/img/custom/search.svg"} style={{ width: "0.9rem" }} className="filter-white" alt="img-search" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-3 mt-md-5 d-flex justify-content-center faq">
                <div className="col-12">
                    <div className="accordion accordion-flush faq" id="accordionExample">
                        {data.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading-${index}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                            <div className="fs-5 fw-bold">
                                                {item?.name}
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="border-base border-3 border-start ms-5 ps-3 mb-4" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {(data.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase())).length === 0 && search !== "") && (
                                <p><i>Not Found</i></p>
                            )}
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};


export default connect(mapState, actionCreators)(SellerHelp);
