import React from "react";
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";



const OrdersLayout = (props) => {
    return (
        <Outlet />
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(OrdersLayout);
