import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable} from '../DataTable';
import { formatMoney, validateString, formatDateTime, formatDateTimeSecond } from "../../helper/lib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const ItemReviewTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit, actionFunction  }) => {
    const columns = [
        {
            name: "Order No",
            selector: "order_no",
            width: "10%",
            class: []
        },
        {
            name: "Date Start",
            selector: "date_start",
            width: "8%",
            class: [],
            childClass:[]
        },
        {
            name: "Date To",
            selector: "date_to",
            width: "8%",
            class: [],
            childClass:[]
        },
        {
            name: "Total Order (Rp)",
            selector: "total_order",
            width: "10%",
            class: ["text-end"],
            childClass:[]
        },
        {
            name: "KR Commission (Rp)",
            selector: "commision",
            width: "10%",
            class: ["text-end"],
            childClass:[]
        },
        {
            name: "Net Income (Rp)",
            selector: "amount",
            width: "10%",
            class: ["text-end"],
            childClass:[]
        },
        // {
        //     name: "Status",
        //     selector: "status",
        //     width: "10%",
        //     class: [],
        //     childClass:[]
        // },
        {
            name: "",
            selector: "action",
            width: "5%",
            class: [],
            childClass:[]
        },
    ]

    return (
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
                >
                     { isLoadingData ? (<tr><td style={{background:"white", height:"200px"}} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span>{validateString(item?.order_number)}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatDateTimeSecond(new Date(item?.date_start)))}</span>
                                                    </td>
                                                    <td>
                                                        <span>{validateString(formatDateTimeSecond(new Date(item?.date_to)))}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <span>{validateString(formatMoney(item?.total_order.replaceAll(',', ''), ""))}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <span>{validateString(formatMoney(item?.commission, ""))}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <span>{validateString(formatMoney(item?.disbursement_ammount.replaceAll(',', ''), ""))}</span>
                                                    </td>
                                                    {/* <td>
                                                        {status}
                                                    </td> */}
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <NavLink to={`${item?.id}`} className='btn btn-sm btn-warning' data-bs-toggle="tooltip" title='Disbursement Detail'>
                                                                <FontAwesomeIcon icon={faEye} color="white" />
                                                            </NavLink>
                                                        </div>
                                                    </td>
                                               </tr>
                                           )
                                        }) }

                </CustomDataTable>
    )
}

export default ItemReviewTable;