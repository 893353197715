import React from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getItems, getVendorCategories } from "../../helper/function";
import ItemsTable from "../../organism/Items/ItemsTable";
import $ from "jquery";
import Select from 'react-select';

const ItemsList = (props) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [type, setType] = useState("all");
    const [totalCurrent, setTotalCurrent] = useState(0);
    const [search, setSearch] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [category, setCategory] = useState(0);
    const [tsx, setTsx] = useState(Date.now());

    const statusOptions = [
        { value: "all", label: "All Status" },
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
    ];

    const getVendorCategoriesData = async () => {
        const response = await getVendorCategories();
        if(response.message === "Success" && response.meta.total > 0){
            let options = [];
            response.data.map((item, index) => {
                options.push({ value: item.id, label: item.name })
                return;
            })

            options.push({ value: 0, label: "All Category" })
            setCategoryOptions(options)
        }
    }

    const customStyles = {
        category : {
            control: (provided, state) => ({
                ...provided,
                width: 200,
            }),
        },
        status : {
            control: (provided, state) => ({
                ...provided,
                width: 150,
            }),
        }
    }


    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleChangeLimit = (limit) => {

    }

    const handleSearchData = (e) => {
        if($(e.currentTarget).prop("id") === 'button-search'){
            setSearch($(e.currentTarget).parent().siblings('input#search-field').val())
        }

        if(e?.key === 'Enter') {
            setSearch($('input#search-field').val())
        }

        if($(e.currentTarget).prop("id") === 'search-field'){
            $(e.currentTarget).on("keyup", function(){

            })
        }
    }

    const handleResetFilter = () => {
        setType("all")
        setPage(0)
        setLimit(10)
        setSearch("")
        setCategory(0)
        $(document).find("#filterType > li button[data-value='all']").trigger("click")

    }

    const handleChangeType = (type) => {
        setType(type)
    }
    const handleChangeCategory = (category) => {
        setCategory(category)
    }

    const getItemsData = async (type = "all", page = 0, limit = 10, search="",category=0) => {
        setIsLoadingData(true)
        const response = await getItems(type, page, limit, search, category);
        if(response.message === "Success" && response.meta.total > 0){
            setData(response.data)
            setTotal(response.meta.total)
            setTotalCurrent(response.data.length)
            setIsLoadingData(false)
        }
        else{
            setData([])
            setIsLoadingData(false)
        }
    }
    
    const location = useLocation()
    useEffect(() => {
        if(location.state){
            setType(location.state.status)
        }
    },[])


    useEffect(() => {
        getItemsData(type, page, limit, search, category);
    }, [page, limit, type, search, category]);

    useEffect(() => {
        getVendorCategoriesData();
    }, []);

    return (
        <div className="tab-content" id="pills-tabContent">
            {/* <!-- Promotions --> */}
            <div className="tab-pane fade show active" id="pills-promotions" role="tabpanel" aria-labelledby="pills-promotions-tab">
                <div className="w-100 d-flex justify-content-between align-items-center mb-4">
                    <div className="fs-5 fw-bold mt-2 mb-4">
                        Product List
                    </div>
                    {/* <div className="d-flex align-items-center">
                        <div className="pe-0 pe-lg-4 w-lg-100">
                            <button className="btn fw-bold w-lg-100 d-flex align-items-center py-1 px-1 px-lg-2 fz-14">
                                <div className="img-wrap">
                                    <img alt="" src={"/dist/img/custom/download.svg"} className="icon-1" />                            
                                </div>
                                <div className="ms-2 d-none d-lg-block">
                                    Download Template
                                </div>
                            </button>
                        </div>
                        <div className="ps-0 ps-lg-4 w-lg-100 border-start border-lg-0">
                            <button className="btn fw-bold w-lg-100 d-flex align-items-center py-1 px-1 px-lg-2 fz-14">
                                <div className="img-wrap">
                                    <img alt="" src={"/dist/img/custom/import.svg"} className="icon-1" />
                                </div>
                                <div className="ms-2 d-none d-lg-block">
                                    Import XLS
                                </div>
                            </button>
                        </div>
                    </div> */}
                </div>
               
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-2 mb-lg-0">
                        <div className="mb-2 mb-lg-0">
                            <div className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-lg-100" style={{ width:"235px" }}>
                            <input type="text" onKeyUp={handleSearchData} id="search-field" className="form-control border-0 fz-14 box-shadow-focus-0" placeholder="Search by name" aria-label="Username" aria-describedby="addon-wrapping" />
                            <div className="py-1 px-1">
                                <button onClick={handleSearchData} className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center" type="button" id="button-search" style={{width: "1.8rem",height: "1.7rem"}}>
                                <img alt="" src={"/dist/img/custom/search.svg"} style={{width: ".9rem"}} className="filter-white" />
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
                        <Select
                            className="basic-single w-100"
                            classNamePrefix="select"
                            name="form-category"
                            defaultValue={categoryOptions[0]}
                            value={categoryOptions.find(obj => obj.value === category)}
                            options={categoryOptions}
                            isSearchable={true}
                            onChange={(e) => handleChangeCategory(e.value)}
                            styles={customStyles.category}
                            />

                    </div>
                    <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="form-status"
                            defaultValue={statusOptions[0]}
                            value={statusOptions.find(obj => obj.value === type)}
                            options={statusOptions}
                            isSearchable={false}
                            onChange={(e) => handleChangeType(e.value)}
                            styles={customStyles.status}
                            />
                    </div>
                    <div className="ps-0 ps-lg-4 w-lg-100 border-start border-lg-0 d-flex flex-column-reverse flex-lg-row align-items-center">
                        <button onClick={handleResetFilter} className="btn btn-line fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-2 mb-lg-0">
                            Reset
                        </button>
                        <NavLink to="/items/add-new" className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0">
                            Add Product
                        </NavLink>
                    </div>
                </div>
                {(data.length > 0 || isLoadingData) && 
                (
                    <>
                <div className="w-100 mt-3">
                    <ItemsTable
                          items={data} 
                          total={total}
                          page={page}
                          limit={limit}
                          isLoadingData={isLoadingData}
                          onChangePage={handleChangePage}
                          onChangeLimit={handleChangeLimit}
                          getItemsData={getItemsData}
                          setIsLoadingData={setIsLoadingData}
                          tsx={tsx}
                          />
                </div>
                </>
                )}

                {/* <!-- Tidak Ada Isinya --> */}
                {data.length === 0 && isLoadingData === false && 
                (
                <>
                {/* <!-- Empty --> */}
                    <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                    <div className="d-grid px-3 px-sm-0">
                        <div className="d-flex align-items-center justify-content-center">
                        <div className="img-wrap img-wrap-empty">
                            <img alt="" src={"/dist/img/empty/empty-product.png"} />
                        </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-4">   
                        <div className="fs-6 fw-bold text-center">
                            No Product Found!
                        </div>                                                       
                        </div>
                    </div>
                    </div>
                    </>
                )}
            </div>
        </div> 
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(ItemsList);
