import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { getDetailDesignConcept } from "../../../helper/function"
import LoadingComponent from '../../../atoms/LoadingComponent';
import PdfThumbnail from '../../../organism/Project/PdfThumbnail'
import moment from 'moment';

const DetailProjectDesign = (props) => {
    moment.locale('en');
    const navigate = useNavigate()
    const location = useLocation()
    const { orderNo, id, projectID } = useParams();
    const [design, setDesign] = useState(null)
    const [isFinal, setIsFinal] = useState(location.state?.isFinal);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [tsx, setTsx] = useState(Date.now());

    //
    const getDetailDesign = async () => {
        // Set loading
        setIsLoadingData(true)

        const payload = {
            idProject: projectID,
            orderNo: orderNo,
            id: id
        }
        const response = await getDetailDesignConcept(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            // Set loading
            setIsLoadingData(false)

            setDesign(response.data)
        } else {
            // Set loading
            setIsLoadingData(false)
        }
    }

    useEffect(() => {
        getDetailDesign()
    }, [])

    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            <div className="mb-4">
                <a onClick={() => navigate(`/designer/project/view/${orderNo}/${projectID}`, {
                    state: {
                        tab: isFinal ? 'finalDesign' : "designPlan",
                        designType: location?.state?.designType
                    }
                })} className="fs-6 fw-bold">
                    <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" />
                    {!isFinal && "Design Concept Detail"}
                    {isFinal && "Final Design Detail"}
                </a>
            </div>
            <hr className="mt-5 mb-5" />

            {/* Information */}
            <div className="fs-5 fw-bold mt-2 mb-4">
                Information
            </div>
            {/* Project Detail Component */}
            {(design !== null && isLoadingData === false) && (
                <table>
                    <tbody>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Design Board</p></td>
                            <td>
                                {(design?.photos.length === 0 && design?.docs.length === 0) && (
                                    <p className="fz-14 fw-bold ml-2">
                                        No file upload</p>
                                )}
                                <div className="row mb-3" style={{ gap: "15px" }}>
                                    {design?.photos.map((photo, index) => {
                                        {/* Image Element */ }
                                        if (photo.full_image_url !== null && photo.full_image_url !== "") {
                                            return (
                                                <div key={index} className="col-3 rounded border p-3">
                                                    <a className="text-center image-component">
                                                        <img src={`${photo.full_image_url}?tsx=${tsx}`} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img" />
                                                    </a>

                                                    <div className="d-flex mt-4">
                                                        <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img" />
                                                        <p className="fz-14 fw-bold mb-0">file_inspiration.jpg</p>
                                                        {/* <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" /></a>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14">Download</a></li></ul> */}
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return
                                    })}

                                    {design?.docs.map((doc, index) => {
                                        {/* Image Element */ }
                                        if (doc.full_doc_url !== null && doc.full_doc_url !== "") {
                                            return (
                                                <div key={index} className="col-3 rounded border p-3">
                                                    <a className="d-flex justify-content-center text-center image-component">
                                                        <PdfThumbnail url={doc.full_doc_url} />
                                                    </a>

                                                    <div className="d-flex mt-4">
                                                        <img src={"/dist/img/custom/file-image.svg"} className="icon-1 me-2" alt="img-file-image" />
                                                        <p className="fz-14 fw-bold mb-0">file_inspiration.pdf</p>
                                                        <a className="" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ marginLeft: "auto" }}><img src={"/dist/img/custom/dots.svg"} className="icon-2" alt="img-dots" /></a>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink"><li><a className="dropdown-item fz-14" target="_blank" href={doc.full_doc_url}>Download</a></li></ul>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Status</p></td>
                            <td>
                                <div className="badge-new mb-3 badge-gray">{design?.status}</div>
                            </td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Project No</p></td>
                            <td><p className="fz-14 fw-bold">{design?.orderNo}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">{design?.service === 'Express Package' ? 'Room Type' : 'Room Name'}</p></td>
                            <td><p className="fz-14 fw-bold">{design?.service === 'Express Package' ? design?.roomTypes : design?.roomName}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Service Level</p></td>
                            <td><p className="fz-14 fw-bold">{design?.service}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Last Update</p></td>
                            <td><p className="fz-14 fw-bold">{moment(design?.updatedAt.replaceAll(' WIB', ''), 'ddd MMM DD HH:mm:ss YYYY', true).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Client</p></td>
                            <td><p className="fz-14 fw-bold">{design?.client}</p></td>
                        </tr>
                        <tr className="align-top">
                            <td width="150"><p className="fz-14 ff2">Description</p></td>
                            <td><p className="fz-14 fw-bold">{design?.description}</p></td>
                        </tr>
                        {(!(design?.feedBack) && !isFinal) && (
                            <tr className="align-top">
                                <td width="150"><p className="fz-14 ff2">Feedback</p></td>
                                <td><p className="fz-14 fw-bold">-</p></td>
                            </tr>
                        )}
                        {((design?.feedBack) && !isFinal) && (
                            <>
                                {design?.feedBack.map((feedBack, index) => {
                                    return (
                                        <tr className="align-top">
                                            <td width="150"><p className="fz-14 ff2">Feedback {index + 1}</p></td>
                                            <td><p className="fz-14 fw-bold">{feedBack}</p></td>
                                        </tr>
                                    )
                                })}
                            </>
                        )}
                    </tbody>
                </table>
            )}

            {/* Loading */}
            {(isLoadingData === true) && (<LoadingComponent />)}

            <div className="text-right">
                {(!isFinal && design?.status === 'Create Concept') && (
                    <>
                        {(design?.service === 'Custom Project' && (design?.onSubmit !== true || (design?.onSubmit === true && design?.feedBack?.length > 0))) && (
                            <NavLink to={`/designer/project/add-design/${orderNo}/${projectID}/${id}`} className="btn btn-fill fw-bold" state={{ designType: location?.state?.designType }}>
                                Edit
                            </NavLink>
                        )}


                        {(design?.service === 'Express Package' && (design?.onSubmit !== true || (design?.onSubmit === true && design?.feedBack?.length > 0))) && (
                            <NavLink to={`/designer/project/add-design/${orderNo}/${projectID}/${id}`} className="btn btn-fill fw-bold" state={{ designType: location?.state?.designType }}>
                                Edit
                            </NavLink>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(DetailProjectDesign);