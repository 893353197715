import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  addVariant,
  detailProduct,
  detailVariant,
  updateVariant,
} from '../../../helper/function';
import {
  getBase64FromUrlV3,
  formatMoney,
  formatTime,
} from '../../../helper/lib';
import { IsAlert } from '../../../atoms/IsAlert';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import { toast } from 'react-toastify';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const AddVariant = (props) => {
  const { productID, variantID } = useParams();
  const location = useLocation();
  const data = location.state?.data;
  const payloadProduct = location.state?.payloadProduct;
  const isEdit = variantID !== undefined;

  const [previewImage, setPreviewImage] = useState();
  const [samplePreview, setSamplePreview] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [id, setId] = useState();
  const [sku, setSku] = useState('');
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [finishing, setFinishing] = useState('');
  const [other, setOther] = useState('');
  const [minStock, setMinStock] = useState('');
  const [stock, setStock] = useState('');

  const [dimension1, setDimension1] = useState();
  const [dimension2, setDimension2] = useState();
  const [dimension3, setDimension3] = useState();
  const [packageDimension1, setPackageDimension1] = useState();
  const [packageDimension2, setPackageDimension2] = useState();
  const [packageDimension3, setPackageDimension3] = useState();
  const [weight, setWeight] = useState();
  const [productWeight, setProductWeight] = useState();
  const [diameter, setDiameter] = useState('');

  const [price, setPrice] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [discount, setDiscount] = useState();
  const [discountDateFrom, setDiscountDateFrom] = useState(null);
  const [discountDateTo, setDiscountDateTo] = useState(null);
  const [discountTimeFrom, setDiscountTimeFrom] = useState(null);
  const [discountTimeTo, setDiscountTimeTo] = useState(null);
  const [tsx, setTsx] = useState(Date.now());

  const handleUpload = async (event) => {
    // Validate size & type file
    let availableExtension = ['jpeg', 'jpg', 'png'];
    let extension = event.target.files[0].name.split('.').pop().toLowerCase();
    let size =
      Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100;

    // Validate Size
    if (size > 5) {
      toast.warning('Max file size 5MB.');
      return;
    }

    // Validate Extension
    if (availableExtension.includes(extension) === false) {
      toast.warning(
        'File extension not allowed, allowed extension: .jpeg, .jpg, & .png'
      );
      return;
    }

    const previewImage = URL.createObjectURL(event.target.files[0]);
    setPreviewImage(previewImage);
    const base64 = await convertBase64(event.target.files[0]);
    samplePreview.push({
      name: event?.target?.files[0]?.name,
      size: Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100,
      preview: previewImage,
      file: base64,
    });
    setPreviewImage(samplePreview);
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  const getDetailProduct = async () => {
    const response = await detailProduct(productID);
    if (response.message === 'Success' && response.meta.total > 0) {
      setSku(response?.data?.sku);

      setDimension1(response?.data?.product_details[0]?.dimension_x);
      setDimension2(response?.data?.product_details[0]?.dimension_y);
      setDimension3(response?.data?.product_details[0]?.dimension_z);
      setPackageDimension1(
        response?.data?.product_details[0]?.package_dimension_x
      );
      setPackageDimension2(
        response?.data?.product_details[0]?.package_dimension_y
      );
      setPackageDimension3(
        response?.data?.product_details[0]?.package_dimension_z
      );
      setWeight(response?.data?.product_details[0]?.weight);
      setProductWeight(response?.data?.product_details[0]?.product_weight);
      setDiameter(response?.data?.product_details[0]?.diameter);
      setPrice(formatMoney(response?.data?.price, ''));
      setSelectedDiscountType(
        response?.data?.discount_type === 0 ? 1 : response?.data?.discount_type
      );
      setDiscount(
        response?.data?.discount_type === 0 ||
          response?.data?.discount_type === 1
          ? formatMoney(response?.data?.discount, '')
          : response?.data?.discount
      );
      setDiscountDateFrom(
        response?.data?.discount_active_from
          ? moment(response?.data?.discount_active_from).toDate()
          : null
      );
      setDiscountDateTo(
        response?.data?.discount_active_to
          ? moment(response?.data?.discount_active_to).toDate()
          : null
      );
      if (response?.data?.discount_active_from) {
        setDiscountTimeFrom(
          formatTime(new Date(response?.data?.discount_active_from))
        );
      }
      if (response?.data?.discount_active_to) {
        setDiscountTimeTo(
          formatTime(new Date(response?.data?.discount_active_to))
        );
      }
    }
  };

  const getDetailVariant = async () => {
    const response = await detailVariant(variantID);
    if (response.message === 'Success' && response.meta.total > 0) {
      setSku(response?.data?.sku);

      setColor(response?.data?.colors);
      setSize(response?.data?.sizes);
      setMaterial(response?.data?.material);
      setFinishing(response?.data?.finishing);
      setOther(response?.data?.other);
      setMinStock(response?.data?.minimum_stock);
      setStock(response?.data?.total_stock);
      setDimension1(response?.data?.dimension1);
      setDimension2(response?.data?.dimension2);
      setDimension3(response?.data?.dimension3);
      setPackageDimension1(response?.data?.package_dimension1);
      setPackageDimension2(response?.data?.package_dimension2);
      setPackageDimension3(response?.data?.package_dimension3);
      setWeight(response?.data?.weight);
      setProductWeight(response?.data?.product_weight);
      setDiameter(response?.data?.diameter);
      setPrice(formatMoney(response?.data?.sell_price, ''));
      setSelectedDiscountType(
        response?.data?.discount_type === 0 ? 1 : response?.data?.discount_type
      );
      setDiscount(response?.data?.discount);
      setDiscountDateFrom(
        response?.data?.discount_active_from
          ? moment(response?.data?.discount_active_from).toDate()
          : null
      );
      setDiscountDateTo(
        response?.data?.discount_active_to
          ? moment(response?.data?.discount_active_to).toDate()
          : null
      );
      if (response?.data?.discount_active_from) {
        setDiscountTimeFrom(
          formatTime(new Date(response?.data?.discount_active_from))
        );
      }
      if (response?.data?.discount_active_to) {
        setDiscountTimeTo(
          formatTime(new Date(response?.data?.discount_active_to))
        );
      }

      // Image
      const previewImages = [];
      if (response?.data?.product_images) {
        for (let i = 0; i < response?.data?.product_images.length; i++) {
          const el = response?.data?.product_images[i];
          if (el.full_image_url !== '') {
            let base64File = await getBase64FromUrlV3(el.full_image_url);
            if (base64File !== '' && base64File !== undefined) {
              previewImages.push({
                name: 'response-photo.jpg',
                size: '-',
                preview: el.full_image_url + '?tsx=' + tsx,
                file: base64File,
                extension: 'png',
              });
            }
          }
        }
      }
      await setSamplePreview(previewImages);
    }
  };

  const handleChangeDiscontValue = (e) => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
      if (
        parseInt(String(e.target.value).replaceAll('.', '')) >
        parseInt(String(price).replaceAll('.', ''))
      ) {
        setDiscount(0);
      } else {
        setDiscount(e.target.value);
      }
    } else {
      if (isNaN(e.target.value)) {
        setDiscount(0);
      } else if (e.target.value > 100) {
        setDiscount(100);
      } else {
        setDiscount(e.target.value);
      }
    }
  };

  const handleKeyupDiscountValue = (e) => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
      setDiscount(formatMoney(e.target.value.replaceAll('.', ''), ''));
    } else {
      setDiscount(e.target.value < 0 ? 0 : e.target.value);
    }
  };

  const handleChangeDiscountType = (e) => {
    setSelectedDiscountType(e.target.value);

    if (e.target.value === 1 || e.target.value === '1') {
      setDiscount(formatMoney(String(discount).replaceAll('.', ''), ''));
    } else {
      setDiscount(String(discount).replaceAll('.', ''));
    }
  };

  useEffect(() => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
    } else {
      if (discount > 100) {
        setDiscount(100);
      }
    }
  }, [selectedDiscountType]);

  useEffect(() => {
    if (data) {
      setSku(data.sku);
      setId(parseInt(data.id));
    } else {
      if (isEdit === false) {
        getDetailProduct();
      } else {
        getDetailVariant();
      }
    }

    if (payloadProduct && isEdit === false) {
      setDimension1(payloadProduct?.dimension1);
      setDimension2(payloadProduct?.dimension2);
      setDimension3(payloadProduct?.dimension3);
      setPackageDimension1(payloadProduct?.package_dimension1);
      setPackageDimension2(payloadProduct?.package_dimension2);
      setPackageDimension3(payloadProduct?.package_dimension3);
      setWeight(payloadProduct?.weight);
      setProductWeight(payloadProduct?.product_weight);
      setDiameter(payloadProduct?.diameter);
      setPrice(formatMoney(payloadProduct?.price, ''));
      setSelectedDiscountType(payloadProduct?.discount_type);
      setDiscount(payloadProduct?.discount ?? 0);
      setDiscountDateFrom(
        payloadProduct?.from_date
          ? new Date(
              payloadProduct?.from_date.replaceAll(
                'T00:00',
                'T' + payloadProduct?.from_time
              )
            )
          : null
      );
      setDiscountDateTo(
        payloadProduct?.to_date
          ? new Date(
              payloadProduct?.to_date.replaceAll(
                'T00:00',
                'T' + payloadProduct?.to_time
              )
            )
          : null
      );
      setDiscountTimeFrom(payloadProduct?.from_time);
      setDiscountTimeTo(payloadProduct?.to_time);
    }
  }, []);

  const submitForm = async (event) => {
    props.setLoading(true);
    try {
      event.preventDefault();

      let imagesPayload = [];
      samplePreview.forEach((el) => {
        photos.push(el?.file);
        imagesPayload.push(el?.file);
      });

      // If not edit
      if (!isEdit) {
        const payload = {
          product_id: parseInt(productID),
          sku: String(sku),
          color: String(color),
          size: String(size),
          material: String(material),
          finishing: String(finishing),
          other: String(other),
          min_stock: parseInt(minStock),
          stock: parseInt(stock),
          weight: parseFloat(String(weight).replaceAll(',', '.')),
          product_weight: parseFloat(
            String(productWeight).replaceAll(',', '.')
          ),
          diameter:
            diameter === '' ? '0' : String(diameter).replaceAll(',', '.'),
          dimension1: parseFloat(String(dimension1).replaceAll(',', '.')),
          dimension2: parseFloat(String(dimension2).replaceAll(',', '.')),
          dimension3: parseFloat(String(dimension3).replaceAll(',', '.')),
          package_dimension1: parseFloat(
            String(packageDimension1).replaceAll(',', '.')
          ),
          package_dimension2: parseFloat(
            String(packageDimension2).replaceAll(',', '.')
          ),
          package_dimension3: parseFloat(
            String(packageDimension3).replaceAll(',', '.')
          ),
          price: parseInt(String(price).replaceAll('.', '')),
          discount: discount
            ? parseInt(String(discount).replaceAll('.', ''))
            : 0,
          discount_type: parseInt(selectedDiscountType),
          from_date: discountDateFrom
            ? moment(discountDateFrom).format()
            : null,
          to_date: discountDateTo ? moment(discountDateTo).format() : null,
          from_time: discountTimeFrom,
          to_time: discountTimeTo,
          images: photos,
        };
        const response = await addVariant(payload);

        if (response?.message === 'Success create new record') {
          props.setLoading(false);
          navigate(`/items/add-new/${productID}?list-varian-only=1`, {
            state: { isShowVariant: true },
          });
        } else {
          props.setLoading(false);
          IsAlert.fire({
            title: 'Failed',
            text: response?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        return;
      }

      // If edit
      if (isEdit) {
        const payload = {
          id: parseInt(variantID),
          product_id: parseInt(productID),
          sku: String(sku),
          color: String(color),
          size: String(size),
          material: String(material),
          finishing: String(finishing),
          other: String(other),
          min_stock: parseInt(minStock),
          stock: parseInt(stock),
          weight: parseFloat(String(weight).replaceAll(',', '.')),
          product_weight: parseFloat(
            String(productWeight).replaceAll(',', '.')
          ),
          diameter:
            diameter === '' ? '0' : String(diameter).replaceAll(',', '.'),
          dimension1: parseFloat(String(dimension1).replaceAll(',', '.')),
          dimension2: parseFloat(String(dimension2).replaceAll(',', '.')),
          dimension3: parseFloat(String(dimension3).replaceAll(',', '.')),
          package_dimension1: parseFloat(
            String(packageDimension1).replaceAll(',', '.')
          ),
          package_dimension2: parseFloat(
            String(packageDimension2).replaceAll(',', '.')
          ),
          package_dimension3: parseFloat(
            String(packageDimension3).replaceAll(',', '.')
          ),
          price: parseInt(String(price).replaceAll('.', '')),
          discount: discount
            ? parseInt(String(discount).replaceAll('.', ''))
            : 0,
          discount_type: parseInt(selectedDiscountType),
          from_date: discountDateFrom
            ? moment(discountDateFrom).format()
            : null,
          to_date: discountDateTo ? moment(discountDateTo).format() : null,
          from_time: discountTimeFrom,
          to_time: discountTimeTo,
          images: imagesPayload,
        };
        const response = await updateVariant(payload);

        if (response?.message === 'Updated') {
          props.setLoading(false);
          navigate(`/items/add-new/${productID}?list-varian-only=1`, {
            state: { isShowVariant: true },
          });
        } else {
          props.setLoading(false);
          IsAlert.fire({
            title: 'Failed',
            text: response?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        return;
      }
    } catch (error) {
      props.setLoading(false);
      console.error(error);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi many-form">
      <div className="mb-4">
        <a onClick={() => navigate(-1)} className="fs-6 fw-bold">
          <img
            src={'/dist/img/custom/caret-left-light.svg'}
            className="icon-4 me-2"
            alt="img-caret-left-light"
          />
          {isEdit && 'Edit Variant'}
          {!isEdit && 'Add Variant'}
        </a>
      </div>

      <div className="fs-5 fw-bold mt-2 mb-4 px-2">Photos</div>
      <div className="w-100 px-2">
        <label htmlFor="file-upload" className="upload-file-custom">
          <div>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <div className="img-wrap">
                {/* <img src="/dist/img/custom/image.svg" className="w-4rem" alt="img-upload" /> */}
                <img
                  src="/dist/img/custom/image.svg"
                  className="w-4rem"
                  alt="img-upload"
                />
              </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-center mt-4">
              <p className="mb-0 text-center">
                Drop your photos here, or <strong>browse</strong>
              </p>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-center mt-1">
              <p className="mb-0 text-center text-secondary fz-14">
                Max. 5MB per file with format .jpeg, .jpg, .png (Max. 1 files)
              </p>
            </div>
          </div>
        </label>
        <input
          className="visually-hidden"
          id="file-upload"
          type="file"
          accept=".jpeg,.jpg,.png"
          onChange={handleUpload}
        />
      </div>
      <div className="ms-2 d-flex justify-content-center">
        <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0 mb-4">
          {samplePreview.map((image, index) => {
            if (image) {
              return (
                <div key={index}>
                  <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                        <div className="w-100 h-100">
                          <img
                            id="imageId"
                            src={image?.preview}
                            className="object-fit-cover w-5rem ar-square"
                            alt="Preview"
                          />
                        </div>
                      </div>
                      <div className="col ms-3">
                        <p className="mb-0 text-wrap-1">{image?.name}</p>
                        <div className="fz-14 text-secondary mt-3">
                          {image?.size} Mb
                        </div>
                      </div>
                    </div>
                    <button className="btn p-0">
                      <img
                        src="/dist/img/custom/x.svg"
                        className="icon-3"
                        alt="img-delete"
                        onClick={clearImageSelected.bind(this, index)}
                      />
                    </button>
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>

      <div className="fs-5 fw-bold mb-4">Variant Detail</div>
      <form onSubmit={submitForm}>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">
              Product SKU<div className="text-danger ps-1">*</div>
            </label>
            {data ? (
              <input
                type="text"
                className="form-control"
                value={sku}
                disabled
                required
              />
            ) : (
              <input
                type="text"
                className="form-control"
                value={sku}
                onChange={(event) => {
                  setSku(event.target.value);
                }}
                required
              />
            )}
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label d-flex fw-semibold">
              Color<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={color}
              onChange={(event) => {
                setColor(event.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">Size</label>
            <input
              type="text"
              className="form-control"
              value={size}
              onChange={(event) => {
                setSize(event.target.value);
              }}
            />
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label d-flex fw-semibold">Material</label>
            <input
              type="text"
              className="form-control"
              value={material}
              onChange={(event) => {
                setMaterial(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">Finishing</label>
            <input
              type="text"
              className="form-control"
              value={finishing}
              onChange={(event) => {
                setFinishing(event.target.value);
              }}
            />
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label fw-semibold">Others</label>
            <input
              type="text"
              className="form-control"
              value={other}
              onChange={(event) => {
                setOther(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">
              Minimum Stock<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={minStock}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setMinStock(0);
                } else if (event.target.value < 0) {
                  setMinStock(0);
                } else {
                  setMinStock(event.target.value);
                }
              }}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
              }
              required
            />
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label d-flex fw-semibold">
              Stock<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={stock}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setStock(0);
                } else if (event.target.value < 0) {
                  setStock(0);
                } else {
                  setStock(event.target.value);
                }
              }}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
              }
              required
            />
          </div>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-3 px-1">
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Product Length (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={dimension1}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setDimension1(0);
                } else if (event.target.value < 0) {
                  setDimension1(0);
                } else {
                  setDimension1(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Product Width (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={dimension2}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setDimension2(0);
                } else if (event.target.value < 0) {
                  setDimension2(0);
                } else {
                  setDimension2(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Product Height (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={dimension3}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setDimension3(0);
                } else if (event.target.value < 0) {
                  setDimension3(0);
                } else {
                  setDimension3(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Packaging Length (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={packageDimension1}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setPackageDimension1(0);
                } else if (event.target.value < 0) {
                  setPackageDimension1(0);
                } else {
                  setPackageDimension1(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Packaging Width (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={packageDimension2}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setPackageDimension2(0);
                } else if (event.target.value < 0) {
                  setPackageDimension2(0);
                } else {
                  setPackageDimension2(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Packaging Height (CM)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={packageDimension3}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setPackageDimension3(0);
                } else if (event.target.value < 0) {
                  setPackageDimension3(0);
                } else {
                  setPackageDimension3(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Total Weight (KG)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={weight}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setWeight(0);
                } else if (event.target.value < 0) {
                  setWeight(0);
                } else {
                  setWeight(event.target.value);
                }
              }}
              placeholder="Packaging & Product"
              required
            />

            <small>
              <i className="">
                *) The values of Total Weight cannot be less than the values of
                Product Weight
              </i>
            </small>
          </div>
          <div className="p-2">
            <label className="form-label d-flex fw-semibold">
              Product Weight (KG)<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={productWeight}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setProductWeight(0);
                } else if (event.target.value < 0) {
                  setProductWeight(0);
                } else {
                  setProductWeight(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className="p-2">
            <label className="form-label fw-semibold">Diameter (CM)</label>
            <input
              type="text"
              className="form-control"
              value={diameter}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  setDiameter(0);
                } else if (event.target.value < 0) {
                  setDiameter(0);
                } else {
                  setDiameter(event.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="fs-5 fw-bold mb-4 mt-4">Price</div>
        <div className="d-sm-flex">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">
              Price<div className="text-danger ps-1">*</div>
            </label>
            <input
              type="text"
              className="form-control"
              value={price}
              onChange={(event) => {
                setPrice(event.target.value);
              }}
              onKeyUp={(event) =>
                setPrice(
                  formatMoney(event.target.value.replaceAll('.', ''), '')
                )
              }
              required
            />
          </div>
        </div>
        <small>
          <i className="">*) Price is required and should be greater than 0</i>
        </small>

        <div className="d-sm-flex mb-3 mt-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label fw-semibold">Discount Value</label>
            <input
              type="text"
              className="form-control"
              value={discount}
              onChange={handleChangeDiscontValue}
              onKeyUp={handleKeyupDiscountValue}
            />
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label fw-semibold">Discount Type</label>
            <select
              value={selectedDiscountType}
              onChange={handleChangeDiscountType}
              className="form-select"
            >
              <option value="1">Value</option>
              <option value="2">Percent</option>
            </select>
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label fw-semibold">Discount Date From</label>
            <div
              className="input-group bg-white border rounded password date w-100"
              id="datepicker"
            >
              <DatePicker
                className="form-control border-0 box-shadow-focus-0"
                selected={discountDateFrom}
                onChange={(date) => setDiscountDateFrom(date)}
                maxDate={discountDateTo}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label fw-semibold">Discount Date To</label>
            <div
              className="input-group bg-white border rounded password date w-100"
              id="datepicker2"
            >
              <DatePicker
                className="form-control border-0 box-shadow-focus-0"
                selected={discountDateTo}
                onChange={(date) => setDiscountDateTo(date)}
                minDate={discountDateFrom}
              />
            </div>
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label fw-semibold">Discount Time From</label>
            <Datetime
              value={discountTimeFrom}
              className="form-control border-0 box-shadow-focus-0 p-0"
              dateFormat={false}
              timeFormat="HH:mm"
              inputProps={{ readOnly: true }}
              onChange={(time) => setDiscountTimeFrom(time.format('HH:mm'))}
            />
          </div>
          <div className="col-12 col-sm-6 ps-sm-2">
            <label className="form-label fw-semibold">Discount Time To</label>
            <Datetime
              value={discountTimeTo}
              className="form-control border-0 box-shadow-focus-0 p-0"
              dateFormat={false}
              timeFormat="HH:mm"
              inputProps={{ readOnly: true }}
              onChange={(time) => setDiscountTimeTo(time.format('HH:mm'))}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
            <button
              type="submit"
              className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(AddVariant);
