import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DashboardWdLoading = () => {

    return (
        <>
            <div className="container-fluid mb-4 px-0">
                <div className="d-lg-flex">
                    <div className="col-12 col-lg-4 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex p-3 h-100 flex-column h-100">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Skeleton width={80} />
                                </div>
                                <div>
                                    <Skeleton width={20} />
                                </div>
                            </div>

                            <div className="d-flex flex-column mt-3">
                                <div className="col-12 col-md-12 d-flex align-items-center justify-content-center mb-4">
                                    <div className="w-100 d-flex justify-content-center">
                                        <div>
                                            <Skeleton width={300} height={300} circle={true} />
                                        </div>
                                    </div>
                                </div>

                                <h6 className="border-bottom mb-4 pb-1 w-100 text-center">
                                    <Skeleton width={100} />
                                </h6>
                                <div className="col-12 col-md-5 pe-2 d-flex align-items-center w-100 justify-content-center">
                                    <div className="border-right pe-2 text-center">
                                        <p className="fw-bold mb-1">
                                            <Skeleton width={20} />
                                        </p>
                                        <p className="">
                                            <Skeleton width={80} />
                                        </p>
                                    </div>
                                    <div className="ps-2 text-center">
                                        <p className="fw-bold mb-1">
                                            <Skeleton width={20} />
                                        </p>
                                        <p className="">
                                            <Skeleton width={80} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex flex-column p-3 h-100">
                            <div className="d-flex justify-content-between">
                                <Skeleton width={80} />
                                <Skeleton width={20} />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="col-7 col-md-5 d-flex justify-content-center w-100 mb-3">
                                    <div className="img-wrap d-flex-justify-content-center">
                                        <Skeleton width={160} height={160} />
                                    </div>
                                </div>

                                <div className='mt-3 text-center'>
                                    <Skeleton width={250} count={2} />
                                </div>

                                <div className='mt-3 text-center'>
                                    <Skeleton width={250} height={35} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 pe-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 d-flex flex-column p-3 h-100">
                            <div className="d-flex justify-content-between mb-3">
                                <Skeleton width={80} />
                                <Skeleton width={20} />
                            </div>
                            <div className="img-wrap d-flex justify-content-center mb-3">
                                <Skeleton width={160} height={160} />
                            </div>

                            <div className='mt-3 text-center'>
                                <Skeleton width={250} count={2} />
                            </div>

                            <div className='mt-3'>
                                <Skeleton width={130} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Latest Project */}
                <div className="col-12 mt-3 mt-lg-4">
                    <Skeleton width={80} />

                    <div className="w-100 d-flex flex-column mt-3">
                        <div className="card card-project">
                            <div className="card-body p-5">
                                <div className="row item">
                                    <div className="col-12 col-md-6">
                                        <div className="d-flex justify-content-around align-items-start">
                                            <Skeleton width={256} height={180} />
                                            <div className="d-flex flex-column ms-4">
                                                <dl>
                                                    <dt><Skeleton width={80} /></dt>
                                                    <dd><Skeleton width={120} /></dd>
                                                </dl>
                                                {/* <a href="#">View</a> */}
                                                <Skeleton width={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="row row-cols-2 px-5">
                                            <dl className="col mb-0">
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                            </dl>
                                            <dl className="col mb-0">
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Latest Design Concepts */}
                <div className="col-12 mt-3 mt-lg-4">
                    <Skeleton width={80} />

                    <div className="w-100 d-flex flex-column mt-3">
                        <div className="card card-project">
                            <div className="card-body p-5">
                                <div className="row item">
                                    <div className="col-12 col-md-6">
                                        <div className="d-flex justify-content-around align-items-start">
                                            <Skeleton width={256} height={180} />
                                            <div className="d-flex flex-column ms-4">
                                                <dl>
                                                    <dt><Skeleton width={80} /></dt>
                                                    <dd><Skeleton width={120} /></dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="row row-cols-2 px-5">
                                            <dl className="col mb-0">
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                            </dl>
                                            <dl className="col mb-0">
                                                <dt><Skeleton width={50} /></dt>
                                                <dd><Skeleton width={80} /></dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-3 mt-lg-4">
                    <div className="rounded-lg border w-100 p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-semibold"><Skeleton width={100} /></div>
                            <div className="pagination">

                            </div>
                        </div>
                        <div className="w-100 mt-3 overflow-hidden">
                            <div className="swiper ">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                            <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                            <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>
                                    <div className="swiper-slide rounded-lg border p-3">
                                        <div className="fw-bold text-wrap-2">
                                            <Skeleton width={300} />
                                        </div>
                                        <div className="fw-normal mt-2 text-wrap-3">
                                            <Skeleton width={800} count={3} />
                                        </div>
                                        <div className="fw-medium-semibold mt-2 text-wrap-1">
                                            <Skeleton width={800} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DashboardWdLoading;




