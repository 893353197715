import React from "react";
import { connect } from "react-redux";
import Select from 'react-select';

const DiscountType = (props) => {
    const categoryOptions = [
        { value: "all", label: "All Category" },
        { value: "1", label: "Category 1" },
        { value: "2", label: "Category 2" },
    ];

    const discountType = [
        { value: "all", label: "All Discount Type" },
        { value: "1", label: "Discount Type 1" },
        { value: "2", label: "Discount Type 2" },
    ];

    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-discount-type" role="tabpanel" aria-labelledby="pills-discount-type-tab">
                <div className="w-100 mb-4 alert alert-basic" role="alert">
                    <p className="mb-2 fz-14">To set discount, first choose discount type, then fill in discount value and select category of the items.</p>
                    <p className="mb-2 fz-14">Choose percentage, if you want to update by percentage.</p>
                    <p className="mb-2 fz-14">Choose price, if you want to update by specific amount.</p>
                    <p className="mb-0 fz-14">Choose - in category, if you want to update all items.</p>
                </div>
                <div className="fs-5 fw-bold mb-4">
                    Set Discount for All Item
                </div>
                <form>
                    <div className="d-sm-flex mb-3">
                        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                            <label className="form-label fw-semibold">Category</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="form-category"
                                options={categoryOptions}
                                placeholder="Category"
                                isClearable={true}
                                onChange={() => {}}
                                />                                                            
                        </div>
                        <div className="col-12 col-sm-6 ps-sm-2">
                            <label className="form-label fw-semibold">Discount Type</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="form-discount-type"
                                options={discountType}
                                placeholder="Discount Type"
                                isClearable={true}
                                onChange={() => {}}
                                />                                                                      
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Discount Value</label>
                        <input type="text" className="form-control" />                            
                    </div>                        
                    <div className="d-flex justify-content-end">
                        <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                            <button type="button" className="btn w-100 fw-semibold btn-light-gray px-5 mt-3 ask"> 
                                Save
                            </button>  
                            <button type="button" className="d-none btn w-100 fw-semibold btn-fill px-5 mt-3 ask">
                                Save
                            </button>                                                                                                                                              
                        </div>
                    </div>
                </form>
            </div>
        </div>
        )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
  };
  
  const actionCreators = (dispatch) => {
    return {
      setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
  };

export default connect(mapState, actionCreators)(DiscountType);