import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { detailProduct, variantList } from "../../helper/function";
import { formatMoney, validateString, formatTime } from "../../helper/lib";
import LoadingComponent from '../../atoms/LoadingComponent';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const DetailItem = (props) => {
    const navigate = useNavigate();
    const [detailItem, setDetailItem] = useState([])
    const [variants, setVariants] = useState([]);
    const [searchVariant, setSearchVariant] = useState("");
    const [mainImage, setMainImage] = useState('')
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [tsx, setTsx] = useState(Date.now());
    const { id } = useParams();
    
    const selectedImage = (image) => {
        setMainImage(image)
    }

    const getVariants = async () => {
        const response = await variantList(id, searchVariant);
        if (response?.message === "Success") {
            setVariants(response?.data)
        }
    }

    const getDetailItemData = async () => {
        const response = await detailProduct(id)
        if (response.message === "Success") {
            setDetailItem(response.data)
            const image = response?.data?.product_details[0]?.product_images[0]?.full_image_url
            setMainImage(image)
        } else {
            setDetailItem([])
        }

        setIsLoadingData(false)
    }

    useEffect(() => {
        getDetailItemData()
        getVariants()
    }, [])

    return (
        <div>
            {isLoadingData === false && (
                <div className="container-fluid mb-4 px-0 tab-navigasi">
                    <div className="mb-4 px-4 px-md-0">
                        <a onClick={() => navigate(-1)} className="fs-6 fw-bold">
                            <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" />
                            Product Detail
                        </a>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="d-flex mt-2 mb-4" style={{ height: "100%" }}>
                            <div className="col-12 col-md-6 pe-md-4">
                                <div className="d-flex h-100">
                                    <div className="col-4 col-lg-2 h-100 pe-2">
                                        <div className="carusel-nav">
                                            {detailItem?.product_details && (
                                                <>
                                                    {detailItem?.product_details[0]?.product_images?.map(function (image, index) {
                                                        return (image?.full_image_url !== "" ?
                                                            <div className="my-2 cursor-pointer" key={index} onClick={selectedImage.bind(this, image?.full_image_url)} style={{ border: '1px solid #000000' }}>
                                                                <img src={`${image?.full_image_url}?tsx=${tsx}` || "https://placehold.co/600x400"} alt="Imaj" className="carusel-list-img" />
                                                            </div>
                                                            :
                                                            <div key={index}></div>)
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-10 h-100 ps-2">
                                        <div className="carusel" style={{ border: '1px solid #000000' }}>
                                            <div><img src={`${mainImage}?tsx=${tsx}`} alt="Imaj" className="carusel-thumbnail" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 ps-md-4">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Product Name</h6>
                                        <p>{detailItem?.name}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Room Type</h6>
                                        <p>{detailItem?.room_type_list?.join(', ')}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Product Style Tagging</h6>
                                        <p>{detailItem?.style_tags?.replaceAll(',', ', ')}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Category</h6>
                                        <p>{detailItem?.categories1[0]?.name}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Sub Category</h6>
                                        <p>{detailItem?.categories2[0]?.name}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Sub Category</h6>
                                        <p>{validateString(detailItem?.categories[0]?.name)}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Product SKU</h6>
                                        <p>{detailItem?.sku}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Warranty</h6>
                                        <p>{detailItem?.product_details[0]?.warranty_type === 1 ? 'Seller Warranty' : ''}{detailItem?.product_details[0]?.warranty_type === 2 ? 'Distributor Warranty' : ''}{detailItem?.product_details[0]?.warranty_type !== 1 && detailItem?.product_details[0]?.warranty_type !== 2 ? 'No Warranty' : ''}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Warranty Period (Days)</h6>
                                        <p>{detailItem?.product_details[0]?.warranty_period}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Warranty Description</h6>
                                        <p>{validateString(detailItem?.product_details[0]?.warranty_description)}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Product Dimension</h6>
                                        <p>{detailItem?.product_details[0]?.dimension_x + ' x ' + detailItem?.product_details[0]?.dimension_y + ' x ' + detailItem?.product_details[0]?.dimension_z} cm<sup>3</sup></p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Packaging Dimension</h6>
                                        <p>{detailItem?.product_details[0]?.package_dimension_x + ' x ' + detailItem?.product_details[0]?.package_dimension_y + ' x ' + detailItem?.product_details[0]?.package_dimension_z} cm<sup>3</sup></p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Brand</h6>
                                        <p>{detailItem?.brand?.name}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Sell Price</h6>
                                        <p>{formatMoney(detailItem?.price)}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Discount</h6>
                                        <p>{detailItem?.discount_type === 2 ? detailItem?.discount + '%' : formatMoney(detailItem?.discount)}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Discount Date</h6>
                                        <p>{detailItem?.discount > 0 && detailItem?.discount_active_from && detailItem?.discount_active_to ? moment(detailItem?.discount_active_from).format('YYYY-MM-DD') + ' ' + formatTime(new Date(detailItem?.discount_active_from)) + ' - ' + moment(detailItem?.discount_active_to).format('YYYY-MM-DD') + ' ' + formatTime(new Date(detailItem?.discount_active_to)) : '-'}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Made To Order</h6>
                                        <p>{detailItem?.is_preorder === true ? 'Yes' : 'No'}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">PO Durations (Days)</h6>
                                        <p>{detailItem?.po_duration}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Item Is Fragile</h6>
                                        <p>{detailItem?.is_fragile === true ? 'Yes' : 'No'}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Item Is Using Volumetric Weight</h6>
                                        <p>{detailItem?.is_volumetric === true ? 'Yes' : 'No'}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Item Need To Use Additional Wood Packaging</h6>
                                        <p>{detailItem?.wood_package === true ? 'Yes' : 'No'}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Short Description</h6>
                                        <p>{detailItem?.product_details[0]?.short_description[0]}</p>
                                    </div>

                                    <div className="col-sm-6">
                                        <h6 className="fw-bold">Detail Description</h6>
                                        <p dangerouslySetInnerHTML={{ __html: detailItem?.product_details[0]?.description }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fs-5 fw-bold mt-2 mb-4">
                        Variants List
                    </div>
                    <div className="w-100 mt-3 overflow-auto flipped">
                        <table className="table table-striped table-ordinary content-isi" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Size</th>
                                    <th>Color</th>
                                    <th>Material</th>
                                    <th>Finishing</th>
                                    <th>Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(variants && variants.length > 0) && (
                                    <>
                                        {variants.map((variant, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{variant?.sku}</td>
                                                    <td>{variant?.sizes}</td>
                                                    <td>{variant?.colors}</td>
                                                    <td>{variant?.material}</td>
                                                    <td>{variant?.finishing}</td>
                                                    <td>{variant?.total_stock}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* Loading */}
            {(isLoadingData === true) && (<LoadingComponent />)}
        </div>
    );
};

export default DetailItem;