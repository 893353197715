
// create custom component for pagination wtih bootstrap


import React from 'react';

const PaginationComponent = ({ total, totalCurrent, page, limit, isLoadingData, setPage }) => {
    const totalPage = Math.ceil(total / limit);
    const startItem = (page * limit) + 1;
    const endItem = (page * limit) + totalCurrent;
    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="row">
            {(total > 0) && (
                <>
                    <div className="col-sm-12 col-md-5">
                        {isLoadingData ? '' : <div className="dataTables_info" id="" role="status" aria-live="polite">Showing {startItem} - {endItem} of {total} items.</div>}
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div className="dataTables_paginate paging_simple_numbers" id="">
                            <ul className="pagination" style={{ justifyContent: 'end' }}>
                                <li className={`paginate_button page-item previous ${page === 0 ? 'disabled' : ''}`} id="">
                                    <a href="#" onClick={() => setPage(page - 1)} className="page-link"><i className="fas fa-chevron-left"></i></a>
                                </li>
                                <li className={`paginate_button page-item`}>
                                    <a href="#" onClick={() => setPage(0)} className="page-link">First</a>
                                </li>
                                {pageNumbers.map((number, index) => {
                                    if (number === page + 1 || ((page + 1) - number < 10 && number - (page + 1) < 10)) {
                                        return (
                                            <li key={number} className={`paginate_button page-item ${number === page + 1 ? 'active' : ''}`}>
                                                <a href="#" onClick={() => setPage(number - 1)} className="page-link">{number}</a>
                                            </li>
                                        )
                                    }

                                    return ""
                                })}
                                <li className={`paginate_button page-item`}>
                                    <a href="#" onClick={() => setPage(pageNumbers?.length - 1)} className="page-link">Last</a>
                                </li>
                                <li className={`paginate_button page-item next ${page === totalPage - 1 ? 'disabled' : ''}`} id="">
                                    <a href="#" onClick={() => setPage(page + 1)} className="page-link"><i className="fas fa-chevron-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            )}

            {(total <= 0) && (
                <>
                    <div className="col-sm-12 col-md-12">
                        <div className="w-100 empty-wrap d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 500px)' }}>
                            <div className="d-grid px-3 px-sm-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="img-wrap img-wrap-empty">
                                        <img alt="" src={"/dist/img/empty/empty-product.png"} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4">
                                    <div className="fs-6 fw-bold text-center">
                                        No Data Found!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PaginationComponent;