import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getDetailTransaction, getDetailListTransactionVendor } from "../../helper/function";
import LoadingComponent from '../../atoms/LoadingComponent';
import { formatMoney, formatDateTimeSecond } from "../../helper/lib";
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const TransactionsDetail = (props) => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const validateString = (string) => (string === null || string === undefined || string === "") ? "-" : string;

    const fetchDetail = async () => {
        setIsLoadingData(true)
        const response = await getDetailTransaction(id)

        if (response.message === "Success") {
            setData(response.data)
        }

        setIsLoadingData(false)
    };

    const getDetailDataVendor = async (page = 0, limit = 10) => {
        setIsLoadingData(true)

        const response = await getDetailListTransactionVendor()
        if (response.message === "Success" && response.meta.total > 0) {
            setDetailData(response.data)
        }
        else {
            setDetailData(null)
        }

        setIsLoadingData(false)
    }

    useEffect(() => {
        fetchDetail()
        getDetailDataVendor()
    }, []);


    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">

            {isLoadingData ? <LoadingComponent classList={['vh-100']} /> : <>
                <div className="mb-4 px-4 px-md-0">
                    <a onClick={() => navigate("/transactions")} className="fs-5 fw-bold">
                        <img alt="" src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" />
                        Transaction Detail
                    </a>
                </div>

                {/* <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                    <div className="w-100 px-4 mt-4 mt-md-3 px-md-0 table-responsive">
                        <table id="table_id2" className="table table-striped" style={{ "width": "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.9em' }}>No</th>
                                    <th style={{ fontSize: '0.9em' }}>Order No</th>
                                    <th style={{ fontSize: '0.9em' }}>Date Start</th>
                                    <th style={{ fontSize: '0.9em' }}>Date To</th>
                                    <th style={{ fontSize: '0.9em' }}>Item Original Price</th>
                                    <th style={{ fontSize: '0.9em' }}>Item discount price</th>
                                    <th style={{ fontSize: '0.9em' }}>Total Order</th>
                                    <th style={{ fontSize: '0.9em' }}>Shipping</th>
                                    <th style={{ fontSize: '0.9em' }}>Service Fee</th>
                                    <th style={{ fontSize: '0.9em' }}>Gross Revenue</th>
                                    <th style={{ fontSize: '0.9em' }}>KR Commission</th>
                                    <th style={{ fontSize: '0.9em' }}>Net Income</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ fontSize: '0.9em' }}>{index + 1}</td>
                                            <td style={{ fontSize: '0.9em' }}>{validateString(item?.order_no)}</td>
                                            <td style={{ fontSize: '0.9em' }}>{validateString(item?.date_start)}</td>
                                            <td style={{ fontSize: '0.9em' }}>{validateString(item?.date_to)}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.original_price, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.dicount_price, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.total_order, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.shipping, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.service_fee, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.gross_revenue, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.kr_commission, "Rp.", 0, ",", "."))}</td>
                                            <td className="text-end" style={{ fontSize: '0.9em' }}>{validateString(formatMoney(item?.net_income, "Rp.", 0, ",", "."))}</td>
                                        </tr>
                                    )
                                }
                                )}

                                {(data?.length === 0 || data === null || data === undefined) && (
                                    <tr>
                                        <td colSpan={12} className="text-center"><i>No Data</i></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div> */}

                <div className="w-100 d-flex align-items-lg-stretch flex-column flex-lg-row">
                    <div className="col-12 col-lg-12 mb-md-5 px-4 pe-md-0 ps-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
                        <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                            <div className="d-flex flex-column flex-md-row flex-lg-column">
                                <div className="col-lg-12 w-md-100">
                                    <div className="row row-cols-1 row-cols-md-3 gx-3">
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Order No</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.order_no)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Date Start</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatDateTimeSecond(new Date(data?.date_start)))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Date To</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatDateTimeSecond(new Date(data?.date_to)))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Item Original Price</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.original_price, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Item discount price</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.dicount_price, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Total Order</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.total_order, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Shipping</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.shipping, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Service Fee</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.service_fee, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Gross Revenue</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.gross_revenue, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">KR Commission</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.kr_commission, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Net Income</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.net_income, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(TransactionsDetail);
