import React from "react";
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getListTransactionVendor, getDetailTransactionVendor, getDetailBalanceVendor } from "../../helper/function";
import { formatMoney } from "../../helper/lib";
import ItemReviewTable from "../../organism/Transactions/TransactionsTable";
import ModalRequestDisbursement from "../../organism/Transactions/modals/ModalRequestDisbursement";
import $ from "jquery";
import moment from 'moment';
import 'moment/locale/id';

$.datepicker = require("bootstrap-datepicker")
moment.locale('id');

const Transactions = (props) => {
    const [data, setData] = useState([]);
    const [detailData, setDetailData] = useState(null);
    const [detailBalance, setDetailBalance] = useState(null);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalCurrent, setTotalCurrent] = useState(0);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [dateFilter, setDateFilter] = useState("");
    const [dateFilterEnd, setDateFilterEnd] = useState("");

    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleChangeLimit = (limit) => {

    }

    const disbursementSucceed = () => {
        getTransactionsData(page, limit);
        getDetailDataVendor();
    }

    const getTransactionsData = async (page = 0, limit = 10) => {
        setIsLoadingData(true)

        const payload = {
            type: "pending",
            orderType: 3,
            query: "",
            date: dateFilter !== "" ? dateFilter : moment(dateFilterEnd, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'),
            dateEnd: dateFilterEnd !== "" ? dateFilterEnd : moment(dateFilter, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
            sort: "",
            offset: page,
            limit: limit
        }
        const response = await getListTransactionVendor(payload)

        // const response = {
        //     data: [
        //         {
        //             id: "DKL12QSFW2",
        //             date_start: "2021-01-01 01:00:00",
        //             date_to: "2021-01-01 01:00:00",
        //             total_order: 1000000,
        //             commision: 100000,
        //             amount: 900000,
        //             status: "paid"
        //         },
        //         {
        //             id: "DKL12QSFW2",
        //             date_start: "2021-01-01 01:00:00",
        //             date_to: "2021-01-01 01:00:00",
        //             total_order: 1000000,
        //             commision: 100000,
        //             amount: 900000,
        //             status: "paid"
        //         },
        //         {
        //             id: "DKL12QSFW2",
        //             date_start: "2021-01-01 01:00:00",
        //             date_to: "2021-01-01 01:00:00",
        //             total_order: 1000000,
        //             commision: 100000,
        //             amount: 900000,
        //             status: "paid"
        //         },
        //         {
        //             id: "DKL12QSFW2",
        //             date_start: "2021-01-01 01:00:00",
        //             date_to: "2021-01-01 01:00:00",
        //             total_order: 1000000,
        //             commision: 100000,
        //             amount: 900000,
        //             status: "in_wallet"
        //         },

        //     ],
        //     meta: {
        //         total: 4,
        //         total_current: 4
        //     },
        //     message: "Success"
        // }

        if (response.message === "Success") {
            setData(response.data)
            setTotal(response.meta.total)
            setTotalCurrent(response.data.length)
            setIsLoadingData(false)
        }
        else {
            setData([])
            setIsLoadingData(false)
        }
    }

    const getDetailDataVendor = async () => {
        setIsLoadingData(true)

        const payload = {
            type: "pending",
            orderType: 3,
            date: dateFilter !== "" ? dateFilter : moment(dateFilterEnd, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'),
            dateEnd: dateFilterEnd !== "" ? dateFilterEnd : moment(dateFilter, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
        }
        const response = await getDetailTransactionVendor(payload)
        if (response.message === "Success" && response.meta.total > 0) {
            setDetailData(response.data)
        }
        else {
            setDetailData(null)
        }

        const responseBalance = await getDetailBalanceVendor()
        if (responseBalance.message === "Success" && responseBalance.meta.total > 0) {
            setDetailBalance(responseBalance.data)
        }
        else {
            setDetailBalance(null)
        }

        setIsLoadingData(false)
    }

    const handleResetFilter = () => {
        setPage(0)
        setLimit(10)
        setDateFilter("")
        setDateFilterEnd("")
        $('input[name="filterDate"]').val('')
        $('input[name="filterDateEnd"]').val('')
    }

    useEffect(() => {
        getTransactionsData(page, limit);
        getDetailDataVendor()
    }, [page, limit, dateFilter, dateFilterEnd]);

    useEffect(() => {
        getDetailDataVendor();

        $('#datepicker-order-date, #datepicker-order-date-end').datepicker({
            templates: {
                leftArrow: '<img src="../../dist/img/custom/chevron-left.svg"></img>',
                rightArrow: '<img src="../../dist/img/custom/chevron-right.svg"></img>'
            }
        });

        $('#datepicker-order-date').on('changeDate', function (e) {
            let date = $('input[name="filterDate"]').val();
            setDateFilter(moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').format('YYYY-MM-DD'))
        })

        $('#datepicker-order-date-end').on('changeDate', function (e) {
            let date = $('input[name="filterDateEnd"]').val();
            setDateFilterEnd(moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').add(1, 'days').format('YYYY-MM-DD'))
        })
    }, [])

    return (
        <>
            <div className="container-fluid mb-4 px-0 tab-navigasi">
                <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
                    <div className="w-100 swiper-nav swiper">
                        <ul className="nav nav-pills ff2 nav-wrap swiper-wrapper" id="filterType" role="tablist">
                            <li className="nav-item swiper-slide" role="presentation">
                                <NavLink to="/transactions" end className="nav-link" >
                                    Settlement
                                </NavLink>
                            </li>
                            <li className="nav-item swiper-slide" role="presentation">
                                <NavLink to="/RequestDisbursement" end className="nav-link" >
                                    History Disbursement
                                </NavLink>
                            </li>
                            <li className="ms-auto">
                                <div className="ps-0 ps-lg-4 w-lg-100 border-lg-0 d-flex flex-column flex-lg-row align-items-center">
                                    <button type="button" className="btn btn-fill fw-bold w-lg-100 me-0 w-lg-100 mb-2 mb-lg-0" data-bs-toggle="modal" data-bs-target="#ModalRequest" disabled={!(data && data?.length > 0)}>
                                        Request Disbursement
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                </div>

                <div className="fs-5 fw-bold mt-2 mb-4">
                    Transaction List
                </div>
                <div className="w-100 mb-4 alert alert-basic" role="alert">
                    <p className="fz-14 fw-bolder">Info</p>
                    <p className="mb-2 fz-14">Below are the list of completed order.</p>
                </div>
                <div className="w-100 rounded-3 flex-column flex-md-row d-flex my-4">
                    <div className="col-6 w-md-100 pe-0 pe-md-4 border-md-bottom border-md-right-0 pb-3 pb-md-0">
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="fz-14 fw-bold text-secondary mb-2">Current Balance</p>
                                <p className="fw-bold mb-2">Rp {formatMoney(detailBalance?.current_balance?.replaceAll(',', ''), '')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="fs-5 fw-bold mt-2 mb-4">
                Request Disbursement List
            </div> */}
                {/* <!-- Empty --> */}
                <div className="d-none">
                    <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                        <div className="d-grid px-3 px-sm-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="img-wrap img-wrap-empty">
                                    <img src="../dist/img/empty/empty-product.png" alt="img-search" />
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <div className="fs-6 fw-bold text-center">
                                    No Transactions Found!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Ada Isinya --> */}
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
                    <div className="pe-0 pe-lg-3 col w-lg-100 mb-2 mb-lg-0">
                        <div className="mb-2 mb-lg-0" hidden>
                            <div className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-100">
                                <input type="text" className="form-control border-0 fz-14 box-shadow-focus-0" placeholder="Search Here..." aria-label="Username" aria-describedby="addon-wrapping" />
                                <div className="py-1 px-1">
                                    <button className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center" type="button" id="button-addon2" style={{ width: "1.8rem", height: "1.7rem" }}>
                                        <img src="../dist/img/custom/search.svg" style={{ width: "0.9rem" }} className="filter-white" alt="img-search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-3 mb-lg-0">
                        From
                    </div>
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-3 mb-lg-0">
                        <div className="input-group bg-white border rounded password date dates w-lg-100" id="datepicker-order-date">
                            <input type="text" name="filterDate" className="form-control border-0 box-shadow-focus-0" placeholder="Date" aria-describedby="basic-addon1" />
                            <span className="input-group-append">
                                <span className="input-group-text bg-white border-0" id="basic-addon1">
                                    <img src="/dist/img/custom/calendar.svg" className="icon-3 pt-1" alt="img-calendar"></img>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-3 mb-lg-0">
                        To
                    </div>
                    <div className="pe-0 pe-lg-3 w-lg-100 mb-3 mb-lg-0">
                        <div className="input-group bg-white border rounded password date dates w-lg-100" id="datepicker-order-date-end">
                            <input type="text" name="filterDateEnd" className="form-control border-0 box-shadow-focus-0" placeholder="Date" aria-describedby="basic-addon2" />
                            <span className="input-group-append">
                                <span className="input-group-text bg-white border-0" id="basic-addon2">
                                    <img src="/dist/img/custom/calendar.svg" className="icon-3 pt-1" alt="img-calendar"></img>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="ps-0 ps-lg-4 w-lg-100 border-start border-lg-0 d-flex flex-column-reverse flex-lg-row align-items-center">
                        <button onClick={handleResetFilter} className="btn btn-line fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-2 mb-lg-0">
                            Reset
                        </button>
                    </div>
                </div>
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-end mt-5">
                    <div>
                        <p className="fz-14 fw-bold text-secondary mb-2">Total Income</p>
                        <p className="fw-bold mb-0">Rp {detailData?.balance === '-' ? '0' : formatMoney(detailData?.balance?.replaceAll(',', ''), '')}</p>
                    </div>
                </div>
                {(data.length > 0 || isLoadingData) &&
                    (
                        <div className="w-100 mt-3">
                            <ItemReviewTable
                                items={data}
                                total={total}
                                page={page}
                                limit={limit}
                                isLoadingData={isLoadingData}
                                onChangePage={handleChangePage}
                                onChangeLimit={handleChangeLimit}
                            />
                        </div>
                    )}
                {data.length === 0 && !isLoadingData &&
                    (
                        <div className="w-100 mt-3">
                            <ItemReviewTable
                                items={data}
                                total={total}
                                page={page}
                                limit={limit}
                                isLoadingData={isLoadingData}
                                onChangePage={handleChangePage}
                                onChangeLimit={handleChangeLimit}
                            />
                        </div>
                    )}
            </div>

            <ModalRequestDisbursement
                handleClose={() => { }}
                closeOnClickBackdrop={false}
                data={detailBalance}
                disbursementSucceed={disbursementSucceed}
            />
        </>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(Transactions);
