import React from "react";
import { createStore } from 'redux';
import Router from "./Router";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const globalState = {
  totalCart: 0,
  alamatPengiriman: null,
  loadingGeneral: false,
  dataProfile: null,
  dataNotification:null,
  dataCategory: null,
  brand: null, 
  qbSession: null,
  qbUnread: 0,
  qbNewMessage: null
}
const rootReducer = (state = globalState, action) => {
  switch (action.type) {
    case 'ADD_TOTALCART':
      return {
        ...state,
        totalCart: action.payload
      }
    case 'ADD_PENGIRIMAN':
      return {
        ...state,
        alamatPengiriman: action.payload
      }
    case 'CHANGE_LOADING':
      return {
        ...state,
        loadingGeneral: action.payload
      }
    case 'CHANGE_DATA_PROFILE':
      return {
        ...state,
        dataProfile: action.payload
      }
    case 'CHANGE_DATA_CATEGORY':
      return {
        ...state,
        dataCategory: action.payload
      }
    case 'CHANGE_DATA_BRAND':
      return {
        ...state,
        brand: action.payload
      }
    case 'CHANGE_DATA_NOTIFICATION':
      return {
        ...state,
        dataNotification: action.payload
      }
    case 'CHANGE_QB_SESSION':
      return {
        ...state,
        qbSession: action.payload
      }
    case 'CHANGE_QB_UNREAD':
      return {
        ...state,
        qbUnread: action.payload
      }
    case 'CHANGE_QB_NEW_MESSAGE':
        return {
          ...state,
          qbNewMessage: action.payload
        }
  
    default:
      return state
  }
}
const store = createStore(rootReducer);

function App() {
  return (
    <Provider store={store}>
      <Router />
      <ToastContainer />
    </Provider>
  )
}

export default App;