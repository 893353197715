import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getDetailDisbursement, getDetailTransactionVendor } from "../../../helper/function";
import LoadingComponent from '../../../atoms/LoadingComponent';
import { formatMoney, formatDateTimeSecond } from "../../../helper/lib";
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const RequestDisbursementDetailDesigner = (props) => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const validateString = (string) => (string === null || string === undefined || string === "") ? "-" : string;

    const fetchDetail = async () => {
        setIsLoadingData(true)
        const response = await getDetailDisbursement(id)

        if (response.message === "Success") {
            setData(response.data)
        }

        setIsLoadingData(false)
    };

    const getDetailDataVendor = async (page = 0, limit = 10) => {
        setIsLoadingData(true)

        const response = await getDetailTransactionVendor()
        if (response.message === "Success" && response.meta.total > 0) {
            setDetailData(response.data)
        }
        else {
            setDetailData(null)
        }

        setIsLoadingData(false)
    }

    useEffect(() => {
        fetchDetail()
        getDetailDataVendor()
    }, []);


    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">

            {isLoadingData ? <LoadingComponent classList={['vh-100']} /> : <>
                <div className="mb-4 px-4 px-md-0">
                    <a onClick={() => navigate("/designer/requestdisbursement")} className="fs-5 fw-bold">
                        <img alt="" src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" />
                        Disbursement Detail
                    </a>
                </div>


                {/* <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100 mb-3">
                    <h5>Bank Information</h5>
                    <hr />

                    <div className="col-12 col-lg-12 mb-md-5 px-4 pe-md-0 ps-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
                        <div className="d-flex flex-column flex-md-row flex-lg-column">
                            <div className="col-lg-12 w-md-100">
                                <div className="row row-cols-1 row-cols-md-2 gx-3">
                                    <div className="col mb-3">
                                        <div className="w-100 d-flex align-items-start d-md-block">
                                            <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Name</p>
                                            <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(detailData?.Name)}</p>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <div className="w-100 d-flex align-items-start d-md-block">
                                            <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Bank</p>
                                            <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(detailData?.Bank)}</p>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <div className="w-100 d-flex align-items-start d-md-block">
                                            <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Account No</p>
                                            <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(detailData ? detailData['Account Number'] : '-')}</p>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <div className="w-100 d-flex align-items-start d-md-block">
                                            <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Account Name</p>
                                            <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(detailData ? detailData['Account Name'] : '-')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="w-100 d-flex align-items-lg-stretch flex-column flex-lg-row">
                    <div className="col-12 col-lg-12 mb-md-5 px-4 pe-md-0 ps-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
                        <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                            <div className="d-flex flex-column flex-md-row flex-lg-column">
                                <div className="col-lg-12 w-md-100">
                                    <div className="row row-cols-1 row-cols-md-3 gx-3">
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Request No</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.disburse_id)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Status</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2"><div className="badge-gray">{data?.status}</div></p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">In Process Date</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.list_transaction[1]?.tanggal ? validateString(formatDateTimeSecond(new Date(data?.list_transaction[1]?.tanggal))) : ''}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Disbursement Request</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.amount_before_fee.replaceAll(',', '')))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Disbursement Fee</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.disbursement_fee ? data?.disbursement_fee.replaceAll(',', '') : 0))}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Disbursed Date</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.list_transaction[0]?.tanggal ? validateString(formatDateTimeSecond(new Date(data?.list_transaction[0]?.tanggal))) : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Disbursement Amount</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(formatMoney(data?.amount.replaceAll(',', '')))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                    <h5>Disbursement Histories</h5>
                    <hr />

                    <div className="w-100 px-4 mt-4 mt-md-3 px-md-0">
                        <table id="table_id2" className="table table-striped" style={{ "width": "100%" }}>
                            <thead>
                                <tr>
                                    <th className="w-13-persen">No</th>
                                    <th className="w-15-persen">Date</th>
                                    <th className="w-15-persen">Amount</th>
                                    <th className="w-15-persen">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.list_transaction?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{validateString(formatDateTimeSecond(new Date(item?.tanggal)))}</td>
                                            <td>{validateString(formatMoney(item?.amount, "Rp.", 0, ",", "."))}</td>
                                            <td><div className="badge-gray">{item?.status}</div></td>
                                        </tr>
                                    )
                                }
                                )}

                                {(data?.list_transaction?.length === 0) && (
                                    <tr>
                                        <td colSpan={4} className="text-center"><i>No Histories</i></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </>
            }
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(RequestDisbursementDetailDesigner);
