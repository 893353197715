import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDashboard, switchActive, getProfile } from "../helper/function";
import $ from "jquery";
import { connect } from "react-redux";
import SwiperInstance from "swiper"
import DashboardLoading from "../organism/Dashboard/DashboardLoading";
$.DataTable = require("datatables.net-bs5");

const Dashboard = (props) => {
    const [tableBestSelling, setTableBestSelling] = useState();
    const [tableOutOfStock, setTableOutOfStock] = useState();
    // const [newsSwiper, setNewsSwiper] = useState();
    const [data, setData] = useState();
    const [id, setId] = useState();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const newsEl = React.useRef(null);
    const newsSwiper = React.useRef(null);
    const [switchOn, setSwitchOn] = useState(false);



    const getUserData = async () => {
        let res = await getProfile();

        if (res.meta.total > 0) {
            setId(res.data.id);
            setSwitchOn(res?.data?.is_open)
        } else {
            console.error({ res });
        }
    }

    const handleSwitchToggle = () => {
        setSwitchOn(!switchOn)
        switchTogle();
    }

    const switchTogle = async () => {
        let res = await switchActive(id, String(!switchOn));
        if (res.meta.total > 0) {
        } else {
        }
    }

    const getDashboardData = async () => {
        setIsLoading(true);
        let res = await getDashboard();

        if (res.meta.total > 0) {
            setData(res.data);
            setIsLoading(false);
            props.setLoading(false);

        } else {
            props.setLoading(false);
            if (res.message && res.message === "Unauthorized access") {
                navigate("/login");
                return;
            }
        }
    }

    useEffect(() => {
        let t1 = tableBestSelling
        let t2 = tableOutOfStock;

        // initialize best selling table
        t1?.destroy();
        t1 = $('#table_id').DataTable({
            "lengthChange": false,
            "searching": false,
            language: {
                "emptyTable": " ",
                "infoEmpty": " ",
                "info": "Showing _START_ - _END_ of _TOTAL_ items.",
                "paginate": {
                    "previous": '<i class="fas fa-chevron-left"></i>',
                    "next": '<i class="fas fa-chevron-right"></i>'
                }
            },
            // scrollX: true,
            "pageLength": 5,
            "ordering": false,
            data: data?.recommendations?.best_selling,
            columns: [
                {
                    width: "10%",
                    data: "id", render: (data, type, row, meta) => {
                        return meta.row + 1;
                    }
                },
                {
                    data: "name", render: (data, type, row, meta) => {
                        let text = data.length > 70 ? data.substring(0, 70) + "..." : data;
                        return `<span data-toggle="tooltip" data-placement="top" title="${data}">${text}</span>`;
                    }
                },
                { width: "10%", data: "total_item" },
            ],
            columnDefs: [
                {
                    targets: 2,
                    className: 'text-center'
                }
            ],
            "autoWidth": false
        })
        setTableBestSelling(t1);

        // initialize out of stock table
        t2?.destroy();
        t2 = $('#table_id2').DataTable({
            "lengthChange": false,
            "searching": false,
            language: {
                "emptyTable": " ",
                "infoEmpty": " ",
                "info": "Showing _START_ - _END_ of _TOTAL_ items.",
                "paginate": {
                    "previous": '<i class="fas fa-chevron-left"></i>',
                    "next": '<i class="fas fa-chevron-right"></i>'
                }
            },
            // scrollX: true,
            "pageLength": 5,
            "ordering": false,
            data: data?.out_of_stock?.stock_list,
            columns: [
                {
                    data: "id", render: (data, type, row, meta) => {
                        return meta.row + 1;
                    }
                },
                {
                    data: "name", render: (data, type, row, meta) => {
                        let text = data.length > 50 ? data.substring(0, 50) + "..." : data;
                        return `<span data-toggle="tooltip" data-placement="top" title="${data}">${text}</span>`;
                    }
                },
            ],
            "autoWidth": false
        })

        setTableOutOfStock(t2);

        $('.dataTables_scrollBody').css('min-height', '260px');


        setTimeout(() => {
            t2?.columns.adjust().draw();
            t1?.columns.adjust().draw();
        }, 500);
    }, [data])



    useEffect(() => {

        let swiperInstance = new SwiperInstance(newsEl.current, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            slideSize: 360,
            navigation: {
                nextEl: "#swiper-button-next",
                prevEl: "#swiper-button-prev",
            },
        });

        newsSwiper.current = swiperInstance;

    }, [newsEl.current])

    useEffect(() => {
        getDashboardData();
    }, [])
    useEffect(() => {
        getUserData();
    }, [])

    function open(props) {
        return <>Open Shop</>;
    }

    function close(props) {
        return <>Close Shop</>;
    }

    const handleClickOrderStat = (type) => {
        navigate(`/orders?type=${type}`);
    }

    return (
        isLoading ? <DashboardLoading /> :
            <div className="container-fluid mb-4 px-0">
                <div className="d-lg-flex">
                    <div className="col-12 col-lg-7 pe-lg-2">
                        <div className="rounded-lg border w-100 d-flex align-items-center justify-content-start p-3">
                            <div>
                                <label className="switch">
                                    <input type="checkbox" checked={switchOn} onChange={handleSwitchToggle} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="fs-6 fw-semibold ms-3">{!switchOn ? open() : close()}</div>
                        </div>
                        <div className="rounded-lg border w-100 d-flex align-items-center p-3 mt-3">
                            <div className="col-7 col-md-5 pe-2 d-flex align-items-center justify-content-center">
                                <div className="img-wrap" style={{ "width": "227px", "height": "227px" }}>
                                    <img alt="" src="../dist/img/custom/furniture-store.svg" />
                                </div>
                            </div>
                            <div className="col-5 col-md-7 pe-md-5 d-flex align-items-center">
                                <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="fw-semibold">Total Order</div>
                                        <Link to={{ pathname: "/orders" }} state={{ status: "active" }} className="link-dark fz-14 fw-bold">Detail</Link>
                                    </div>
                                    <div className="row gx-5">
                                        <div onClick={() => handleClickOrderStat('new')} className="rounded-lg border order-stat col-5 p-3 mt-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <div className="mb-1 fz-14">New Order</div>
                                                        <div className="fs-5 fw-bold">{data?.order_summary?.new_order ?? 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2"></div>
                                        <div onClick={() => handleClickOrderStat('confirmed')} className="rounded-lg border order-stat col-5 p-3 mt-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <div className="mb-1 fz-14">Confirmed</div>
                                                        <div className="fs-5 fw-bold">{data?.order_summary?.order_confirmed ?? 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div onClick={() => handleClickOrderStat('processing')} className="rounded-lg border order-stat col-5 p-3 mt-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <div className="mb-1 fz-14">Processing</div>
                                                        <div className="fs-5 fw-bold">{data?.order_summary?.order_processed ?? 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2"></div>
                                        <div onClick={() => handleClickOrderStat('pickup')} className="rounded-lg border order-stat col-5 p-3 mt-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <div className="mb-1 fz-14">Waiting for pickup</div>
                                                        <div className="fs-5 fw-bold">{data?.order_summary?.order_waiting_for_pickup ?? 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 ps-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold">Total Products</div>
                                <div className="fw-bold">{data?.products.active + data?.products.inactive}</div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img alt="" src="../dist/img/custom/check-circle-vendor.svg" className="icon-b-3" />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14">Active</div>
                                            <div className="fs-5 fw-bold">{data?.products.active}</div>
                                        </div>
                                    </div>
                                    <Link to={{ pathname: "/items" }} state={{ status: "active" }} className="link-dark fz-14 fw-bold">Detail</Link>
                                    {/* <a href="#" className="link-dark fz-14 fw-bold">Detail</a> */}
                                </div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img alt="" src="../dist/img/custom/x-circle-vendor.svg" className="icon-b-3" />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14">Inactive</div>
                                            <div className="fs-5 fw-bold">{data?.products.inactive}</div>
                                        </div>
                                    </div>
                                    <Link to={{ pathname: "/items" }} state={{ status: "inactive" }} className="link-dark fz-14 fw-bold">Detail</Link>
                                    {/* <a href="#" className="link-dark fz-14 fw-bold">Detail</a> */}

                                </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="fw-semibold">Total Product In Review</div>
                                <div className="fw-bold">{data?.products.in_review}</div>
                            </div>
                            <div className="rounded-lg border w-100 p-3 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img alt="" src="../dist/img/custom/hourglass-circle.svg" className="icon-b-3" />
                                        </div>
                                        <div className="ms-3">
                                            <div className="mb-1 fz-14">In Review</div>
                                            <div className="fs-5 fw-bold">{data?.products.in_review}</div>
                                        </div>
                                    </div>
                                    <Link to={{ pathname: "/item-review" }} className="link-dark fz-14 fw-bold">Detail</Link>
                                    {/* <a href="#" className="link-dark fz-14 fw-bold">Detail</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-lg-flex mt-3 mt-lg-4">
                    <div className="col-12 col-lg-7 pe-lg-2">
                        <div className="rounded-lg border w-100 h-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold">Best Selling Products</div>
                            </div>
                            <div className="w-100 mt-3">
                                <table id="table_id" className="table table-striped w-100" >
                                    <thead>
                                        <tr>
                                            <th className="number">No</th>
                                            <th className="name">Name</th>
                                            <th className="total-items">Total Products</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                        <td className="number">1</td>
                                        <td className="name">Table A</td>
                                        <td className="total-items">42</td>
                                    </tr>
                                    <tr>
                                        <td className="number">2</td>
                                        <td className="name">Table B</td>
                                        <td className="total-items">25</td>
                                    </tr>
                                    <tr>
                                        <td className="number">3</td>
                                        <td className="name">Table C</td>
                                        <td className="total-items">21</td>
                                    </tr>
                                    <tr>
                                        <td className="number">4</td>
                                        <td className="name">Table D</td>
                                        <td className="total-items">19</td>
                                    </tr>
                                    <tr>
                                        <td className="number">5</td>
                                        <td className="name">Table E</td>
                                        <td className="total-items">17</td>
                                    </tr>
                                    <tr>
                                        <td className="number">1</td>
                                        <td className="name">Table A</td>
                                        <td className="total-items">42</td>
                                    </tr>
                                    <tr>
                                        <td className="number">2</td>
                                        <td className="name">Table B</td>
                                        <td className="total-items">25</td>
                                    </tr>
                                    <tr>
                                        <td className="number">3</td>
                                        <td className="name">Table C</td>
                                        <td className="total-items">21</td>
                                    </tr>
                                    <tr>
                                        <td className="number">4</td>
                                        <td className="name">Table D</td>
                                        <td className="total-items">19</td>
                                    </tr>
                                    <tr>
                                        <td className="number">5</td>
                                        <td className="name">Table E</td>
                                        <td className="total-items">17</td>
                                    </tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 ps-lg-2 mt-3 mt-lg-0">
                        <div className="rounded-lg border w-100 p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-semibold">Out Of Stock</div>
                            </div>
                            <div className="w-100 mt-3">
                                <table id="table_id2" className="table table-striped w-100">
                                    <thead>
                                        <tr>
                                            <th className="number">No</th>
                                            <th className="name">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {
                                        data?.out_of_stock?.stock_list.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="number">{index + 1}</td>
                                                    <td className="name">{item.name}</td>
                                                </tr>
                                            )
                                        })
                                    } */}
                                        {/* <tr>
                                            <td className="number">1</td>
                                            <td className="name">Table A</td>
                                        </tr>
                                        <tr>
                                            <td className="number">2</td>
                                            <td className="name">Table B</td>
                                        </tr>
                                        <tr>
                                            <td className="number">3</td>
                                            <td className="name">Table C</td>
                                        </tr>
                                        <tr>
                                            <td className="number">4</td>
                                            <td className="name">Table D</td>
                                        </tr>
                                        <tr>
                                            <td className="number">5</td>
                                            <td className="name">Table E</td>
                                        </tr>
                                        <tr>
                                            <td className="number">6</td>
                                            <td className="name">Table F</td>
                                        </tr>
                                        <tr>
                                            <td className="number">7</td>
                                            <td className="name">Table G</td>
                                        </tr>
                                        <tr>
                                            <td className="number">8</td>
                                            <td className="name">Table H</td>
                                        </tr>
                                        <tr>
                                            <td className="number">9</td>
                                            <td className="name">Table I</td>
                                        </tr>
                                        <tr>
                                            <td className="number">10</td>
                                            <td className="name">Table J</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(Dashboard);
