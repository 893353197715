import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateProjectNote } from "../../../helper/function";
import $ from "jquery";
import { IsAlert } from "../../../atoms/IsAlert";

const UpdateProjectNote = (props) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [note, setNote] = useState(location.state?.noted)
    const { orderNo, projectID } = useParams();

    //
    const handleSubmit = async (event) => {
        event.preventDefault()

        // Disable button
        $('.btn-save').prop('disabled', true)

        const payload = {
            orderNo: orderNo,
            note: note,
            id: projectID,
        }

        const response = await updateProjectNote(payload)
        let redirectUrlSuccess = `/designer/project/view/${orderNo}/${projectID}`
        if (response?.message === "Success") navigate(redirectUrlSuccess, {
            state: {
                tab: "designPlan"
            }
        })

        // Error
        if (response?.message !== "Success") {
            $('.btn-save').prop('disabled', false)
            IsAlert.fire({
              title: "Fail",
              text: response.message,
              icon: "error",
            })
        }
    }

    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            <div className="mb-4">
                <a onClick={() => navigate(-1)} className="fs-6 fw-bold">
                    <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                    Update Greetings
                </a>
            </div>
            <hr className="mt-5 mb-5" />

            {/* Designer Note for Others */}
            <div className="fs-5 fw-bold mt-2 mb-4">
                Designer Greetings for Others
            </div>
            <form onSubmit={handleSubmit}>
                <div className="d-sm-flex mb-3">
                    <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                        <label className="form-label fw-semibold">Greetings</label>
                        <p>You can save & edit this note later before you send your design plan.</p>
                        <textarea className="form-control" rows="3" placeholder="Note" value={note} onChange={(event) => { setNote(event.target.value) }}></textarea>
                    </div>
                </div>

                <div className="text-right">
                    <button type="submit" className="btn fw-semibold btn-fill px-5 mt-3 ask btn-save" >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(UpdateProjectNote);