import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchApiPostPatchNoToken } from "../helper/api";
import { useSnackbar } from 'notistack';

const Activate = () => {
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    let { code } = useParams();
    React.useEffect(() => {
        var mount = true
        if (mount) {
            getActivate()
        }
        return (() => {
            mount = false
        })
    }, [])
    const getActivate = async () => {
        var req = await fetchApiPostPatchNoToken("users/session/activate", { code: code })
        var res = await req.json()
        if (res.meta.total > 0) {
            enqueueSnackbar('Sukses mengaktifasi, silahkan tunggu', { variant: "success", preventDuplicate: true });
            setTimeout(() => {
                navigate('/login')
            }, 5000);
        }
        else {
            enqueueSnackbar(res.message, { variant: "error", preventDuplicate: true });
        }

    }
    return (
        <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
            <p>Prosses Activate</p>
        </div>
    )
}

export default Activate