import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { fetchApiPostPatchNoToken } from "../helper/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import $ from "jquery";
import { IsAlert } from "../atoms/IsAlert";

const ResetPassword = (props) => {
  let { code } = useParams();
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password harus diisi")
      .min(6, "Password harus lebih dari 6 karakter")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password harus terdiri dari Uppercase, Lowercase, Nomor dan Symbol"
      ),
    confirm_password: Yup.string()
      .required("Password harus diisi")
      .oneOf([Yup.ref("password")], "Password tidak sama"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [errorMessage, seterrorMessage] = React.useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const onSubmit = async (data) => {
    data.key = code;
    var req = await fetchApiPostPatchNoToken(
      "merchants/session/reset_password",
      data
    );
    var res = await req.json();
    if (res.meta.total > 0) {
      IsAlert.fire({
        title: "Berhasil",
        text: res.message,
        icon: "success",
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Selesai",
      }).then(function (isConfirm) {
        if (isConfirm.isConfirmed) {
          navigate("/login");
        }
      });
    } else {
      IsAlert.fire({
        title: "Gagal",
        text: res?.message === 'The record was not found' ? 'URL is not valid' : res?.message,
        icon: "error",
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Konfirmasi",
      })
      seterrorMessage(res.message);
    }
  };
  React.useEffect(() => {
    showHidePassword();
  }, []);
  const showHidePassword = () => {
    $("i#show").click(function () {
      $(this).addClass("d-none").removeClass("d-block");
      $(this).parent().find("i#hide").addClass("d-block").removeClass("d-none");
      $(this).parent().parent().parent().find("input").attr("type", "text");
    });
    $("i#hide").click(function () {
      $(this).addClass("d-none").removeClass("d-block");
      $(this).parent().find("i#show").addClass("d-block").removeClass("d-none");
      $(this).parent().parent().parent().find("input").attr("type", "password");
    });
  };
  return (
    <div className="wrap-content signin">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  New Password
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <span className="pe-1 text-gray text-center fz-14">
                  Enter your new password.
                </span>
              </div>
              <div className="mb-3">
                <label className="form-label fz-12 fw-bold">Password</label>
                <div className={`input-group d-flex bg-white rounded password ${
                      errors.password ? "is-invalid" : ""
                    }`}>
                  <input
                    {...register("password")}
                    className={`form-control border-0 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    type="password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    className="input-group-text bg-white border-0"
                    id="basic-addon1"
                  >
                    <i className="fas fa-eye-slash text-secondary" id="show" />
                    <i
                      className="fas fa-eye text-secondary d-none"
                      id="hide"
                    />
                  </span>
                </div>
                {errors.password && (
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label fz-12 fw-bold">
                  Confirm Password
                </label>
                <div
                  className={`input-group d-flex bg-white rounded password ${
                    errors.confirm_password ? "is-invalid" : ""
                  }`}
                >
                  <input
                    {...register("confirm_password")}
                    className={`form-control border-0 ${
                      errors.confirm_password ? "is-invalid" : ""
                    }`}
                    type="password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    className="input-group-text bg-white border-0"
                    id="basic-addon1"
                  >
                    <i className="fas fa-eye-slash text-secondary" id="show" />
                    <i
                      className="fas fa-eye text-secondary d-none"
                      id="hide"
                    />
                  </span>
                </div>
                {errors.confirm_password && (
                  <div className="invalid-feedback">
                    {errors.confirm_password?.message}
                  </div>
                )}
              </div>
              <button type="submit" className="btn w-100 fw-semibold btn-fill">
                Reset Password
              </button>
              <div className="d-flex align-items-center justify-content-center mt-5 fz-14 ask">
                <span className>
                  <Link
                    to="/login"
                    className="link-dark d-flex align-items-center justify-content-center fw-semibold"
                  >
                    <div className="img-wrap me-1">
                      <img src="../dist/img/custom/arrow-left.svg" alt="img-arrow-left" />
                    </div>
                    Back to log in
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
