import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { getBankList, addVendorBank, updateVendorBank } from '../../../../helper/function';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from 'react-toastify';

const ModalAddBank = ({ itemReview, onSubmit, isShowModal, handleClose, closeOnClickBackdrop = false, dataEdit = null, isEdit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const modalBackdrop = useRef(null);
    const btnClose = useRef(null)
    const [options, setOptions] = useState([]);
    const [bankOptions, setBankOptions] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const customStyles = {
        bank: {
            control: (provided, state) => ({
                ...provided,
            }),
            menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
            }),
        }
    }

    const validationSchema = Yup.object().shape({
        bank_id: Yup.string().required("Bank is Required"),
        account_name: Yup.string().required("Account Name is Required"),
        account_number: Yup.number().required("Account Number is Required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm(formOptions);

    const getBankData = async () => {
        const response = await getBankList();
        if (response.message === "Success" && response.data.length > 0) {
            let bank = response.data.map((item) => {
                return { value: item.id, label: item.name };
            });
            setBankOptions(bank);
        } else {
            setBankOptions([]);
        }
    }

    const closeModal = () => {
        resetModal();
        btnClose.current.click();
        // btnClose.current.dispatchEvent(new MouseEvent("click", { bubbles: true }));

    }

    const handleChangeBank = (e) => {
        setSelectedBank(e);
        setValue("bank_id", e.value);
    }

    const handleChangeAccountName = (e) => {
        setAccountName(e.target.value);
        setValue("account_name", e.target.value);
    }

    const handleChangeAccountNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setAccountNumber(e.target.value);
            setValue("account_number", e.target.value);
        }
    }

    const onSubmitBank = (data) => {
        setErrorMessage(null);
        setIsSubmitting(true);
        if (dataEdit) {
            const loadingToast = toast.loading("Updating Bank Account...")

            // TODO : api here
            let dataUpdate = {
                "id": parseInt(dataEdit?.id),
                "bank_id": parseInt(data?.bank_id),
                "account_name": data?.account_name,
                "account_number": data?.account_number
            }
            updateVendorBank(dataUpdate)
            .then((response) => {
                if (response.message === "Success") {
                    onSubmit();
                    closeModal();
                    toast.update(loadingToast, { render: "Bank Account Updated", type: "success", isLoading: false, autoClose: 5000,closeOnClick: true, pauseOnHover: true  });
                } else {
                    setErrorMessage(response.message);
                    toast.update(loadingToast, { render: response.message, type: "error", isLoading: false, autoClose: 5000,closeOnClick: true, pauseOnHover: true  });
                }
            }).finally(() =>{
                setIsSubmitting(false)
            })

        } else {
            const loadingToast = toast.loading("Adding Bank Account...")
                addVendorBank(data)
                .then((response) => {
                    if (response.message === "Success") {
                        onSubmit();
                        closeModal();
                        toast.update(loadingToast, { render: "Bank Account Added", type: "success", isLoading: false, autoClose: 5000,closeOnClick: true, pauseOnHover: true  });
                    } else {
                        setErrorMessage(response.message);
                        toast.update(loadingToast, { render: response.message, type: "error", isLoading: false, autoClose: 5000,closeOnClick: true, pauseOnHover: true  });
                    }
                }).finally(() =>{
                    setIsSubmitting(false)
                })
        }

    }

    const resetModal = () => {
        setSelectedBank(null);
        setAccountName("");
        setAccountNumber("");
        setValue("bank_id", "");
        setValue("account_name", "");
        setValue("account_number", "");
        setErrorMessage(null);
        setIsSubmitting(false);
    }


    useEffect(() => {
        getBankData();
    }, []);

    useEffect(() => {
        if (dataEdit) {
            let findBank = bankOptions.find((item) => item.label === dataEdit.bank_name);
            handleChangeBank(findBank);
            setAccountName(dataEdit.account_name);
            setAccountNumber(dataEdit.account_number);

            setValue("account_name", dataEdit.account_name);
            setValue("account_number", dataEdit.account_number);
        }

    }, [dataEdit]);
    
    useEffect(() => {
        if (closeOnClickBackdrop) {
            const handleClickOutside = (event) => {
                if (modalBackdrop.current && !modalBackdrop.current.contains(event.target)) {
                    handleClose()
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            setOptions({
                "data-bs-backdrop": "static",
                "data-bs-keyboard": "false",
            });

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }

    }, [closeOnClickBackdrop, handleClose]);


    return (
        <>
            <div className={`modal fade ${isShowModal ? 'show' : ''}`} id="ModalAddBank"
                {...options}
                tabIndex="-1"
                aria-labelledby="ModalAddBankLabel"
                aria-hidden={!isShowModal}
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title fw-bold" id="ModalAddCityLabel">{!dataEdit ? "Add Bank" : "Edit Bank"}</h5>
                            <button type="button" id="btn-close-modal" ref={btnClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModal()}></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmitBank)}>
                            {/* input id hidden when edit */}
                            {dataEdit && <input type="hidden" value={dataEdit.id} {...register("id")} />}
                            <div className="modal-body">
                                {errorMessage !== null && (
                                <div className="alert alert-danger alert-dismissible fade show">
                                    {errorMessage}
                                    <button type="button" className="close" onClick={() => setErrorMessage(null)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                )}
                                <div className="mb-3">
                                    <label className="form-label fw-bolder">Bank Name</label>
                                    <Controller
                                        name="bank_id"
                                        control={control}
                                        render={({ field }) =>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={selectedBank}
                                                placeHolder="Select Bank"
                                                options={bankOptions}
                                                isSearchable={true}
                                                styles={customStyles.bank}
                                                menuPortalTarget={document.body}
                                                onChange={handleChangeBank}
                                            />
                                        }
                                    />
                                    {errors.bank_id ? (
                                        <span className="text-danger">
                                            {errors.bank_id.message}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="d-sm-flex mb-3">
                                    <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                                        <label className="form-label fw-bolder">Account Name</label>
                                        <input type="text" className="form-control" {...register("account_name")} onChange={handleChangeAccountName} value={accountName}/>
                                        {errors.account_name ? (
                                            <span className="text-danger">
                                                {errors.account_name.message}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-12 col-sm-6 ps-sm-2">
                                        <label className="form-label fw-bolder">Account Number</label>
                                        <input type="text" className="form-control" {...register("account_number")} onChange={handleChangeAccountNumber} value={accountNumber} maxLength={10}/>
                                        {errors.account_number ? (
                                            <span className="text-danger">
                                                {errors.account_number.message}
                                            </span>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 position-relative">
                                <div className="w-sm-50 m-0 m-sm-1 p-1 p-sm-0">
                                    <button type="button" className="btn w-sm-100 btn-light-gray fw-semibold" data-bs-dismiss="modal" onClick={() => resetModal()}>Close</button>
                                </div>
                                <div className="w-sm-50 m-0 m-sm-1 p-1 p-sm-0">
                                    {!isSubmitting && (
                                        <button type="submit" className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold">{!dataEdit ? "Add Bank" : "Edit Bank"}</button>
                                    )}
                                    {isSubmitting && (
                                        <button type="submit" disabled className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold">
                                            <div className="spinner-border" role="status" style={{width:"1.5rem",height:"1.5rem"}}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </>
    );
}

export default ModalAddBank;




