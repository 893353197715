import React from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { updateUserPassword } from "../../helper/function"
import { IsAlert } from "../../atoms/IsAlert";
import $ from "jquery";

const ChangePassword = (props) => {
    const [currPassword, setCurrPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')

    const [isHideCurrPassword, setIsHideCurrPassword] = useState(true)
    const [isHideNewPassword, setIsHideNewPassword] = useState(true)
    const [isHideConfPassword, setIsHideConfPassword] = useState(true)

    const handleChangeEyeCurrPassword = () => {
        let setData = !isHideCurrPassword

        $('.current-password').find('input').attr('type', setData ? 'password' : 'text')
        setIsHideCurrPassword(setData)
    }

    const handleChangeEyeNewPassword = () => {
        let setData = !isHideNewPassword

        $('.new-password').find('input').attr('type', setData ? 'password' : 'text')
        setIsHideNewPassword(setData)
    }

    const handleChangeEyeConfPassword = () => {
        let setData = !isHideConfPassword

        $('.confirm-password').find('input').attr('type', setData ? 'password' : 'text')
        setIsHideConfPassword(setData)
    }

    const handleChangePassword = async () => {
        if(newPassword !== confPassword) {
            IsAlert.fire({
                title: "Failed",
                text: 'Password confirmation is not same',
                icon: "error",
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonText: "Confirm",
            });
            return;
        }

        // Acces API
        props.setLoading(true)
        let payload = {
            current_password: currPassword,
            password: newPassword,
            confirm_password: confPassword,
        }
        let response = await updateUserPassword(payload);
        if (response.message === "Success") {
            setCurrPassword('')
            setNewPassword('')
            setConfPassword('')
            IsAlert.fire({
                title: "Success",
                text: 'Update password success.',
                icon: "success",
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonText: "Confirm",
            })
        } else {
            IsAlert.fire({
                title: "Error",
                text: response?.message ?? 'Something when wrong.',
                icon: "error",
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonText: "Confirm",
            })
        }
        props.setLoading(false)

    }

    return (
        <div className="tab-content" id="pills-tabContent">
            {/* <!-- ChangePassword --> */}
            <div className="tab-pane fade show active" id="pills-password" role="tabpanel" aria-labelledby="pills-password-tab">
                <div className="fs-5 fw-bold mb-4">
                    Password
                </div>
                <form>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Current Password</label>
                        <div className="input-group d-flex bg-white rounded password current-password">
                            <input type="password" className="form-control border-0" aria-label="Username" aria-describedby="basic-addon1" value={currPassword} onChange={(event) => { setCurrPassword(event.target.value) }} />
                            <span className="input-group-text bg-white border-0" id="basic-addon1" onClick={handleChangeEyeCurrPassword}>
                                {(!isHideCurrPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-show.svg" id="show"></img>
                                    </>
                                )}
                                {(isHideCurrPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-hide.svg" id="hide"></img>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">New Password</label>
                        <div className="input-group d-flex bg-white rounded password new-password">
                            <input type="password" className="form-control border-0" aria-label="Username" aria-describedby="basic-addon1" value={newPassword} onChange={(event) => { setNewPassword(event.target.value) }} />
                            <span className="input-group-text bg-white border-0" id="basic-addon1" onClick={handleChangeEyeNewPassword}>
                                {(!isHideNewPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-show.svg" id="show"></img>
                                    </>
                                )}
                                {(isHideNewPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-hide.svg" id="hide"></img>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Confirm Password</label>
                        <div className="input-group d-flex bg-white rounded password confirm-password">
                            <input type="password" className="form-control border-0" aria-label="Username" aria-describedby="basic-addon1" value={confPassword} onChange={(event) => { setConfPassword(event.target.value) }} />
                            <span className="input-group-text bg-white border-0" id="basic-addon1" onClick={handleChangeEyeConfPassword}>
                                {(!isHideConfPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-show.svg" id="show"></img>
                                    </>
                                )}
                                {(isHideConfPassword) && (
                                    <>
                                        <img alt="" src="/dist/img/custom/eye-hide.svg" id="hide"></img>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                            <button type="button" className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask" disabled={!(currPassword && newPassword && confPassword)} onClick={handleChangePassword}>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(ChangePassword);
