import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable} from '../DataTable';
import { formatDate, formatMoney, formatDateTimeSecond } from "../../helper/lib";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


const OrdersTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit,  }) => {
    let navigate = useNavigate();
    const columns = [
        {
            name: "Order No",
            selector: "order_no",
            width: "0px",
            class: []
        },
        {
            name: "Order Date",
            selector: "order_date",
            width: "16.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Customer",
            selector: "customer",
            width: "13.5%",
            class: [],
            childClass:[]
        },
        {
            name: "Shipping Address",
            selector: "address",
            width: "18%",
            class: [],
            childClass:[]
        },
        {
            name: "Item(s)",
            selector: "items",
            width: "9%",
            class: [],
            childClass:[]
        },
        {
            name: "Total (Rp)",
            selector: "total",
            width: "8%",
            class: ['text-center'],
            childClass:[]
        },
        {
            name: "Status",
            selector: "status",
            width: "15%",
            class: [],
            childClass:[]
        },
        {
            name: "Options",
            selector: "options",
            width: "0px",
            class: [],
            childClass: ["visually-hidden"]
        }
    ]

    const _navigateToDetail = (orderNo, id) => {
        let slug = orderNo.replace(/\//g, "-") + "-" + id;
        navigate(`/orders/detail/${slug}/`);
    }

    return (
            <CustomDataTable
                columns={columns}
                items={items}
                page={page}
                limit={limit}
                total={total}
                isLoadingData={isLoadingData}
                onChangePage={onChangePage}
                onChangeLimit={onChangeLimit}
                >
                     { isLoadingData ? (<tr><td style={{background:"white", height:"200px"}} colSpan={8} rowSpan={3}><LoadingComponent /></td></tr>) : items.map((item, index) => {
                                            let status = <div className="badge-gray">{item.status}</div>;
                                            // switch(item.status?.toLowerCase()) {
                                            //     case 'order verified':
                                            //         status = <div className="badge-yellow">New Order</div>;
                                            //         break;
                                            //     case 'confirmed':
                                            //         status = <div className="badge-green">{item.status}</div>;
                                            //         break;
                                            //     case 'packed':
                                            //         status = <div className="badge-gray">{item.status}</div>;
                                            //         break;
                                            //     case 'packing':
                                            //         status = <div className="badge-green">Processing</div>;
                                            //         break;
                                            //     case 'cancel':
                                            //         status = <div className="badge-red">{item.status}</div>;
                                            //         break;
                                            //     case 'shipped':
                                            //         status = <div className="badge-gray">{item.status}</div>;
                                            //         break;
                                            //     case 'completed':
                                            //         status = <div className="badge-green">{item.status}</div>;
                                            //         break;
                                            //     default:
                                            //         status = <div className="badge-gray">{item.status}</div>;
                                            //         break;
    
                                            // }
                                            return (
                                                <tr key={index}>
                                                   <td>{item.order_number}</td>
                                                   <td>{formatDateTimeSecond(new Date(item.date))}</td>
                                                   <td>{item.buyer_name}</td>
                                                   <td>{item.buyer_address?.substring(0,30)}...</td>
                                                   <td>{item.items.reduce((sum, x) => sum = sum + x.quantity, 0)} item(s)</td>
                                                   <td className='text-end'>{formatMoney(item.total, "", 0, ",", ".")}</td>
                                                   <td>{status}</td>
                                                   <td>
                                                        <div className='d-flex'>
                                                            <a onClick={() => _navigateToDetail(item.order_number,item.id)} className='btn btn-sm btn-warning' data-bs-toggle="tooltip" title='Order Detail'>
                                                                <FontAwesomeIcon icon={faEye} color="white" />
                                                            </a>
                                                        </div>
                                                   </td>
                                               </tr>
                                           )
                                        }) }

                </CustomDataTable>
    )
}

export default OrdersTable;