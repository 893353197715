import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getVendorBrand, getVendorBrandList, updateSettingProfile, getProfile } from '../../helper/function';
import Select from 'react-select';
import { IsAlert } from "../../atoms/IsAlert";

const Setting = (props) => {
    const [brandOptions, setBrandOptions] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [shippingPolicy, setShippingPolicy] = useState(props.dataProfile?.shipping_policy ?? '');
    const [returnPolicy, setReturnPolicy] = useState(props.dataProfile?.return_policy ?? '');

    const getVendorBrandData = async () => {
        setIsLoadingData(true);
        const response = await getVendorBrandList();
        
        if (response.message === "Success" && response.data.length > 0) {
            let options = response.data.map((item) => { return { value: item.id, label: item.name, img: item.image_url, tags: item.tags, checked: item.checked } });

            // Get yg user sudah pilih  
            const responseOwnBrand = await getVendorBrand();
            if (responseOwnBrand.message === "Success" && responseOwnBrand.data.length > 0) {
                let selectedBrand = [];
                responseOwnBrand.data.map((item) => {
                    options.push({ value: item.id, label: item.name, img: item.image_url, tags: item.tags, checked: item.checked })
                    selectedBrand.push({ value: item.id, label: item.name, img: item.image_url, tags: item.tags, checked: item.checked })
                    return
                })
                setSelectedBrand(selectedBrand)
            }

            setBrandOptions(options)
        }
        setIsLoadingData(false);
    }

    const customStyles = {
        brand: {
            control: (provided, state) => ({
                ...provided,
                width: 150,
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
        }
    }

    const formatOptionLabel = ({ value, label, img }) => (
        <div style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: 11 }}>
            <span>{label}</span>
        </div>
    );

    const handleOnChangeBrand = (e) => {
        setSelectedBrand(e)
    }

    const getUserData = async () => {
        let res = await getProfile();

        if (res.meta.total > 0) {
            props.changeDataProfile(res.data);
        } else {
            console.error({ res });
        }
    }

    const submitFormSetting = async () => {
        props.setLoading(true);

        let brandIds = [];
        selectedBrand.map((item) => {
            brandIds.push(parseInt(item?.value))
            return
        })

        let payload = {
            id_vendor: props.dataProfile?.id,
            shipping_policy: shippingPolicy,
            return_policy: returnPolicy,
            brand_id: brandIds
        };

        const response = await updateSettingProfile(payload);
        if (response.message === "Success") {
            getVendorBrandData();
            getUserData();
            IsAlert.fire({
                title: "Success",
                text: 'Update setting profile success.',
                icon: "success",
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonText: "Confirm",
            })
        } else {
            IsAlert.fire({
                title: "Failed",
                text: response?.message ?? 'Something when wrong.',
                icon: "error",
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonText: "Confirm",
            })
        }
        props.setLoading(false);
    }

    useEffect(() => {
        getVendorBrandData();
    }, []);

    useEffect(() => {
        setShippingPolicy(props.dataProfile?.shipping_policy)
        setReturnPolicy(props.dataProfile?.return_policy)
    }, [props.dataProfile]);


    return (
        <div className="tab-content" id="pills-tabContent">
            {/* <!-- Setting --> */}
            <div className="tab-pane fade show active" id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
                <div className="fs-5 fw-bold mb-4">
                    Setting
                </div>
                <form>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Default Shipping Policy</label>
                        <textarea className="form-control" rows="3" defaultValue={props.dataProfile?.shipping_policy} onChange={(event) => { setShippingPolicy(event.target.value) }}></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Default Return Policy</label>
                        <textarea className="form-control" rows="3" defaultValue={props.dataProfile?.return_policy} onChange={(event) => { setReturnPolicy(event.target.value) }}></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-semibold">Brands</label>
                        <Select
                            className="basic-single w-100"
                            classNamePrefix="select"
                            placeholder="Brands"
                            name="form-category"
                            value={selectedBrand}
                            isMulti
                            options={brandOptions}
                            isSearchable={false}
                            onChange={handleOnChangeBrand}
                            formatOptionLabel={formatOptionLabel}
                            styles={customStyles.star}
                            menuPortalTarget={document.body}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                            <button type="button" className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask" disabled={!(shippingPolicy && returnPolicy && selectedBrand)} onClick={submitFormSetting}>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapState = (state) => {
    return {
        dataProfile: state.dataProfile,
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
        changeDataProfile: (data) => dispatch({ type: "CHANGE_DATA_PROFILE", payload: data }),
    };
};

export default connect(mapState, actionCreators)(Setting);
