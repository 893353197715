var axios = require("axios");
let main_url = process.env.REACT_APP_ENVIRONTMENT === 'local' ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_ENVIRONTMENT === 'staging' ? process.env.REACT_APP_API_URL_STAGING : process.env.REACT_APP_API_URL_PRODUCTION;
let token = localStorage.getItem("token");

const getToken = () => {
  return localStorage.getItem("token");
}

const getMainUrl = () => {
  return main_url;
}

//headers.append("X-API-TOKEN", "2b9b335661abe7aa7594305d26efe6a5e695cbbacb");
const fetchApiDelete = async (path, raw) => {
  token = getToken();

  const option = {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiGet = async (path) => {
  token = getToken();

  const option = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiGetNoToken = async (path, raw = "") => {
  const option = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      Accept: "*/*",
    },
    redirect: "follow",
  };
  return await fetch(`${main_url}/${path}`, option);
};

const fetchApiPost = async (path, raw) => {
  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      Accept: "*/*",
      // token : token
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiPostRO = async (path, raw) => {
  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      key: "e6c494835bfb7136d37a6ce4ee61383c",
      // token : token
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${path}`, option);
};
const fetchApiPostToken = async (path, raw) => {
  token = getToken();

  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiPostPatch = async (path, raw) => {
  token = getToken();

  const option = {
    crossDomain: true,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiPostPatchNoToken = async (path, raw) => {
  const option = {
    crossDomain: true,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiPostNoToken = async (path, raw) => {
  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiPostTokenUpdateProfile = async (path, raw) => {
  var tokenProfile = localStorage.getItem("tokenUpdate");
  if (tokenProfile) {
    token = tokenProfile;
  }
  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiDeleteToken = async (path, raw) => {
  token = getToken();

  const option = {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};

const getData = async (path) => {
  token = getToken();
  var config = {
    method: "get",
    url: main_url + "/users/profile/member",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
  };
  axios(config)
    .then(function (response) {
      localStorage.setItem("fullname", response.data.data.full_name);
    })
    .catch(function (error) {
      localStorage.removeItem("logged");
      localStorage.removeItem("fullname");
      localStorage.removeItem("phoneRegister");
      localStorage.removeItem("phone");
      localStorage.removeItem("token");
      localStorage.removeItem("member_id");
      localStorage.removeItem("tokenUpdate");
      // document.location = ''
    });
};
const deleteAlamat = async (path, raw) => {
  const option = {
    crossDomain: true,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};
const fetchApiAddItems = async (path, raw) => {
  token = getToken();

  const option = {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
      token: token,
      Accept: "*/*",
    },
    redirect: "follow",
    body: JSON.stringify(raw),
    referrerPolicy: "no-referrer",
  };
  return await fetch(`${main_url}/${path}`, option);
};

export {
  fetchApiAddItems,
  getData,
  fetchApiPostToken,
  fetchApiGet,
  fetchApiPost,
  fetchApiPostTokenUpdateProfile,
  fetchApiPostPatch,
  fetchApiPostPatchNoToken,
  deleteAlamat,
  fetchApiPostRO,
  fetchApiGetNoToken,
  fetchApiDelete,
  fetchApiPostNoToken,
  getMainUrl,
  fetchApiDeleteToken
  
};
