import React from 'react';
import PaginationComponent from './CustomDataTablePagination';

const CustomDataTable = ({items, page, limit, total, isLoadingData, onChangePage, onChangeLimit,columns, children }) => {

    return (
        <div id="" className="dataTables_wrapper dt-bootstrap5 no-footer">
            <div className="row">
                <div className="col-sm-12">
                    <div className="dataTables_scroll">
                        <div className="dataTables_scrollHead" style={{position: "relative", overflow: "auto", width: "100%"}}>
                            <table id="" className="table table-striped dataTable no-footer" style={{width: "100%"}} aria-describedby="table_id2_info">
                                <thead>
                                    <tr>
                                        {columns.map((column, index) => {
                                            return (
                                                <th key={index} className={`sorting_disabled ${column.class.join(" ")}`} rowSpan="1" colSpan="1" style={{borderTop:"1px solid #DEE3ED",width: column.width}}><span className={`${column.childClass?.join(" ")}`}>{column.name}</span></th> 
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <PaginationComponent 
                total={total}
                page={page}
                limit={limit}
                startPage={0}
                totalCurrent={items.length}
                setPage={onChangePage}
                isLoadingData={isLoadingData}
            />
        </div>
    )
}

export default CustomDataTable;