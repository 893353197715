function addDefaultSrc(ev) {
    ev.target.src = '/dist/img/brokenImage.jpg'
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// format money with currency
const formatMoney = (money, currency = "Rp.", decimalCount = 0, decimal = ",", thousands = ".") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = money < 0 ? "-" : "";

        let i = parseInt(money = Math.abs(Number(money) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return (currency + " " + negativeSign + (j ? i.substr(0, j) + thousands : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(money - i).toFixed(decimalCount).slice(2) : "")).trim();
    } catch (e) {
        console.log(e)
    }
}

const convertMoney = (string) => {
    let number = string.replace(/[^0-9.-]+/g, "");
    return parseFloat(number);
}

const formatDate = (date) => {
    var dd = date.getDate();
    var mm = date.toLocaleString('en-us', { month: 'short' });
    var yyyy = date.getFullYear().toString();

    return dd + " " + mm + " " + yyyy;
}

const formatDateTime = (date) => {
    var dd = date.getDate();
    var mm = date.toLocaleString('en-us', { month: 'short' });
    var yyyy = date.getFullYear().toString();
    var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    return dd + " " + mm + " " + yyyy + " " + hh + ":" + min;
}

const formatDateTimeSecond = (date) => {
    var dd = date.getDate();
    var mm = date.toLocaleString('en-us', { month: 'short' });
    var yyyy = date.getFullYear().toString();
    var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var sec = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    return dd + " " + mm + " " + yyyy + " " + hh + ":" + min + ":" + sec;
}

const formatTime = (date) => {
    var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    return hh + ":" + min;
}

const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

const validateString = (string) => /*return "-" if string is empty" */(string === "" || string === null || string === undefined) ? "-" : string;

// discount format
const discountFormat = (discount) => (discount === 0 || discount === null || discount === undefined || discount === "") ? "-" : discount + "% Off";

const getBase64StringFromDataURL = (dataURL) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');

// Get file extension from string (url, filename, est)
const getFileExtension = async (string) => {
    return string.split(".").pop()
}

// Get base64 from file url
const getBase64FromUrl = async (fileUrl) => {
    let resp = ''
    let headerWithNoCache = new Headers();
    headerWithNoCache.append('pragma', 'no-cache');
    headerWithNoCache.append('cache-control', 'no-cache');

    await fetch(fileUrl, {headers: headerWithNoCache, mode: 'no-cors'})
        .then(response => response.arrayBuffer())
        .then(buffer => {
            // Convert the file data to a binary string
            const binaryString = String.fromCharCode(...new Uint8Array(buffer));

            // Encode the binary string as a base64 string
            const base64String = btoa(binaryString);

            // Output the base64 string to the console
            return resp = base64String
        })
        .catch(error => {
            return resp = ""
        });

    return String(resp)
}

const getBase64FromUrlV2 = async (url) => {
    try {
        const response = await fetch(url, {mode: 'no-cors'});
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.onerror = reject;
        });
    } catch (error) {
        // Handle fetch or FileReader errors here
        console.error('Error:', error);
        throw error; // Propagate the error if needed
    }
};

const getBase64FromUrlV3 = async (url) => {
    const option = {
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
          Accept: "*/*",
        },
        redirect: "follow",
        body: JSON.stringify({
            url: url
        }),
        referrerPolicy: "no-referrer",
      };
    const request = await fetch(`${process.env.REACT_APP_API_JAVA}/users/image/get_base64`, option);
    const response = await request.json()
    return response.base64;
}


export {
    addDefaultSrc,
    formatMoney,
    convertMoney,
    capitalizeEachWord,
    formatDate,
    formatDateTime,
    validateString,
    discountFormat,
    getBase64StringFromDataURL,
    getFileExtension,
    getBase64FromUrl,
    isJsonString,
    formatTime,
    formatDateTimeSecond,
    getBase64FromUrlV2, 
    getBase64FromUrlV3
}