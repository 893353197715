import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import $ from "jquery";
import { IsAlert } from "../../../atoms/IsAlert";
import { getFinalDesignMaterial, updateMaterialList, createMaterialList } from "../../../helper/function";
import { getBase64FromUrlV3 } from "../../../helper/lib";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UploadMaterialListProject = (props) => {
    const navigate = useNavigate()
    const { orderNo, projectID } = useParams();
    const [previewImage, setPreviewImage] = useState();
    const [samplePreview, setSamplePreview] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [designID, setDesignID] = useState(0);

    // Form state
    const [materials, setMaterials] = useState('');

    // Get data materials
    const getMaterials = async () => {
        // Material List
        const payload = {
            orderNo: orderNo,
            id: projectID
        }

        const responseMaterial = await getFinalDesignMaterial(payload)
        const previewImages = []
        if (responseMaterial.message === "Success" && responseMaterial.meta.total > 0 && responseMaterial?.data?.designConcept?.length > 0) {
            setIsEdit(true)
            setDesignID(responseMaterial?.data?.designConcept[0]?.id)

            setMaterials(responseMaterial?.data?.designConcept[0]?.materials)
            for (let i = 0; i < responseMaterial?.data?.designConcept[0]?.photos?.length; i++) {
                const el = responseMaterial?.data?.designConcept[0]?.photos[i];
                if (el.full_image_url !== "") {
                    let base64File = await getBase64FromUrlV3(el.full_image_url)
                    if (base64File !== "" && base64File !== undefined) {
                        previewImages.push({
                            name: "response-photo.jpg",
                            size: "-",
                            preview: el.full_image_url,
                            file: base64File,
                            extension: 'png'
                        });
                    }
                }
            }
        } else {
            setMaterials('')
        }

        // set images if exists
        await setSamplePreview(previewImages)

    }

    //
    useEffect(() => {
        getMaterials();
        // setMaterials('Moodboard')
        // setSamplePreview([
        //     {
        //         name: "response-photo.jpg",
        //         size: "-",
        //         preview: `http://localhost:3000/dist/img/custom/image.svg`,
        //         file: `http://localhost:3000/dist/img/custom/image.svg`
        //     }
        // ]);
    }, [])

    //
    const handleUpload = async (event) => {
        const previewImage = URL.createObjectURL(event.target.files[0])
        setPreviewImage(previewImage)
        const base64 = await convertBase64(event.target.files[0])
        samplePreview.push(
            {
                name: event?.target?.files[0]?.name,
                size: Math.round(event?.target?.files[0]?.size / 1000000 * 100) / 100,
                preview: previewImage,
                file: base64
            }
        )
        setPreviewImage(samplePreview)
    }
    const convertBase64 = async (file, isBlob) => {
        let blob = file
        if (isBlob) {
            blob = await file.blob()
        }
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const clearImageSelected = (index) => {
        const filtered = samplePreview.filter((_, i) => {
            return i !== index
        })
        setSamplePreview(filtered)
    }

    //
    const handleSubmit = async (event) => {
        event.preventDefault()

        // Disable button
        $('.btn-save').prop('disabled', true)

        let photoTemp = [];
        samplePreview.forEach((el) => {
            photoTemp.push(el.file)
        })

        if (!isEdit) {
            const payload = {
                IdProject: projectID,
                orderNo: orderNo,
                title: "",
                description: "",
                materials: materials,
                designType: 4,
                photos: photoTemp,
                docs: [],
            }
            const response = await createMaterialList(payload)
            let redirectUrlSuccess = `/designer/project/view/${orderNo}/${projectID}`
            if (response?.message === "Success") navigate(redirectUrlSuccess, {
                state: {
                    tab: 'finalDesign'
                }
            })

            // Error
            if (response?.message !== "Success") {
                $('.btn-save').prop('disabled', false)
                IsAlert.fire({
                    title: "Fail",
                    text: response.message,
                    icon: "error",
                })
            }
        }

        if (isEdit) {
            const payload = {
                id: designID,
                IdProject: projectID,
                orderNo: orderNo,
                title: "",
                description: "",
                materials: materials,
                designType: 4,
                photos: photoTemp,
                docs: [],
            }
            const response = await updateMaterialList(payload)
            let redirectUrlSuccess = `/designer/project/view/${orderNo}/${projectID}`
            if (response?.message === "Success") navigate(redirectUrlSuccess, {
                state: {
                    tab: 'finalDesign'
                }
            })

            // Error
            if (response?.message !== "Success") {
                $('.btn-save').prop('disabled', false)
                IsAlert.fire({
                    title: "Fail",
                    text: response.message,
                    icon: "error",
                })
            }
        }
    }

    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">
            <div className="mb-4">
                <a onClick={() => navigate(`/designer/project/view/${orderNo}/${projectID}`, {
                    state: {
                        tab: 'finalDesign'
                    }
                })} className="fs-6 fw-bold">
                    <img src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" alt="img" />
                    Upload Material List
                </a>
            </div>
            <hr className="mt-5 mb-5" />

            <form onSubmit={handleSubmit}>
                <div className="d-sm-flex mb-3" style={{ minHeight: '200px' }}>
                    <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                        <label className="form-label d-flex fw-semibold">List Of Materials<div className="text-danger ps-1">*</div></label>
                        <ReactQuill theme="snow" value={materials} onChange={setMaterials} style={{ height: '80%' }} />
                    </div>
                </div>

                {/* Photo */}
                <div className="fs-5 fw-bold mt-2 mb-4 px-2 mt-5">
                    Photo
                </div>
                <div className="w-100 px-2">
                    <label htmlFor="file-upload" className="upload-file-custom">
                        <div>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <div className="img-wrap">
                                    {/* <img src="/dist/img/custom/image.svg" className="w-4rem" alt="img-upload" /> */}
                                    <img src={'/dist/img/custom/image.svg'} className="w-4rem" alt="img-upload" />
                                </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                                <p className="mb-0 text-center">
                                    Drop your photos here, or <strong>browse</strong>
                                </p>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-center mt-1">
                                <p className="mb-0 text-center text-secondary fz-14">
                                    Max. 3MB per file with format .jpeg, .jpg, .png (Max. 1 files)
                                </p>
                            </div>
                        </div>
                    </label>
                    <input className="visually-hidden" id="file-upload" type="file" accept="image/jpeg,image/jpg,image/png*" onChange={handleUpload} />
                </div>
                <div className="ms-2 d-flex justify-content-center">
                    <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0">
                        {samplePreview.map((image, index) => {
                            return (
                                <div key={index}>
                                    <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                                                <div className="w-100 h-100">
                                                    <img id="imageId" src={image.preview} className="object-fit-cover w-5rem ar-square" alt="Preview" />
                                                </div>
                                            </div>
                                            <div className="col ms-3">
                                                <p className="mb-0 text-wrap-1">
                                                    {image.name}
                                                </p>
                                                <div className="fz-14 text-secondary mt-3">
                                                    {image.size} Mb
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn p-0" type="button">
                                            {/* <img src="/dist/img/custom/x.svg" className="icon-3" alt="img-delete" onClick={clearImageSelected.bind(this, index)} /> */}
                                            <img src={'/dist/img/custom/x.svg'} className="icon-3" alt="img-delete" onClick={clearImageSelected.bind(this, index)} />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="text-right">
                    <button type="submit" className="btn fw-semibold btn-fill px-5 mt-3 ask btn-save">
                        Save
                    </button>
                </div>

                <div className="modal fade" id="modalSubmitDesign" tabIndex="-1" aria-labelledby="modalSubmitDesignLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                                    <div className="d-grid px-3 px-sm-0">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="img-wrap img-wrap-empty">
                                                <img src="/dist/img/custom/submit-design.svg" alt="item-submit-design" />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                            <div className="fs-4 fw-bold text-center">
                                                Submit Material List
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                                            The file that you send in this submission will be received by the customer.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 position-relative">
                                <div className="w-50 m-0 p-1">
                                    <button type="button" className="btn w-100 btn-line fw-semibold" data-bs-dismiss="modal">Cancel</button>
                                </div>
                                <div className="w-50 m-0 p-1">
                                    <button type="submit" className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold" data-bs-dismiss="modal">Send Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(UploadMaterialListProject);