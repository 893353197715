import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getNotification, readAllNotification } from "../helper/function";
import PaginationComponent from '../organism/DataTable/CustomDataTablePagination';

const Notifications = (props) => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [isLoadingData, setIsLoadingData] = useState(false);

    let navigate = useNavigate();
    var location = useLocation();
    const locationPathOne = location.pathname.split('/')[1]; 

    const getNotificationList = async (page) => {
        let res = await getNotification(locationPathOne === 'designer' ? 2 : 1, page);

        if (res?.data && res?.meta) {
            setData(res.data);
            setTotal(res.meta.total);
            
            await readAllNotification();
            
            if (props.dataNotification && props.dataNotification.meta && props.dataNotification.meta.total > 0) {
                let clone = { ...props.dataNotification }
                clone.meta.total = 0;
                props.changeDataNotification(clone);
            }

        } else {
            setData([]);
            setTotal(0);
        }

    }

    useEffect(() => {
        getNotificationList(0);
    }, [])

    const onChangePage = (page) => {
        setPage(page)
        getNotificationList(page);
    }

    const _navigateToDetail = (orderNo, id) => {
        if (locationPathOne === 'designer') {
            if (orderNo) {
                navigate(`/designer/project/view/${orderNo}`);
            }
        } else {
            if (orderNo && id) {
                let slug = orderNo.replace(/\//g, "-") + "-" + id;
                navigate(`/orders/detail/${slug}/`);
            }
        }
    }
    
    const handleClickNotifItem = (item) => {
        _navigateToDetail(item.order_number, item.order_id);
    }

    return (
        <div className="container-fluid mb-4 px-0">
            <div className="fs-5 fw-bold mt-2 mb-4">
                Notifications
            </div>

            {data.length > 0 ? (
                <div>
                    {data.slice(0, 10)
                        .sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        })
                        .map((item, idx) => {
                            return (
                                <div key={item.id} onClick={() => handleClickNotifItem(item)}  className={`${item.is_read ? "bg-white border notif-item-white" : "bg-light-blue notif-item-lightblue"} rounded-3 p-3 mb-3`}>
                                    <div className="fw-bold">
                                        {item.title}
                                    </div>
                                    <div className="fz-14 mt-2">
                                        {item.content}
                                    </div>
                                    <div className="text-right fz-12">
                                        [{(idx + 1 + page * 10)}]
                                    </div>
                                </div>
                            )
                        })}

                        <PaginationComponent 
                            total={total}
                            page={page}
                            limit={10}
                            startPage={0}
                            totalCurrent={data.length}
                            setPage={onChangePage}
                            isLoadingData={isLoadingData}
                        />

                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center p-3 mt-3">
                    There is no new notification.
                </div>
            )
            }

        </div>
    )
}

const mapState = (state) => {
    return {
        dataNotification: state.dataNotification,
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
        changeDataNotification: (data) => dispatch({ type: "CHANGE_DATA_NOTIFICATION", payload: data }),
        
    };
};


export default connect(mapState, actionCreators)(Notifications);
