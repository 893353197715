import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getDetailPromotion, generateUrl } from "../../helper/function";
import LoadingComponent from '../../atoms/LoadingComponent';
import { addDefaultSrc, formatMoney, capitalizeEachWord, formatDateTimeSecond } from "../../helper/lib";
import moment from 'moment';

const PromotionsDetail = (props) => {
    moment.locale('en');
    let navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const validateString = (string) => (string === null || string === undefined || string === "") ? "-" : string;

    const fetchDetail = async () => {
        setIsLoadingData(true)
        const response = await getDetailPromotion(id)

        if (response.message === "Success") {
            setData(response.data)
        }

        setIsLoadingData(false)
    };

    useEffect(() => {
        fetchDetail()
    }, []);


    return (
        <div className="container-fluid mb-4 px-0 tab-navigasi">

            {isLoadingData ? <LoadingComponent classList={['vh-100']} /> : <>
                <div className="mb-4 px-4 px-md-0">
                    <a onClick={() => navigate("/promotions")} className="fs-5 fw-bold">
                        <img alt="" src={"/dist/img/custom/caret-left-light.svg"} className="icon-4 me-2" />
                        Promotion Detail
                    </a>
                </div>

                <div className="w-100 d-flex align-items-lg-stretch flex-column flex-lg-row">
                    <div className="col-12 col-lg-12 mb-md-5 px-4 pe-md-0 ps-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
                        <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                            <div className="d-flex flex-column flex-md-row flex-lg-column">
                                <div className="col-lg-12 w-md-100">
                                    <div className="row row-cols-1 row-cols-md-3 gx-3">
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Promo Name</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.name)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Caption 1 (H1)</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.caption1)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Caption 2 (H2)</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.caption2)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Discount Type</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.discount_type === 1 ? 'Value' : 'Percent'}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Discount Value</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.discount_type === 1 ? formatMoney(String(data?.discount).replaceAll(',', '')) : data?.discount + ' %'}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Category</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.category_name)}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Status</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2"><div className="badge-gray">{data?.request_status ? capitalizeEachWord(data?.request_status) : ''}</div></p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">From</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.start_date ? formatDateTimeSecond(new Date(data.start_date)) : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">To</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{data?.end_date ? formatDateTimeSecond(new Date(data.end_date)) : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">Image</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">
                                                    <img src={data?.image_url} style={{ maxWidth: "220px !important", height: "220px", objectFit: "cover" }} alt="img-room" onError={addDefaultSrc} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(PromotionsDetail);
